import { FunctionComponent } from "react";
import { Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import SvgDontHaveAny from "../../../assets/IconComponents/DontHaveAny";
import { t } from "i18next";

interface DontHaveAnyComponentProps {
    handleClick: () => void;
    title: string;
    titleClassName?: string;
    description: string;
    descriptionClassName?: string;
    actionButton?: string;
}

const DontHaveAnyComponent: FunctionComponent<DontHaveAnyComponentProps> = ({ handleClick, title, titleClassName, description, descriptionClassName, actionButton }) => {
    return (
        <div className="flex flex-col w-full items-center mx-auto bg-white border-1 rounded-lg p-2 mt-6">
            <div className="mx-auto mt-9">
                <SvgDontHaveAny />
            </div>
            <span className={`text-H5 font-medium text-black mt-6 ${titleClassName}`}>
                {title}
            </span>
            <span className={`text-H7 font-normal text-black text-center mt-2 w-1/2 ${descriptionClassName}`}>
                {description}
            </span>
            <Button className="my-5" type="primary" icon={<PlusOutlined />} onClick={handleClick}>
                {actionButton ?? t("add-new")}
            </Button>
        </div>
    );
};

export default DontHaveAnyComponent;
