import { coreApi as api } from "./Core/BaseApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    save5: build.mutation<Save5ApiResponse, Save5ApiArg>({
      query: (queryArg) => ({
        url: `/brand`,
        method: "POST",
        body: queryArg.brandRequest,
      }),
    }),
    refreshKeyword: build.mutation<
      RefreshKeywordApiResponse,
      RefreshKeywordApiArg
    >({
      query: (queryArg) => ({
        url: `/brand/refresh-keyword`,
        method: "POST",
        body: queryArg.aiRefreshKeywordRequest,
      }),
    }),
    refreshKeyword1: build.mutation<
      RefreshKeyword1ApiResponse,
      RefreshKeyword1ApiArg
    >({
      query: (queryArg) => ({
        url: `/brand/refresh-keyword/${queryArg.brandId}`,
        method: "POST",
      }),
    }),
    importDataFromWebsite: build.mutation<
      ImportDataFromWebsiteApiResponse,
      ImportDataFromWebsiteApiArg
    >({
      query: (queryArg) => ({
        url: `/brand/import-data`,
        method: "POST",
        body: queryArg.importDataFromWebsiteRequest,
      }),
    }),
    generateWithAi: build.mutation<
      GenerateWithAiApiResponse,
      GenerateWithAiApiArg
    >({
      query: (queryArg) => ({
        url: `/brand/generate-with-ai`,
        method: "POST",
        body: queryArg.withAiRequest,
      }),
    }),
    createFromUrl: build.mutation<
      CreateFromUrlApiResponse,
      CreateFromUrlApiArg
    >({
      query: (queryArg) => ({
        url: `/brand/from-url`,
        method: "POST",
        body: queryArg.brandFromUrlRequest,
      }),
    }),
    contentInspiration: build.mutation<
      ContentInspirationApiResponse,
      ContentInspirationApiArg
    >({
      query: (queryArg) => ({
        url: `/brand/content-inspiration`,
        method: "POST",
        body: queryArg.contentInspirationRequest,
      }),
    }),
    cloneBrand: build.mutation<CloneBrandApiResponse, CloneBrandApiArg>({
      query: (queryArg) => ({
        url: `/brand/clone`,
        method: "POST",
        body: queryArg.cloneBrandRequest,
      }),
    }),
    getApiKeysByBrandId: build.query<
      GetApiKeysByBrandIdApiResponse,
      GetApiKeysByBrandIdApiArg
    >({
      query: (queryArg) => ({ url: `/ad_manager/brand/${queryArg.brandId}` }),
    }),
    connectApiKey: build.mutation<
      ConnectApiKeyApiResponse,
      ConnectApiKeyApiArg
    >({
      query: (queryArg) => ({
        url: `/ad_manager/brand/${queryArg.brandId}`,
        method: "POST",
        body: queryArg.connectApiKeyRequest,
      }),
    }),
    deleteApiKeysByBrandId: build.mutation<
      DeleteApiKeysByBrandIdApiResponse,
      DeleteApiKeysByBrandIdApiArg
    >({
      query: (queryArg) => ({
        url: `/ad_manager/brand/${queryArg.brandId}`,
        method: "DELETE",
      }),
    }),
    findById4: build.query<FindById4ApiResponse, FindById4ApiArg>({
      query: (queryArg) => ({ url: `/brand/${queryArg.id}` }),
    }),
    deleteById4: build.mutation<DeleteById4ApiResponse, DeleteById4ApiArg>({
      query: (queryArg) => ({ url: `/brand/${queryArg.id}`, method: "DELETE" }),
    }),
    list6: build.query<List6ApiResponse, List6ApiArg>({
      query: () => ({ url: `/brand/list` }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type Save5ApiResponse = /** status 200 OK */ BrandRequest;
export type Save5ApiArg = {
  brandRequest: BrandRequest;
};
export type RefreshKeywordApiResponse =
  /** status 200 OK */ AiRefreshKeywordResponse;
export type RefreshKeywordApiArg = {
  aiRefreshKeywordRequest: AiRefreshKeywordRequest;
};
export type RefreshKeyword1ApiResponse =
  /** status 200 OK */ AiRefreshKeywordResponse;
export type RefreshKeyword1ApiArg = {
  brandId: string;
};
export type ImportDataFromWebsiteApiResponse =
  /** status 200 OK */ ImportDataFromWebsiteResponse;
export type ImportDataFromWebsiteApiArg = {
  importDataFromWebsiteRequest: ImportDataFromWebsiteRequest;
};
export type GenerateWithAiApiResponse =
  /** status 200 OK */ GenerateWithAiResponse;
export type GenerateWithAiApiArg = {
  withAiRequest: WithAiRequest;
};
export type CreateFromUrlApiResponse = /** status 200 OK */ BrandRequest;
export type CreateFromUrlApiArg = {
  brandFromUrlRequest: BrandFromUrlRequest;
};
export type ContentInspirationApiResponse =
  /** status 200 OK */ AiContentInspirationsResponse;
export type ContentInspirationApiArg = {
  contentInspirationRequest: ContentInspirationRequest;
};
export type CloneBrandApiResponse = /** status 200 OK */ BrandRequest;
export type CloneBrandApiArg = {
  cloneBrandRequest: CloneBrandRequest;
};
export type GetApiKeysByBrandIdApiResponse =
  /** status 200 OK */ BrandSocialsModel[];
export type GetApiKeysByBrandIdApiArg = {
  brandId: string;
};
export type ConnectApiKeyApiResponse = unknown;
export type ConnectApiKeyApiArg = {
  brandId: string;
  connectApiKeyRequest: ConnectApiKeyRequest;
};
export type DeleteApiKeysByBrandIdApiResponse = unknown;
export type DeleteApiKeysByBrandIdApiArg = {
  brandId: string;
};
export type FindById4ApiResponse = /** status 200 OK */ BrandRequest;
export type FindById4ApiArg = {
  id: string;
};
export type DeleteById4ApiResponse = unknown;
export type DeleteById4ApiArg = {
  id: string;
};
export type List6ApiResponse = /** status 200 OK */ BrandRequest[];
export type List6ApiArg = void;
export type BrandKeywords = {
  source?: string;
  value?: string;
};
export type FileProps = {
  id?: string;
  name?: string;
  uploaded?: boolean;
  uploadedId?: string;
  fileDescription?: string;
  imageId?: string;
  subChildConcept?: string;
  preview?: string;
  adId?: string;
  file_name?: string;
  file_type?: string;
  create_date?: number;
  localeDate?: string;
  summary?: string;
  parentConcept?: string;
  isAdapted?: boolean;
  industry?: string;
  objective?: string;
};
export type BeneficiaryPayer = {
  beneficiary?: string;
  payer?: string;
  isDefault?: boolean;
};
export type MetaAdsSettings = {
  ads_category?: string;
  beneficiary_payer?: BeneficiaryPayer[];
};
export type BrandRequest = {
  id?: string;
  keywords?: BrandKeywords[];
  brand_name?: string;
  web_url: string;
  twitter_url?: string;
  linkedin_url?: string;
  instagram_url?: string;
  facebook_url?: string;
  about_company: string;
  brand_tone?: string;
  brand_colors?: string;
  icon_file_ids?: FileProps[];
  logo_file_ids?: FileProps[];
  reference_file_ids?: FileProps[]
  product_file_ids?: FileProps[];
  company_materials_file_ids?: FileProps[];
  primary_font_file_id?: FileProps;
  secondary_font_file_id?: FileProps;
  accent_font_file_id?: FileProps;
  sector?: string;
  sub_sector?: string;
  definition_ratio?: number;
  technical_links?: FileProps[];
  reference_files?: FileProps[];
  content_language?: string;
  audience_ids?: string[];
  img_file_ids?: FileProps[];
  meta_ads_settings?: MetaAdsSettings;
  connected_account_count?: number;
  brand_socials?: BrandSocialsModel[];
};
export type AiRefreshKeywordResponse = {
  keywords?: string;
};
export type AiRefreshKeywordRequest = {
  about?: string;
  twitter?: string;
  instagram?: string;
  linkedin?: string;
  bot_type?: string;
};
export type ImportDataFromWebsiteResponse = {
  twitter?: string;
  instagram?: string;
  facebook?: string;
  linkedin?: string;
  about?: string;
  sector?: string;
  url_text?: string;
  sub_sector?: string;
  most_freq_colors?: string[];
  primary_font_file_id?: FileProps;
  secondary_font_file_id?: FileProps;
  accent_font_file_id?: FileProps;
  text_tone?: string;
  logo_file_ids?: FileProps[];
};
export type ImportDataFromWebsiteRequest = {
  url: string;
  bot_type?: string;
};
export type GenerateWithAiResponse = {
  about?: string;
};
export type WithAiRequest = {
  url: string;
  twitter?: string;
  instagram?: string;
  linkedin?: string;
};
export type BrandFromUrlRequest = {
  brand_name: string;
  web_url: string;
};
export type Id = {
  content_id?: string;
  revision_id?: string;
};
export type Content = {
  id?: Id;
  name?: string;
  type?: "image" | "text";
  platform?: string;
  purpose?: string;
  filters?: string;
  revisionGroupCount?: number;
  creative?: string;
  activityData?: string;
  cta?: boolean;
  created_date?: string;
  created_user?: string;
  updated_date?: string;
  updated_user?: string;
  words_limit?: number;
  sentences_limit?: number;
  image_height?: number;
  image_width?: number;
  brand_id?: string;
  logo_file_id?: string;
  product_file_id?: string;
  is_favorite?: boolean;
  read_only?: boolean;
};
export type AiContentInspirationsResponse = {
  content?: Content[];
};
export type ContentInspirationRequest = {
  brand_id?: string;
  sm_type: "twitter" | "linkedin" | "instagram" | "facebook";
  sort_order?: string;
  keywords?: string;
};
export type CloneBrandRequest = {
  brand_id: string;
};
export type AdPublishConnectionParameters = {
  apiKey?: string;
  apiSecret?: string;
  accessToken?: string;
  tokenSecret?: string;
  userProfile?: string;
  accountId?: string;
  pageId?: string;
  managerAccountId?: string;
};
export type SocialApiKeyModel = {
  id?: string;
  name?: string;
  organisationId?: string;
  apiType?:
  | "facebook"
  | "instagram"
  | "x"
  | "linkedin"
  | "blog"
  | "newsletter"
  | "google_ads"
  | "meta_ads"
  | "facebook_page"
  | "facebook_ad_account";
  connectionParameters?: AdPublishConnectionParameters;
};
export type BrandSocialsModel = {
  brandId?: string;
  apiKeyModel?: SocialApiKeyModel;
};
export type ConnectApiKeyRequest = {
  key_id?: string;
  apiType?:
  | "facebook"
  | "instagram"
  | "x"
  | "linkedin"
  | "blog"
  | "newsletter"
  | "google_ads"
  | "meta_ads"
  | "facebook_page"
  | "facebook_ad_account";
};
export const {
  useSave5Mutation,
  useRefreshKeywordMutation,
  useRefreshKeyword1Mutation,
  useImportDataFromWebsiteMutation,
  useGenerateWithAiMutation,
  useCreateFromUrlMutation,
  useContentInspirationMutation,
  useCloneBrandMutation,
  useGetApiKeysByBrandIdQuery,
  useLazyGetApiKeysByBrandIdQuery,
  useConnectApiKeyMutation,
  useDeleteApiKeysByBrandIdMutation,
  useFindById4Query,
  useLazyFindById4Query,
  useDeleteById4Mutation,
  useList6Query,
  useLazyList6Query,
} = injectedRtkApi;
