import * as React from "react";
const SvgDeleteWhiteDownloadIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={21}
    fill="none"
    {...props}
  >
    <path
      fill="#fff"
      d="M8.333 1.717 7.5 2.55H3.167a.833.833 0 1 0 0 1.667h1v12.5c0 .435.159.879.473 1.193.314.314.758.473 1.193.473h8.334c.435 0 .878-.16 1.193-.473.314-.314.473-.758.473-1.193v-12.5h1a.833.833 0 0 0 0-1.667H12.5l-.833-.833H8.333Zm-2.5 2.5h8.334v12.5H5.833v-12.5ZM7.5 5.883v9.167h1.667V5.883H7.5Zm3.333 0v9.167H12.5V5.883h-1.667Z"
    />
  </svg>
);
export default SvgDeleteWhiteDownloadIcon;
