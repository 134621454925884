import { Input, Radio } from "antd";
import { log } from "console";
import { setDefaultNamespace } from "i18next";
import { FunctionComponent, useEffect, useState } from "react"

interface IBrandHubBeneficiaryPayerProps {
    beneficiary?: string
    payer?: string
    isDefault: boolean
    index: number
    onValuesUpdated: (index: number, isDefault: boolean, beneficiary?: string, payer?: string) => void,
}

const BrandHubBeneficiaryPayer: FunctionComponent<IBrandHubBeneficiaryPayerProps> = (
    props: IBrandHubBeneficiaryPayerProps
) => {

    const [beneficiary, setBeneficiary] = useState<string>()
    const [payer, setPayer] = useState<string>()
    const [isDefault, setIsDefault] = useState(false);



    useEffect(() => {
        props.onValuesUpdated(props.index, isDefault, beneficiary, payer);
    }, [beneficiary, payer, isDefault])

    useEffect(() => {
        setIsDefault(props.isDefault)
    }, [props])


    return (
        <div className="flex flex-row justify-between">
            {(props.index === 0) ? <div className="text-H7 font-normal text-black">Beneficiary & Payer</div> : <div></div>}
            <div className="flex flex-row gap-[12px]">
                <Input className={"w-[200px]"} onChange={(a) => setBeneficiary(a.target.value ?? "")} defaultValue={props.beneficiary} />
                <Input className={"w-[200px]"} onChange={(a) => setPayer(a.target.value ?? "")} defaultValue={props.payer} />
                <Radio className={"w-[120px]"} checked={props.isDefault} onChange={(a) => {
                    setIsDefault(a.target.checked)
                }} >Set Default</Radio>
            </div>

        </div>)

}



export default BrandHubBeneficiaryPayer;