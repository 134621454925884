import { t } from "i18next";
import { FunctionComponent, useEffect, useState } from "react";
import { DonutChart } from "react-circle-chart";
import { Delete, Edit } from "../../assets/IconComponents";
import BrandLogo from "../../components/BrandLogo";
import ContentContainer from "../../components/ContentContainer";
import { BrandRequest } from "../../services/BrandApi";
import Icon from "../../components/Icon";
import SvgMoreIcon from "../../assets/IconComponents/MoreIcon";
import { Dropdown, MenuProps } from "antd";
import { blogSVG, fbSVG, googleSVG, instagramSVG, linkedInSVG, metaSVG, newsletterSVG, xSVG } from "../../assets/IconComponents/svgs";

interface BrandHubBrandLineProps {
  brand: BrandRequest
  editBrand: (brand: BrandRequest) => void
  deleteBrand: (brand: BrandRequest) => void
}

const BrandHubBrandLine: FunctionComponent<BrandHubBrandLineProps> = (props: BrandHubBrandLineProps) => {
  const [menuItems, setMenuItems] = useState<MenuProps["items"]>([]);

  useEffect(() => {
    setMenuItems([
      {
        key: '1',
        label: "Edit",
        icon: <Icon component={Edit} className="w-[20px]" width="20" height="20" viewBox="0 0 20 20" />,
        onClick: (e) => { props.editBrand(props.brand); e.domEvent.stopPropagation(); },
      },
      {
        key: '2',
        label: (
          <span className="text-red">Delete</span>
        ),
        icon: <Icon component={Delete} color="#FF5555" className="w-[20px]" width="20" height="20" viewBox="0 0 20 20" />,
        onClick: (e) => { props.deleteBrand(props.brand); e.domEvent.stopPropagation(); },
      },
    ]);
  }, []);

  const menuConfig = {
    items: menuItems
  };

  function getSocialIcon(apiType: string | undefined) {
    switch (apiType) {
      case "FACEBOOK":
      case "FACEBOOK_PAGE":
      case "FACEBOOK_AD_ACCOUNT":
        return fbSVG

      case "X":
        return xSVG

      case "INSTAGRAM":
        return instagramSVG

      case "LINKEDIN":
        return linkedInSVG

      case "BLOG":
        return blogSVG

      case "NEWSLETTER":
        return newsletterSVG

      case "GOOGLE_ADS":
        return googleSVG

      case "META_ADS":
        return metaSVG

      default:
        return ""
    }
  }

  return (
    <ContentContainer
      hoverable={false}
      onClick={() => {
        props.editBrand(props.brand);
      }}
      className="mt-[10px] w-full md:h-[112px] border-1 border-border !rounded-[8px] p-[18px] flex items-center justify-between cursor-pointer"
      children={
        <div className="flex flex-row justify-stretch w-full">
          <div className="w-[80px] h-[80px] rounded-md flex items-center justify-center">
            <BrandLogo width={80} height={80} brand={props.brand} />
          </div>
          <div className="flex flex-col justify-between w-full">
            <div className="ml-[20px]">
              <p className="text-H5 font-medium text-black">{props.brand.brand_name}</p>
              <p className="text-BodyMedium text-footnote mt-1 line-clamp-1">
                {props.brand.about_company.length > 100 ? `${props.brand.about_company.slice(0, 100)}...` : props.brand.about_company}
              </p>
            </div>
            <div className="w-full md:w-fit flex flex-row items-center max-md:justify-end md:justify-between md:min-w-[150px] mt-[25px] md:mt-0 ml-2">
              {props.brand.definition_ratio !== undefined &&
                props.brand.definition_ratio < 100 && (
                  <div className="md:ml-3 flex flex-row items-center">
                    <div
                      data-tooltip-id="app-tooltip"
                      data-tooltip-html={t(
                        "complete-your-brand-profile-to-get-more-accurate-results"
                      )}
                    >
                      <DonutChart
                        items={[
                          {
                            value: props.brand.definition_ratio ?? 0,
                            label: "",
                            color:
                              props.brand.definition_ratio > 80
                                ? "#00CB2D"
                                : "#FF5555",
                          },
                        ]}
                        showTotal={false}
                        size={20}
                        trackColor="#EAEBE6"
                      />
                    </div>

                    <span
                      className={`text-H8  mr-[3px] ml-[5px] mt-[1px]`}
                      style={{
                        color:
                          props.brand.definition_ratio > 80
                            ? "#00CB2D"
                            : "#FF5555",
                      }}
                    >
                      %{props.brand.definition_ratio}
                    </span>
                    <div
                      className="ml-3"
                      style={{
                        width: "1px",
                        height: "10px",
                        backgroundColor: "#D9D9D9",
                      }}
                    ></div>
                  </div>
                )}
              {props.brand.definition_ratio === undefined ||
                (props.brand.definition_ratio >= 100 && (
                  <div
                    className="md:ml-3"
                    style={{
                      minWidth: "75px", // Adjust the min-width value
                      height: "26px",
                    }}
                  ></div>
                ))}
              {props.brand?.brand_socials && props.brand?.brand_socials?.length > 0 && (
                <>
                  {props.brand?.brand_socials?.map((social, index) => (
                    <div
                      key={index}
                      className="flex items-center justify-center w-[20px] h-[20px] "
                      dangerouslySetInnerHTML={{ __html: getSocialIcon(social.apiKeyModel?.apiType).replace(/width="[^"]*"/, 'width="20px"').replace(/height="[^"]*"/, 'height="20px"') }}>
                    </div>
                  ))}
                </>
              )}
            </div>
          </div>
          <div>
            <Dropdown menu={menuConfig} placement="bottomRight" arrow trigger={["hover"]}>
              <span
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <Icon component={SvgMoreIcon} className="w-[20px]" width="20" height="20" viewBox="0 0 20 20" />
              </span>
            </Dropdown>

          </div>
        </div>
      }
    />
  );
}

export default BrandHubBrandLine;
