import { FunctionComponent, useEffect, useState, useRef } from "react";
import { Button, Input, InputRef, Tag, theme } from "antd";
import { PlusOutlined, ReloadOutlined, SyncOutlined } from "@ant-design/icons";
import { useAppDispatch, useAppSelector } from "../../helpers/hooks";
import { setNewBrandInfo } from '../../store/brandSlice';
import { BrandKeywords } from "../../services/BrandApi";
import SvgRefreshIcon from "../../assets/IconComponents/RefreshIcon";
import { t } from "i18next";

interface TagSelectorProps {
  tagsSelected: (tags: BrandKeywords[]) => void;
  selectedTags?: BrandKeywords[];
  refreshKeywords: () => void;
}

const TagSelector: FunctionComponent<TagSelectorProps> = (props: TagSelectorProps) => {
  const { token } = theme.useToken();
  const [selectedTags, setSelectedTags] = useState<BrandKeywords[]>([]);
  const [inputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const inputRef = useRef<InputRef>(null);
  const brandInfo = useAppSelector(k => k.brand.newBrand);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (props.selectedTags) {
      setSelectedTags(props.selectedTags ?? []);
    }
  }, [props.selectedTags]);

  useEffect(() => {
    if (brandInfo.selectedKeywords) {
      const sTags = [...brandInfo.selectedKeywords, ...(brandInfo.userEnteredKeywords ?? [])];
      setSelectedTags(sTags);
    }
  }, [brandInfo.selectedKeywords]);

  const handleClose = (removedTag: string) => {
    const updatedTags = selectedTags.filter(tag => tag.value !== removedTag);
    const tagToRemove = selectedTags.find(tag => tag.value === removedTag);

    if (tagToRemove?.source === "USER") {
      dispatch(setNewBrandInfo({
        ...brandInfo,
        userEnteredKeywords: brandInfo.userEnteredKeywords?.filter(tag => tag.value !== removedTag)
      }));
    } else {
      dispatch(setNewBrandInfo({
        ...brandInfo,
        selectedKeywords: brandInfo.selectedKeywords?.filter(tag => tag.value !== removedTag)
      }));
    }

    setSelectedTags(updatedTags);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const handleInputConfirm = () => {
    if (inputValue && !selectedTags.some(tag => tag.value === inputValue)) {
      const newTag = { source: "USER", value: inputValue };
      const updatedTags = [...selectedTags, newTag];
      setSelectedTags(updatedTags);
      dispatch(setNewBrandInfo({
        ...brandInfo,
        userEnteredKeywords: [...(brandInfo.userEnteredKeywords ?? []), newTag]
      }));
    }
    setInputVisible(false);
    setInputValue("");
  };

  const showInput = () => {
    setInputVisible(true);
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  };

  const tagPlusStyle: React.CSSProperties = {
    background: token.colorBgContainer,
    borderStyle: "dashed",
    cursor: "pointer",
    height: 24,
    lineHeight: "24px",
  };

  return (
    <div className="mt-5">
      <div className="flex flex-row justify-between">
        <p className="text-H7 font-normal mt-2 text-black">Related Keywords</p>
        <div className=" pt-1 my-1 w-3/4 h-full">
          <div className="pb-3 px-1">
            {selectedTags.map(tag => (
              <Tag
                key={tag.value}
                className="mb-2"
                closable
                onClose={() => tag.value && handleClose(tag.value)}
                color={tag.source === "USER" ? "processing" : "default"}
              >
                {tag.value}
              </Tag>
            ))}
          </div>
          <div className="flex flex-row items-center gap-1">
            {inputVisible ? (
              <Input
                ref={inputRef}
                type="text"
                size="small"
                style={{ width: 90 }}
                value={inputValue}
                onChange={handleInputChange}
                onBlur={handleInputConfirm}
                onPressEnter={handleInputConfirm}
              />
            ) : (
              <Tag onClick={showInput} style={tagPlusStyle}>
                <PlusOutlined /> New Keyword
              </Tag>
            )}
            <Button
              size="small"
              type="default"
              className="text-BodySmall"
              icon={<SyncOutlined />}
              onClick={props.refreshKeywords}
            >
              {t("refresh")}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TagSelector;
