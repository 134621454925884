import { t } from "i18next";
import PrimaryButton from "./PrimaryButton";
import { AgeGroups } from "../constants/AgeGroups";
import { ContentObjectives } from "../constants/ContentObjectives";
import { Educations } from "../constants/Educations";
import { GenderGroups } from "../constants/GenderGroup";
import { TonOfVoices } from "../constants/TonOfVoices";
import { useAppDispatch, useAppSelector } from "../helpers/hooks";
import { INewContent } from "../models/NewContent";
import { useNavigate } from "react-router-dom";
import { ContentWizardStep } from "../models/ContentWizardStep";
import { ContentType } from "../constants/ContentType";
import { Mediums } from "../constants/Mediums";
import moment from "moment";
import { useEffect, useState } from "react";
import SvgHideLeft from "../assets/IconComponents/HideLeft";
import { v4 as uuidv4 } from "uuid";
import {
  Content,
  ContentRequest,
  useSave4Mutation,
} from "../services/ContentApi";

import { toggleLoading } from "../store/appSlice";
import Subject from "../pages/NewContentWizard/ContentDetails/Subject";
import ApplyFilters from "../pages/NewContentWizard/ContentDetails/ApplyFilters";
import { BrandRequest, FileProps } from "../services/BrandApi";
import LogoSelector from "../pages/NewContentWizard/ContentDetails/LogoSelector";
import ProductSelector from "../pages/NewContentWizard/ContentDetails/ProductSelector";
import { AudienceResponse, useLazyGetAudienceByIdQuery } from "../services/AudiencetApi";
import { ContentSize } from "../constants/ContentSize";
import { ProductRevisionRequest, useApplyProductUpdatesMutation } from "../services/CreativeApi";
import { useRemoveBackgroundMutation } from "../services/ImageopsApi";
import { instanceOfApiErrorWithCode } from "../services/Core/BaseApi";
import { log } from "console";
import { ConsoleSqlOutlined } from "@ant-design/icons";
import { PaymentErrorCode } from "../services/Error/PaymentErrorCode";
import SliderCounter from "../pages/NewContentWizard/ProductPhotshootDetails/SliderCounter";
import { setNewContent } from "../store/contentSlice";


export interface IUpdateBriefProps {
  onClose: () => void;
  className?: string;
  content?: Content;
  updateBrief: () => void;
  onContentCreated: () => void;
  newRequestId?: (string) => void;
  onCreditError?: () => void
}

function UpdateBrief(props: IUpdateBriefProps) {
  const dispatch = useAppDispatch();
  const newContent = useAppSelector((k) => k.content.newContent);
  const navigate = useNavigate();
  const [isCollapsed, setCollapsed] = useState(false);
  const isContentLoading = useAppSelector((k) => k.content.isContentLoading);
  const activeAIModels = useAppSelector((k) => k.persisted.activeAIModels);
  const [changed, setChanged] = useState(false);
  const [logoSelectionList, setLogoSelectionList] = useState<FileProps[]>([])
  const [productSelectionList, setProductSelectionList] = useState<FileProps[]>([])
  const [lastBackgroundRemoved, setLastBackgroundRemoved] = useState<string>("")
  const [getAudience, { data: contentAudience }] = useLazyGetAudienceByIdQuery();
  const getRequestId = () => {
    const requestId = uuidv4();
    if (props.newRequestId) {
      props.newRequestId(requestId);
    }
    return requestId;
  };
  const [briefContent, setBriefContent] = useState<INewContent>({
    contentAbout: "",
    contentType: newContent?.contentType,
    medium: Mediums[0],
    loopName: `Loop_${moment().format("DD_MM_yyyy_hh_mm")}`,
    mediumOption: Mediums[0].options![0],
    wizardStep: ContentWizardStep.contentResult,
    tonOfVoice: [TonOfVoices[0]],
    contentObjective: [ContentObjectives[0]],
    genderGroup: [GenderGroups[0]],
    ageGroup: [AgeGroups[0]],
    education: [Educations[0]],
    updateBrief: true,
    cta: newContent?.cta
  });



  const [
    createContent,
    {
      isLoading: isContentCreationLoading,
      isSuccess: contentCreationSuccess,
      data: contentCreationData,
    },
  ] = useSave4Mutation();

  const [
    removeBackground,
    { data: backgroundRemovedImage, isLoading: backgroundRemovalInProgress },
  ] = useRemoveBackgroundMutation();


  useEffect(() => {
    if (backgroundRemovedImage?.backgroundRemovedFile?.id) {
      const updatedSelectionList = productSelectionList.map((item) =>
        item.id === (briefContent.productFileId ?? "")
          ? {
            id: backgroundRemovedImage?.backgroundRemovedFile?.id,
            name: backgroundRemovedImage?.backgroundRemovedFile?.name,
            uploaded: true,
          }
          : item
      );
      setProductSelectionList(updatedSelectionList);
      setLastBackgroundRemoved(
        backgroundRemovedImage?.backgroundRemovedFile?.id
      );
      setBriefContent({
        ...briefContent,
        productFileId: backgroundRemovedImage?.backgroundRemovedFile?.id,
      } as INewContent);
      setTimeout(() => {
        createLoop(backgroundRemovedImage?.backgroundRemovedFile?.id);
      }, 2000);
    }
  }, [backgroundRemovedImage]);

  const [
    updateProduct,
    {
      data: updateProductData,
      isSuccess: productUpdatedSuccessfully,
      isLoading: productUpdating,
      error: productUpdatingError,
    },
  ] = useApplyProductUpdatesMutation();

  useEffect(() => {
    const toggle = isContentCreationLoading || productUpdating || backgroundRemovalInProgress;
    if (!toggle) {
      setTimeout(() => {
        toggleLoading(toggle);
      }, 1000);
    }
    else {
      dispatch(toggleLoading(toggle));
    }
  }, [isContentCreationLoading, productUpdating, backgroundRemovalInProgress]);


  useEffect(() => {
    if (contentCreationSuccess || productUpdatedSuccessfully) {
      props.onContentCreated();
    }
  }, [contentCreationSuccess, productUpdatedSuccessfully]);

  useEffect(() => {
    if (productUpdatingError && instanceOfApiErrorWithCode(productUpdatingError)) {
      const errorData = productUpdatingError.data;
      if (errorData.errorCode === PaymentErrorCode.CreditLimitExceeded && props.onCreditError) {
        props.onCreditError()
      } else {
        console.log(errorData.message);
      }
    }
  }, [productUpdatingError]);

  const createLoop = (newProductId: string | undefined) => {
    if ((props.content?.platform ?? "") === "Product") {
      var payloadProduct = {
        productRevisionRequest: {
          request_id: getRequestId(),
          content_id: props.content?.id?.content_id,
          brief: briefContent?.contentAbout ?? undefined,
          referred_file_id:
            briefContent?.logoFileId !== undefined &&
              briefContent?.logoFileId !== ""
              ? briefContent.logoFileId
              : undefined,
          product_file_id: newProductId
            ? newProductId
            : briefContent?.productFileId !== undefined &&
              briefContent?.productFileId !== ""
              ? briefContent.productFileId
              : undefined,
          refresh: false,
          update_brief: true,
          generate_similar: false,
        } as ProductRevisionRequest,
      };

      if (
        newProductId ||
        (briefContent.productFileId ?? "") === (lastBackgroundRemoved)
      ) {
        updateProduct(payloadProduct);
      } else {
        removeBackground({
          removeBackgroundRequest: { imageFileId: (briefContent.productFileId ?? "") },
        });
      }

    } else {
      if (briefContent?.medium?.name != "Custom") {
        var payload = {
          name: props.content?.name, //`Loop_${moment().format("DD_MM_yyyy_hh_mm")}`,
          type: ContentType[briefContent?.contentType ?? ContentType.text],
          platform: `${briefContent?.medium?.name} | ${briefContent?.mediumOption?.title}`,
          filters: getFilters(),
          brand_id: props.content?.brand_id,
          content_id: props.content?.id?.content_id,
          cta: briefContent.cta,
          logo_file_id:
            briefContent?.logoFileId !== undefined &&
              briefContent?.logoFileId !== ""
              ? briefContent.logoFileId
              : undefined,
          product_file_id:
            briefContent?.productFileId !== undefined &&
              briefContent?.productFileId !== ""
              ? briefContent.productFileId
              : undefined,
        } as ContentRequest;

        createContent({
          contentRequest: payload,
        });
      } else {
        var payload = {
          name: props.content?.name, //`Loop_${moment().format("DD_MM_yyyy_hh_mm")}`,
          type: ContentType[briefContent?.contentType ?? ContentType.text],
          platform: `${briefContent?.medium?.name}`,
          image_width: briefContent.imageWidth,
          image_height: briefContent.imageHeight,
          logo_file_id:
            briefContent?.logoFileId !== undefined &&
              briefContent?.logoFileId !== ""
              ? briefContent.logoFileId
              : undefined,
          product_file_id:
            briefContent?.productFileId !== undefined &&
              briefContent?.productFileId !== ""
              ? briefContent.productFileId
              : undefined,
          filters: getFilters(),
          brand_id: props.content?.brand_id,
          content_id: props.content?.id?.content_id,
          cta: briefContent?.cta,
          ad_id: briefContent.adId,
          creative_image_id: briefContent.creativeImageId,
          creative_theme: briefContent.creative_theme,
          creative_topic: briefContent.creative_topic,
          creative_referred_image_id: briefContent.creative_referred_image_id,
        } as ContentRequest;
        if (newContent?.contentType === ContentType.text) {
          if (newContent?.mediumOption?.description == "Words") {
            payload["words_limit"] =
              Number(newContent?.mediumOption?.value) ?? 100;
          } else if (newContent?.mediumOption?.description == "Sentences") {
            payload["sentences_limit"] =
              Number(newContent?.mediumOption?.value) ?? 100;
          }
        }
        createContent({
          contentRequest: payload,
        });
      }
    }
  };

  const getFilters = () => {
    var payload = {
      bot_type: "marketing",
      objective:
        briefContent?.contentObjective?.map((k) => k.title).join(" & ") ?? "",
      tone_of_voice: getTonOfVoices(),
      message: briefContent?.contentAbout ?? "",
      audience_id: briefContent?.audienceId
    };
    if (briefContent?.contentType === ContentType.image) {
      payload["purpose"] = "Background";
      payload["bot_type"] = "image-gen-prompt";
    }
    return payload;
  };

  const getTonOfVoices = () => {
    const values = briefContent?.tonOfVoice?.map((k) => k.value);
    const filteredVoices = TonOfVoices.filter(
      (k) => values?.includes(k.value)!
    );
    return filteredVoices.map((k) => k.key).join(" & ");
  };

  useEffect(() => {

    if (props.content) {
      const filters = JSON.parse(props.content.filters ?? "");
      const creative = props.content?.creative ? JSON.parse(props.content?.creative) : undefined;

      let strPlatformName = props.content.platform?.split(" | ")[0];
      let strPlatformOption = props.content.platform?.split(" | ")[1];
      const platform = Mediums.filter(
        (k) =>
          k.contentType ===
          (props.content?.type === "text"
            ? ContentType.text
            : ContentType.image)
      ).find((k) => k.name === strPlatformName);
      const platformOption = platform?.options?.find(
        (k) => k.title === strPlatformOption
      );

      if ((props.content?.platform ?? "") === "Product") {
        const lastBg = (props.content?.platform ?? "") === "Product" ? (JSON.parse(props.content?.photoshootContent ?? "").product_photo_id ?? "") : "";
        setLastBackgroundRemoved(lastBg)
      }
      setBriefContent({
        medium: platform,
        imageHeight: props.content?.image_height + "",
        imageWidth: props.content?.image_width + "",
        logoFileId:
          (props.content?.platform ?? "") === "Product"
            ? JSON.parse(props.content?.photoshootContent ?? "")
              .referred_image_id ?? undefined
            : props.content?.logo_file_id,
        productFileId:
          (props.content?.platform ?? "") === "Product"
            ? JSON.parse(props.content?.photoshootContent ?? "")
              .product_photo_id ?? undefined
            : props.content?.product_file_id,
        contentAbout: filters.message,
        contentType:
          ContentType[props.content.type ?? "text"] ?? ContentType.text,
        tonOfVoice:
          filters.tone_of_voice && filters.tone_of_voice != ""
            ? filters.tone_of_voice
              .split(" & ")
              .map((l) => TonOfVoices.find((k) => k.key === l))
            : undefined,
        contentObjective:
          filters.objective && filters.objective != ""
            ? filters.objective
              .split(" & ")
              .map((l) => ContentObjectives.find((k) => k.title === l))
            : undefined,
        genderGroup: filters.gender_group
          .split(" & ")
          .map((l) => GenderGroups.find((k) => k.title === l)),
        ageGroup: filters.age_group
          .split(" & ")
          .map((l) => AgeGroups.find((k) => k.title === l)),
        education: filters.education_level
          .split(" & ")
          .map((l) => Educations.find((k) => k.title === l)),
        brand: { id: props.content.brand_id } as BrandRequest,
        audienceId: filters.audience_id,
        cta: props.content.cta,
        isCreative:
          props.content.creative !== undefined &&
          props.content.creative.length > 0,
        creativeImageId: creative ? creative.upload_image_id : undefined,
        adId: creative ? creative.ad_id : undefined,
        contentSize: getContentSizeFromContent(props.content),
        creative_theme: creative ? creative.theme : undefined,
        creative_topic: creative ? creative.topic : undefined,
        creative_referred_image_id: creative
          ? creative.referred_image_id
          : undefined,
      });
    }
  }, [props.content]);

  useEffect(() => {
    if (props.content) {
      const filters = JSON.parse(props.content.filters ?? "");
      if (filters.audience_id && filters.audience_id.length > 0) {
        getAudience({ audienceId: filters.audience_id })
      }
    }
  }, [props.content])

  useEffect(() => {
    if (logoSelectionList.length === 0 && briefContent && briefContent.logoFileId) {
      setLogoSelectionList([{ id: briefContent.logoFileId }])
    }
  }, [briefContent])

  useEffect(() => {
    if (productSelectionList.length === 0 && briefContent && briefContent.productFileId) {
      setProductSelectionList([{ id: briefContent.productFileId }])
    }
  }, [briefContent])

  const getContentSizeFromContent = (content) => {
    const isCreative = content.creative && content.creative.length > 0;
    let contentSize = ContentSize.post;
    const width = content.image_width ?? 1080;
    const height = content.image_height ?? 1080;
    if (width === 1200 && height == 628 && isCreative) {
      contentSize = ContentSize.creative_landscape;
    } else if (width === 1200 && height == 628) {
      contentSize = ContentSize.landscape;
    }
    else if (width == 1080 && height == 1920 && isCreative) {
      contentSize = ContentSize.creative_story
    }
    else if (width == 1080 && height == 1920) {
      contentSize = ContentSize.story;
    } else if (width == 300 && height == 250) {
      contentSize = ContentSize.mobilemedr;
    } else if (width == 320 && height == 50) {
      contentSize = ContentSize.mobileleaderb;
    } else if (width == 1080 && height == 1080 && isCreative) {
      contentSize = ContentSize.creative_post;
    }
    return contentSize;
  };


  return (
    <div
      className={` ${
        !isCollapsed ? "lg:w-[360px]" : "lg:w-[60px]"
      } transition-all bg-white  md:h-[calc(100vh-56px)] border-border border-1 lg:flex flex-col -ml-2 ${
        props.className
      }`}
    >
      <div
        className={`h-[62px] w-full border-b-1 border-border hidden md:flex items-center ${
          !isCollapsed ? "justify-between" : "justify-center"
        }`}
      >
        {!isCollapsed && !props.content?.read_only && (
          <text className="text-H6 text-black ml-5">{t("update-brief")}</text>
        )}
        {!isCollapsed && props.content?.read_only && (
          <text className="text-H6 text-black ml-5">{t("brief")}</text>
        )}

        <button
          className={`h-10 w-10 min-w-[40px] min-h-[40px] ${
            !isCollapsed ? "mr-[5px]" : ""
          }`}
          onClick={() => {
            setCollapsed(!isCollapsed);
          }}
        >
          <div>
            <SvgHideLeft
              className={` transition-all transform-gpu ${
                isCollapsed ? "rotate-180" : "rotate-0"
              }`}
            />
          </div>
        </button>
      </div>

      {!isCollapsed && (props.content?.platform ?? "") !== "Product" && (
        <div className="relative max-h-[calc(100vh-300px)] md:max-h-full h-[calc(100vh-120px)] w-full flex-col justify-between overflow-scroll scrollbar-hide flex-1 p-4 gap-4">
          {props.content?.read_only && (
            <div className="absolute bg-white/50 left-0 top-0 right-0 bottom-0 z-30"></div>
          )}
          <div className="flex flex-col gap-4">
            {(((briefContent?.contentType ?? "text") === ContentType.image &&
              !briefContent.isCreative) ||
              (briefContent?.contentType ?? "text") === ContentType.text) && (
              <Subject
                briefContent={briefContent}
                className="flex flex-col"
                contentUpdated={(newContent) => {
                  setBriefContent({
                    ...briefContent,
                    contentAbout: newContent.contentAbout,
                    cta: newContent.cta,
                  } as INewContent);
                  setChanged(true);
                }}
                isCreativePages={true}
                useKeywords={briefContent && briefContent.isCreative}
              />
            )}

            {(briefContent?.contentType ?? "text") === ContentType.image &&
              briefContent.isCreative && (
                <Subject
                  briefContent={briefContent}
                  className="flex flex-col"
                  contentUpdated={(newContent) => {
                    if (
                      newContent.creative_topic !== briefContent.creative_topic
                    ) {
                      setBriefContent({
                        ...briefContent,
                        creative_topic: newContent.creative_topic,
                      } as INewContent);
                      setChanged(true);
                    }
                  }}
                  isCreativePages={true}
                  useKeywords={briefContent && briefContent.isCreative}
                />
              )}

            <>
              <div className="w-[328px]  bg-stone-200" />
              <ApplyFilters
                briefContent={briefContent}
                className="flex flex-col"
                audiencePopupClassname="w-[325px]"
                onAudienceEdit={(data: AudienceResponse) => {}}
                onAudienceCreate={() => {}}
                recentlyAddedAudience={contentAudience ? [contentAudience] : []}
                contentUpdated={(newContent) => {
                  setBriefContent({
                    ...briefContent,
                    audienceId: newContent.audienceId,
                    tonOfVoice: newContent.tonOfVoice,
                    contentObjective: newContent.contentObjective,
                  });
                  setChanged(true);
                }}
              />
            </>

            {(briefContent?.contentType ?? "text") === ContentType.image &&
              briefContent.isCreative && (
                <SliderCounter
                  title="Number of Outputs"
                  className="flex-col w-full"
                  isVertical={true}
                  minCount={0}
                  maxCount={4}
                  numberOfImagesChanged={(num) => {
                    dispatch(
                      setNewContent({
                        ...newContent,
                        sliderCount: num,
                      })
                    );
                  }}
                />
              )}

            {(briefContent?.contentType ?? "text") === ContentType.image &&
              !briefContent.isCreative && (
                <>
                  <div className="w-[328px] h-px bg-stone-200" />
                  <LogoSelector
                    className={"!w-[328px] !border-none !p-0 !gap-3"}
                    headerClassName={"!w-[328px]"}
                    contentClassName="!h-[120px]"
                    logoSelectionList={logoSelectionList}
                    briefContent={briefContent}
                    onSelectionChanged={(pickedFiles: FileProps[]) => {
                      if (pickedFiles.length > 0) {
                        setBriefContent({
                          ...briefContent,
                          logoFileId: pickedFiles.at(0)?.id,
                        });
                        setChanged(true);
                      } else if (briefContent.logoFileId) {
                        setBriefContent({
                          ...briefContent,
                          logoFileId: undefined,
                        });
                        setChanged(true);
                      }
                    }}
                    onListingChanged={(file) => {
                      setLogoSelectionList([...file]);
                    }}
                  />
                </>
              )}

            {(briefContent?.contentType ?? "text") === ContentType.image &&
              !briefContent.isCreative &&
              briefContent.contentSize !== ContentSize.mobileleaderb && (
                <>
                  <div className="w-[328px] h-px bg-stone-200" />
                  <ProductSelector
                    className={"!w-[328px] !border-none !p-0 !gap-3"}
                    headerClassName={"!w-[328px]"}
                    contentClassName="!h-[120px]"
                    productSelectionList={productSelectionList}
                    briefContent={briefContent}
                    onSelectionChanged={(pickedFiles: FileProps[]) => {
                      if (pickedFiles.length > 0) {
                        setBriefContent({
                          ...briefContent,
                          productFileId: pickedFiles.at(0)?.id,
                        });
                        setChanged(true);
                      } else if (briefContent.productFileId) {
                        setBriefContent({
                          ...briefContent,
                          productFileId: undefined,
                        });
                        setChanged(true);
                      }
                    }}
                    onListingChanged={(files: any[]) => {
                      setProductSelectionList([...files]);
                    }}
                  />
                </>
              )}
          </div>
        </div>
      )}
      {!isCollapsed && (props.content?.platform ?? "") === "Product" && (
        <div className="relative max-h-[calc(100vh-300px)] md:max-h-full h-[calc(100vh-120px)] w-full flex-col justify-between overflow-scroll scrollbar-hide flex-1 p-4 gap-4">
          {props.content?.read_only && (
            <div className="absolute bg-white/50 left-0 top-0 right-0 bottom-0 z-30"></div>
          )}
          <div className="flex flex-col gap-4">
            <ProductSelector
              className={"!w-[328px] !border-none !p-0 !gap-3"}
              headerClassName={"!w-[328px]"}
              contentClassName="!h-[120px]"
              productSelectionList={productSelectionList}
              briefContent={briefContent}
              bgRemoved={(str) => setLastBackgroundRemoved(str)}
              callingParent="productphotoshoot"
              onSelectionChanged={(pickedFiles: FileProps[]) => {
                if (pickedFiles.length > 0) {
                  setBriefContent({
                    ...briefContent,
                    productFileId: pickedFiles.at(0)?.id,
                  });
                  setChanged(true);
                } else if (briefContent.productFileId) {
                  setBriefContent({
                    ...briefContent,
                    productFileId: undefined,
                  });
                  setChanged(true);
                }
              }}
              onListingChanged={(files: any[]) => {
                setProductSelectionList([...files]);
              }}
            />

            <LogoSelector
              className={"!w-[328px] !border-none !p-0 !gap-3"}
              headerClassName={"!w-[328px]"}
              contentClassName="!h-[120px]"
              callingParent="productphotoshoot"
              logoSelectionList={logoSelectionList}
              briefContent={briefContent}
              onSelectionChanged={(pickedFiles: FileProps[]) => {
                if (pickedFiles.length > 0) {
                  setBriefContent({
                    ...briefContent,
                    logoFileId: pickedFiles.at(0)?.id,
                  });
                  setChanged(true);
                } else if (briefContent.logoFileId) {
                  setBriefContent({
                    ...briefContent,
                    logoFileId: undefined,
                  });
                  setChanged(true);
                }
              }}
              onListingChanged={(file) => {
                setLogoSelectionList([...file]);
              }}
            />

            <Subject
              briefContent={briefContent}
              className="flex flex-col"
              callingParent="productphotoshoot"
              isCreativePages={true}
              contentUpdated={(newContent) => {
                setBriefContent({
                  ...briefContent,
                  contentAbout: newContent.contentAbout,
                  cta: newContent.cta,
                } as INewContent);
                setChanged(true);
              }}
              useKeywords={briefContent && briefContent.isCreative}
            />
          </div>
        </div>
      )}
      {!isCollapsed &&
        changed &&
        !props.content?.read_only &&
        !isContentLoading && (
          <div className="h-[70px] w-full px-[15px] border-t-1 border-border flex items-center justify-end">
            <PrimaryButton
              className="w-full h-[40px] "
              title={
                (props.content?.platform ?? "") === "Product"
                  ? "Generate"
                  : t("update-brief")
              }
              onClick={() => {
                createLoop(undefined);
              }}
            />
          </div>
        )}
    </div>
  );
}

export default UpdateBrief;
