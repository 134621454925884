import React, { useEffect, useState } from 'react';

function AnimatedText({ text = "" }) {
    const [displayedWords, setDisplayedWords] = useState<string[]>([]);

    useEffect(() => {
        const words = text.split(' ');
        let index = 0;
        const interval = setInterval(() => {
            setTimeout(() => {
                setDisplayedWords((prevWords) => [...prevWords, words[index]]);
                index += 1;
                if (index >= words.length) {
                    clearInterval(interval);
                }
            }, 1000);
        }, 30);

        return () => clearInterval(interval);
    }, []);

    return (
        <div className="border border-border h-[80px] w-full rounded py-2 px-1.5 text-ellipsis overflow-hidden line-clamp-3 ">
            <span className=" ">{displayedWords.join(' ')}</span>
        </div>
    );
}

export default AnimatedText;
