import * as React from "react";
const SvgPlusBlack = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={25}
    height={25}
    fill="none"
    {...props}
  >
    <path
      fill="#121315"
      d="M22.014 12.515A1 1 0 0 1 21 13.5h-7.5V21a1 1 0 1 1-2 0v-7.5H4a1 1 0 1 1 0-2h7.5V4a1 1 0 1 1 2 0v7.5H21a1.002 1.002 0 0 1 1.014 1.015Z"
    />
  </svg>
);
export default SvgPlusBlack;
