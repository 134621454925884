import { t } from "i18next";
import { FunctionComponent } from "react";
import { useNavigate } from "react-router-dom";
import DontHaveAnyComponent from "./CreateNew/DontHaveAny";

interface BrandHubNoBrandLineProps {

}

const BrandHubNoBrandLine: FunctionComponent<BrandHubNoBrandLineProps> = () => {
    const navigate = useNavigate()
    return (
        <>
            <DontHaveAnyComponent
                handleClick={() => { navigate("/app/brandhub/new") }}
                title={t('get-started-with-brand-templates')}
                description={t('instantly-create-contents-tailored-to-your-brands-style-and-voice-using-templates')}
                titleClassName="text-H3"
            />
        </>
    );
}

export default BrandHubNoBrandLine;