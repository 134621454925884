import { t } from "i18next";
import { FunctionComponent, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../helpers/hooks";
import { setDialog, toggleDialog, toggleLoading } from "../../../store/appSlice";
import { AudienceResponse, useAddAudienceMutation, useDeleteAudienceMutation } from "../../../services/AudiencetApi";
import PrimaryButton from "../../../components/PrimaryButton";
import MultiRangeSliderWrapper from "../../../components/Slider/MultiRangeSliderWrapper";
import { IAudienceInfo, setNewAudienceInfo, setNewBrandInfo } from "../../../store/brandSlice";
import { EducationLevels } from "../../../constants/EducationLevels";
import { Interests } from "../../../constants/Interests";
import IconButton from "../../../components/IconButton";
import { Delete } from "../../../assets/IconComponents";
import SvgAudienceArrowUp from "../../../assets/IconComponents/ArrowUpAudience";
import { GetGeoLocationsApiArg, useLazyGetGeoLocationsQuery } from "../../../services/AdManagerApi";
import { Button, Form, Input, Radio, Select } from "antd";
import IconSelect from "../../../assets/IconComponents/IconSelect";
import { ContentLanguages } from "../../../constants/ContentLanguages";
import { DownOutlined, MinusOutlined } from "@ant-design/icons";
import MarketingMetaAdsSettings from "../../../components/MarketingPlanner/MarketingAudienceMetaAdsSettings";
import { useLazyFindById4Query } from "../../../services/BrandApi";

interface IBrandHubCreateNewAudienceProps {
    className?: string;
    audience?: AudienceResponse;
    onClose: () => void;
    onAudienceSaved: (audienceResponse: AudienceResponse) => void;
    hideHeader?: boolean
    hideButtons?: boolean
    triggerAddAudience?: boolean
    saveToBrand?: boolean
    hideBorder?: boolean
    setAudience?: (audience: AudienceResponse) => void
    save?: (func) => void
    inCampaign?: boolean
    hideMetaAdsSettings?: boolean
    onMetaSettingsUpdated?: (adsCategorySelection?: boolean, beneficiarySelection?: boolean) => void
}

const genderOptions = [
    { label: 'All', value: 'all' },
    { label: 'Men', value: 'men' },
    { label: 'Women', value: 'women' },
];

const ageGroups = Array.from({ length: 101 }, (_, index) => index.toString());

const BrandHubCreateNewAudience: FunctionComponent<IBrandHubCreateNewAudienceProps> = (props: IBrandHubCreateNewAudienceProps) => {
    const dispatch = useAppDispatch();
    const { audience } = props;
    const [isCollapsed, setCollapsed] = useState(false);
    const [isExcludeLocations, setIsExcludeLocations] = useState(false);
    const [isExcludeInterests, setIsExcludeInterests] = useState(false);
    const [deleteAudience, { isSuccess: audienceDeleted, isLoading: audienceDeleting }] = useDeleteAudienceMutation();
    const brandInfo = useAppSelector((k) => k.brand.newBrand);
    const audienceInfo = useAppSelector((k) => k.brand.newAudience);
    const lastSelectedBrandID = useAppSelector((k) => k.persisted.lastSelectedBrandId)
    const [addAudience, { isLoading: audienceBeingAdded, isSuccess: audienceAddedSuccessfully, data: audienceAddedResponse }] = useAddAudienceMutation();
    const [getGeoLocations] = useLazyGetGeoLocationsQuery();
    const [geoLocations, setGeoLocations] = useState<any[]>([]);
    const [excludeGeoLocations, setExcludeGeoLocations] = useState<any[]>([]);
    const [searchingLocations, setSearchingLocations] = useState<string>('');
    const [searchingExcludeLocations, setSearchingExcludeLocations] = useState<string>('');
    const [getBrand, { data: brand, isLoading: brandLoading }] = useLazyFindById4Query()
    const [adsCategorySelection, setAdsCategorySelection] = useState<boolean>()
    const [beneficiarySelection, setBeneficiarySelection] = useState<boolean>()

    useEffect(() => {
        if (lastSelectedBrandID) {
            getBrand({ id: lastSelectedBrandID })
        }
    }, [lastSelectedBrandID])

    useEffect(() => {
        if (props.onMetaSettingsUpdated) {
            props.onMetaSettingsUpdated(adsCategorySelection, beneficiarySelection)
        }
    }, [adsCategorySelection, beneficiarySelection])

    useEffect(() => {
        if (brand && brand.meta_ads_settings) {
            dispatch(
                setNewAudienceInfo({
                    ...audienceInfo,
                    ads_category: brand?.meta_ads_settings.ads_category,
                    beneficiary: brand?.meta_ads_settings?.beneficiary_payer?.filter(a => a.isDefault).at(0)?.beneficiary,
                    payer: brand?.meta_ads_settings?.beneficiary_payer?.filter(a => a.isDefault).at(0)?.payer
                })
            );
        }
    }, [brand])

    useEffect(() => {
        if (adsCategorySelection || beneficiarySelection) {
            dispatch(
                setNewAudienceInfo({
                    ...audienceInfo,
                    age_group: undefined,
                    gender: ["all"],
                    interest: undefined,
                    education_level: undefined,
                    parenting_status: undefined,
                    location_exclude: undefined,
                    family: undefined,
                })
            );
        }
    }, [adsCategorySelection, beneficiarySelection])


    useEffect(() => {
        const propLocations: any[] = [];
        if (audience?.location?.length) {
            audience?.location.forEach(a => {
                propLocations.push({
                    label: `${a.name}/${a.type}`,
                    value: a.name
                })
            })
        }

        setGeoLocations(propLocations)

        const propExcludeLocations: any[] = [];
        if (audience?.location_exclude?.length) {
            audience?.location_exclude.forEach(a => {
                propExcludeLocations.push({
                    label: `${a.name}/${a.type}`,
                    value: a.name
                })
            })
        }

        setExcludeGeoLocations(propExcludeLocations)

        setIsExcludeLocations(propExcludeLocations.length > 0);

        setIsExcludeInterests((audience?.interest_exclude?.length ?? 0) > 0)
    }, [audience]);

    const convertToAudienceResponse = (audienceInfo: IAudienceInfo): AudienceResponse => {
        const {
            brand_id,
            audience_id,
            name,
            location,
            income_level,
            age_group,
            education_level,
            occupation,
            family,
            type_of_area,
            interest,
            loyalty,
            gender,
            language,
        } = audienceInfo;

        // Use the spread operator to copy properties
        return {
            brand_id,
            audience_id,
            name,
            location,
            income_level,
            age_group,
            education_level,
            occupation,
            family,
            type_of_area,
            interest,
            loyalty,
            gender,
            language,
            // Add or rename additional properties as needed
            organization_id: "your_default_value_for_organization_id", // Replace with your actual value or logic
        };
    };

    useEffect(() => {
        dispatch(toggleLoading(audienceBeingAdded));
        if (audienceAddedSuccessfully) {
            if (audienceAddedResponse) {
                dispatch(
                    setNewAudienceInfo({
                        ...audienceInfo,
                        audience_id: audienceAddedResponse.audience_id,
                    })
                );
                const existingAudiences = brandInfo.associatedAudiences ?? [];
                const modifiedList = existingAudiences.map((existingAudience) => {
                    if (existingAudience.audience_id === audienceInfo.audience_id) {
                        return convertToAudienceResponse({
                            ...audienceInfo,
                            audience_id: audienceAddedResponse.audience_id,
                        });
                    } else {
                        return existingAudience;
                    }
                })

                if (!modifiedList.some((element) => element.audience_id === audienceInfo.audience_id)) {
                    modifiedList.push(convertToAudienceResponse({
                        ...audienceInfo,
                        audience_id: audienceAddedResponse.audience_id
                    }));
                }
                console.log("Modified list is:", modifiedList);
                dispatch(
                    setNewBrandInfo({
                        ...brandInfo,
                        associatedAudiences: modifiedList,
                    })
                );
                props.onAudienceSaved(convertToAudienceResponse({
                    ...audienceInfo,
                    audience_id: audienceAddedResponse.audience_id,
                }))
            }

            // Close the modal or perform any other action
            props.onClose();
        }
    }, [audienceBeingAdded]);

    useEffect(() => {
        if (audience) {
            dispatch(
                setNewAudienceInfo({
                    ...audience,
                    brand_id: audience.brand_id,
                    audience_id: audience.audience_id,
                    name: audience.name,
                    location: audience.location,
                    location_exclude: audience.location_exclude,
                    income_level: audience.income_level ?? ["", ""],
                    age_group: audience.age_group ?? ['18', '65'],
                    education_level: audience.education_level,
                    occupation: audience.occupation,
                    language: audience.language,
                    family: audience.family,
                    type_of_area: audience.type_of_area,
                    interest: audience.interest,
                    interest_exclude: audience.interest_exclude,
                    loyalty: audience.loyalty,
                    gender: audience.gender,
                })
            );
        } else {
            dispatch(setNewAudienceInfo({ name: "Audience" }));
        }
    }, [audience]);

    useEffect(() => {
        dispatch(toggleLoading(audienceDeleting));
        if (audienceDeleted) {
            props.onClose();
        }
    }, [audienceDeleting]);

    useEffect(() => {
        if (props.triggerAddAudience) {
            addAudience({
                addAudienceRequest: {
                    ...audienceInfo,
                    name: (audienceInfo.name !== undefined && audienceInfo.name?.length > 0) ? audienceInfo.name : "Audience",
                    brand_id: props.saveToBrand ? lastSelectedBrandID : undefined,
                    audience_id: audienceInfo?.audience_id,
                    occupation: audienceInfo?.occupation,
                    location: audienceInfo.location,
                    income_level: audienceInfo.income_level,
                    age_group: audienceInfo.age_group,
                    education_level: audienceInfo.education_level,
                    family: audienceInfo.family,
                    language: audienceInfo.language,
                    type_of_area: audienceInfo.type_of_area,
                    interest: audienceInfo.interest,
                    loyalty: audienceInfo.loyalty,
                    gender: audienceInfo.gender,
                    location_exclude: audienceInfo.location_exclude,
                    interest_exclude: audienceInfo.interest_exclude,
                    ads_category: audienceInfo.ads_category,
                    beneficiary: audienceInfo.beneficiary,
                    payer: audienceInfo.payer
                },
            });

        }

    }, [props.triggerAddAudience])

    useEffect(() => {
        if (props.setAudience) {
            props.setAudience({
                ...audienceInfo,
                name: (audienceInfo.name !== undefined && audienceInfo.name?.length > 0) ? audienceInfo.name : "Audience",
                brand_id: props.saveToBrand ? lastSelectedBrandID : undefined,
                audience_id: audienceInfo?.audience_id,
                occupation: audienceInfo?.occupation,
                location: audienceInfo.location,
                income_level: audienceInfo.income_level,
                age_group: audienceInfo.age_group,
                language: audienceInfo.language,
                education_level: audienceInfo.education_level,
                family: audienceInfo.family,
                type_of_area: audienceInfo.type_of_area,
                interest: audienceInfo.interest,
                loyalty: audienceInfo.loyalty,
                gender: audienceInfo.gender,
                location_exclude: audienceInfo.location_exclude,
                interest_exclude: audienceInfo.interest_exclude,
                ads_category: audienceInfo.ads_category,
                beneficiary: audienceInfo.beneficiary,
                payer: audienceInfo.payer
            })
        }
    }, [audienceInfo]);

    const deleteAudienceDialog = () => {
        dispatch(
            setDialog({
                message:
                    t(
                        "are-you-sure-you-want-to-delete-this-audience"
                    ) ?? "",
                title: t("delete-audience"),
                actions: (
                    <>
                        <div className="flex w-full flex-col lg:flex-row lg:justify-end">
                            <PrimaryButton
                                className="h-10 lg:h-12 w-full lg:w-[140px]"
                                noFill
                                title={t("cancel")}
                                onClick={() => {
                                    dispatch(toggleDialog(false));
                                }}
                            />
                            <PrimaryButton
                                className="h-10 lg:h-12 w-full lg:w-[140px] lg:ml-2 max-lg:mt-1"
                                title={t("yes-delete")}
                                onClick={() => {
                                    dispatch(toggleDialog(false));
                                    deleteAudience({
                                        audienceId: audienceInfo.audience_id ?? "",
                                    });
                                }}
                            />
                        </div>
                    </>
                ),
                onClose: () => {
                    dispatch(toggleDialog(false));
                },
            })
        );

        dispatch(toggleDialog(true));
    };

    return (
        <div
            className={`flex flex-col w-full ${!props.inCampaign ?
                (!isCollapsed
                    ? !props.hideButtons
                        ? "h-[580px]"
                        : "h-[530px]"
                    : !props.hideButtons
                        ? "h-[730px]"
                        : "h-[630px]") : 'h-[54vh]'
                } bg-white ${!props.hideBorder ? 'border-1' : ''} rounded-[10px] transition-all`}
        >
            {!props.hideHeader && (
                <div className="flex flex-row w-full justify-between items-center border-b-1 py-2">
                    <div className="flex">
                        <span className="text-H7 text-black ml-4 mt-2 overflow-hidden">
                            {audience ? audience.name ?? "" : "Create New"}
                        </span>
                    </div>
                    {audienceInfo.name && (
                        <div className="md:mr-5">
                            <IconButton
                                className="hover:w-20"
                                title={t("delete")}
                                icon={Delete}
                                onClick={(e) => {
                                    deleteAudienceDialog();
                                }}
                            />
                        </div>
                    )}
                </div>
            )}
            <div className="flex flex-col w-full px-5 overflow-scroll scrollbar-hide mt-5">
                <Form.Item label={<p className={'text-H7 font-normal text-black'}>{t("audience-name") ?? ""}</p>}
                    labelCol={{ span: 7 }} labelAlign={'left'}>
                    <Input defaultValue={"Audience"}
                        maxLength={100}
                        onChange={(e) => {
                            dispatch(
                                setNewAudienceInfo({
                                    ...audienceInfo,
                                    name: e.target.value,
                                })
                            );
                        }}
                        value={audienceInfo.name ?? "Audience"}
                        size="middle"
                    />
                </Form.Item>
                <Form.Item label={<p className={'text-H7 font-normal text-black'}>Locations</p>}
                    tooltip="Enter the name of a city or area to start your search. As you type, the system will automatically suggest possible locations."
                    labelCol={{ span: 7 }} wrapperCol={{ span: 17 }} labelAlign={'left'}>
                    <Select
                        notFoundContent={searchingLocations ? undefined : <div>Start typing a location</div>}
                        placeholder="Please select"
                        value={audienceInfo?.location?.map(k => k.name)}
                        options={geoLocations}
                        onSearch={(e) => {
                            setSearchingLocations(e)
                            getGeoLocations({ geoLocation: e } as GetGeoLocationsApiArg).then(response => {
                                setGeoLocations((prev) => {
                                    const newList: any[] = prev;
                                    response?.data?.locations?.map(data => {
                                        const newOb = {
                                            label: `${data.name}/${data.type}`,
                                            value: data.name
                                        }
                                        if (!newList.filter(fl => fl.value === newOb.value).length) {
                                            newList.push(newOb)
                                        }
                                    })
                                    return newList;
                                })
                            })
                        }}
                        onChange={(e) => {
                            const selectedLocations: any[] = []
                            e.forEach(k => geoLocations.forEach(geo => {
                                if (k === geo.value) {
                                    selectedLocations.push({ name: geo.label.split('/')[0], type: geo.label.split('/')[1] })
                                }
                            }))
                            console.log(e, selectedLocations)
                            dispatch(
                                setNewAudienceInfo({
                                    ...audienceInfo,
                                    location: selectedLocations,
                                })
                            );
                        }}
                        mode="multiple"
                        size="middle"
                        className={'w-full'}
                    />
                    {!isExcludeLocations &&
                        <Button
                            className="mt-2"
                            type="text" icon={<DownOutlined />} danger
                            onClick={() => setIsExcludeLocations(prevState => !prevState)}>Exclude Locations
                        </Button>
                    }
                </Form.Item>
                {isExcludeLocations && <Form.Item label={<p className={'text-H7 font-normal text-black'}>Exculaded Locations</p>}
                    tooltip="Choose the geographic regions or specific locations where your target audience is based. This helps in targeting your campaigns to users in those areas."
                    labelCol={{ span: 7 }} wrapperCol={{ span: 17 }} labelAlign={'left'}>
                    <Select
                        disabled={(adsCategorySelection || beneficiarySelection) ?? false}
                        placeholder="Please select"
                        value={audienceInfo?.location_exclude?.map(k => k.name)}
                        options={excludeGeoLocations}
                        notFoundContent={searchingExcludeLocations ? undefined : <div>Start typing a location</div>}
                        onSearch={(e) => {
                            setSearchingExcludeLocations(e)
                            getGeoLocations({ geoLocation: e } as GetGeoLocationsApiArg).then(response => {
                                if (response?.data?.locations?.length) {
                                    setExcludeGeoLocations((prev) => {
                                        const newList: any[] = prev;
                                        response?.data?.locations?.map(data => {
                                            const newOb = {
                                                label: `${data.name}/${data.type}`,
                                                value: data.name
                                            }
                                            if (!newList.filter(fl => fl.value === newOb.value).length) {
                                                newList.push(newOb)
                                            }
                                        })
                                        return newList;
                                    })
                                }
                            })
                        }}
                        onChange={(e) => {
                            const selectedLocations: any[] = []
                            e.forEach(k => excludeGeoLocations.forEach(geo => {
                                if (k === geo.value) {
                                    selectedLocations.push({ name: geo.label.split('/')[0], type: geo.label.split('/')[1] })
                                }
                            }))
                            dispatch(
                                setNewAudienceInfo({
                                    ...audienceInfo,
                                    location_exclude: selectedLocations,
                                })
                            );
                        }}
                        mode="multiple"
                        size="middle"
                        className={'w-full'}
                    />
                </Form.Item>}
                {
                    <div className="w-full">
                        <Form.Item

                            label={<p className={'text-H7 font-normal text-black'}>{t("age") ?? ""}</p>}
                            tooltip="Set the age range for your target demographic. This helps in crafting messages that resonate with the particular life stages and interests of your audience."
                            labelCol={{ span: 7 }} labelAlign={'left'}>
                            <MultiRangeSliderWrapper
                                disabled={(adsCategorySelection || beneficiarySelection) ?? false}
                                minValue={
                                    ageGroups.findIndex(
                                        (element) =>
                                            element === ((adsCategorySelection || beneficiarySelection) ? [18, 65] : (audienceInfo.age_group ?? [18, 65]))[0]
                                    ) !== -1
                                        ? ageGroups.findIndex(
                                            (element) =>
                                                element === ((adsCategorySelection || beneficiarySelection) ? [18, 65] : (audienceInfo.age_group ?? [18, 65]))[0]
                                        )
                                        : 0
                                }
                                maxValue={
                                    ageGroups.findIndex(
                                        (element) =>
                                            element === ((adsCategorySelection || beneficiarySelection) ? [18, 65] : (audienceInfo.age_group ?? [18, 65]))[1]
                                    ) !== -1
                                        ? ageGroups.findIndex(
                                            (element) =>
                                                element === ((adsCategorySelection || beneficiarySelection) ? [18, 65] : (audienceInfo.age_group ?? [18, 65]))[1]
                                        )
                                        : ageGroups.length - 1
                                }
                                labels={ageGroups}
                                onValuesChanged={(values) => {
                                    dispatch(
                                        setNewAudienceInfo({
                                            ...audienceInfo,
                                            age_group: values.map(a => String(a)),
                                        })
                                    );
                                }}
                            />
                        </Form.Item>
                    </div>
                }

                <Form.Item label={<p className={'text-H7 font-normal text-black'}>Gender</p>}
                    tooltip="Select the gender(s) that your campaign should focus on. This information can be used to tailor your messaging and imagery."
                    labelCol={{ span: 7 }} labelAlign={'left'}>
                    <Radio.Group disabled={(adsCategorySelection || beneficiarySelection) ?? false} size={'middle'} className={'flex !bg-white'} onChange={(e) => {
                        dispatch(
                            setNewAudienceInfo({
                                ...audienceInfo,
                                gender: [e.target.value],
                            })
                        );
                    }} value={(adsCategorySelection || beneficiarySelection) ? "all" : audienceInfo.gender?.[0]} style={{ marginBottom: 8 }}>
                        {genderOptions.map(opt => (
                            <Radio.Button className={'w-full text-center'} value={opt.value}>{opt.label}</Radio.Button>
                        ))}
                    </Radio.Group>
                </Form.Item>

                <Form.Item className={'mt-5'} label={<p className={'text-H7 font-normal text-black'}>Language</p>}
                    tooltip="Specify the primary language(s) spoken by your target audience. This ensures that campaign materials are created in the language most likely to be understood and appreciated by your audience."
                    labelCol={{ span: 7 }} wrapperCol={{ span: 17 }} labelAlign={'left'}>
                    <Select
                        placeholder="Please select"
                        value={audienceInfo?.language}
                        options={ContentLanguages?.map(k => ({
                            value: k.key,
                            label: k.title
                        }))}
                        onChange={(e) => {
                            dispatch(
                                setNewAudienceInfo({
                                    ...audienceInfo,
                                    language: e.map((k) => k ?? ""),
                                })
                            );
                        }}
                        mode="multiple"
                        size="middle"
                        className={'w-full'}
                    />
                </Form.Item>
                <div className="flex flex-row items-center ">
                    <button
                        onClick={(e) => {
                            setCollapsed(!isCollapsed);
                        }}
                    >
                        <SvgAudienceArrowUp
                            fill="red"
                            width={18}
                            height={18}
                            className={`mr-3  transition-all transform-gpu ${isCollapsed ? "" : "-rotate-90"
                                }`}
                        />
                    </button>
                    <span className="text-H7 font-medium text-red mt-1 w-72 sm:w-full truncate  text-ellipsis overflow-hidden">
                        {t("advanced-filters")}
                    </span>
                </div>
                <div className={`flex-col w-full transition-all duration-300`}>
                    <>
                        {isCollapsed && (
                            <>
                                <Form.Item className={'mt-5'} label={<p className={'text-H7 font-normal text-black'}>Interests</p>}
                                    tooltip="Interests are determined by users' interactions on platforms. It's usually effective to stack related interests to widen your target audience. For instance, if you're selling makeup, consider also targeting interests in hair and skincare"
                                    labelCol={{ span: 7 }} wrapperCol={{ span: 17 }} labelAlign={'left'}>
                                    <Select
                                        disabled={(adsCategorySelection || beneficiarySelection) ?? false}
                                        placeholder="Please select"
                                        value={(adsCategorySelection || beneficiarySelection) ? undefined : audienceInfo?.interest}
                                        options={Interests?.map(k => ({
                                            value: k.key,
                                            label: k.title
                                        }))}
                                        onChange={(e) => {
                                            dispatch(
                                                setNewAudienceInfo({
                                                    ...audienceInfo,
                                                    interest: e.map((k) => k ?? ""),
                                                })
                                            );
                                        }}
                                        mode="multiple"
                                        size="middle"
                                        className={'w-full'}
                                    />
                                    {!isExcludeInterests && <Button
                                        className="mt-2"
                                        icon={<DownOutlined />} type="text" danger
                                        onClick={() => setIsExcludeInterests(prevState => !prevState)}>Exclude Interests
                                    </Button>}
                                </Form.Item>
                                {isExcludeInterests && <Form.Item className={'mt-5'}
                                    label={<p className={'text-H7 font-normal text-black'}>Exclude
                                        Interests</p>} tooltip="" labelCol={{ span: 7 }}
                                    wrapperCol={{ span: 17 }} labelAlign={'left'}>
                                    <Select
                                        placeholder="Please select"
                                        value={audienceInfo?.interest_exclude}
                                        options={Interests?.map(k => ({
                                            value: k.key,
                                            label: k.title
                                        }))}
                                        onChange={(e) => {
                                            dispatch(
                                                setNewAudienceInfo({
                                                    ...audienceInfo,
                                                    interest_exclude: e.map((k) => k ?? ""),
                                                })
                                            );
                                        }}
                                        mode="multiple"
                                        size="middle"
                                        className={'w-full'}
                                    />
                                </Form.Item>}
                                <Form.Item label={<p className={'text-H7 font-normal text-black'}>Education Level</p>}
                                    tooltip="Using education filters can be useful for offerings that are more relevant to certain levels of educational attainment."
                                    labelCol={{ span: 7 }} wrapperCol={{ span: 17 }} labelAlign={'left'}>
                                    <Select
                                        disabled={(adsCategorySelection || beneficiarySelection) ?? false}
                                        placeholder="Please select"
                                        value={(adsCategorySelection || beneficiarySelection) ? undefined : audienceInfo?.education_level}
                                        options={EducationLevels?.map(k => ({
                                            value: k.key,
                                            label: k.title
                                        }))}
                                        onChange={(e) => {
                                            dispatch(
                                                setNewAudienceInfo({
                                                    ...audienceInfo,
                                                    education_level: e.map((k) => k ?? ""),
                                                })
                                            );
                                        }}
                                        mode="multiple"
                                        size="middle"
                                        className={'w-full'}
                                    />
                                </Form.Item>
                                <Form.Item label={<p className={'text-H7 font-normal text-black'}>Relationship Status</p>}
                                    tooltip="This filter allows you to target people based on whether they're single, in a relationship, married, etc. It's particularly useful for products or services that cater to specific life stages."
                                    labelCol={{ span: 7 }} wrapperCol={{ span: 17 }} labelAlign={'left'}>
                                    <Select
                                        disabled={(adsCategorySelection || beneficiarySelection) ?? false}
                                        placeholder="Please select"
                                        value={(adsCategorySelection || beneficiarySelection) ? undefined : audienceInfo?.family}
                                        options={[
                                            { value: 'single', label: 'Single' },
                                            { value: 'in_relationship', label: 'In Relationship' },
                                            { value: 'married', label: 'Married' },
                                            { value: 'engaged', label: 'Engaged' },
                                            { value: 'not_specified', label: 'Not Specified' },
                                        ]}
                                        onChange={(e) => {
                                            dispatch(
                                                setNewAudienceInfo({
                                                    ...audienceInfo,
                                                    family: e.map((k) => k ?? ""),
                                                })
                                            );
                                        }}
                                        mode="multiple"
                                        size="middle"
                                        className={'w-full'}
                                    />
                                </Form.Item>
                                <Form.Item label={<p className={'text-H7 font-normal text-black'}>Parenting Status</p>}
                                    tooltip="Aim your ads at parents with children of specific ages. This is beneficial for businesses selling children's products or services that parents would find useful​."
                                    labelCol={{ span: 7 }} wrapperCol={{ span: 17 }} labelAlign={'left'}>
                                    <Select
                                        disabled={(adsCategorySelection || beneficiarySelection) ?? false}
                                        placeholder="Please select"
                                        value={(adsCategorySelection || beneficiarySelection) ? undefined : audienceInfo?.parenting_status}
                                        options={[
                                            { value: 'parent', label: 'Parent' },
                                            { value: 'not_a_parent', label: 'Not a Parent' },
                                        ]}
                                        onChange={(e) => {
                                            dispatch(
                                                setNewAudienceInfo({
                                                    ...audienceInfo,
                                                    parenting_status: e.map((k) => k ?? ""),
                                                })
                                            );
                                        }}
                                        mode="multiple"
                                        size="middle"
                                        className={'w-full'}
                                    />
                                </Form.Item>
                            </>
                        )}
                    </>
                </div>

                {(!props.hideMetaAdsSettings && brand !== undefined && audienceInfo !== undefined) &&
                    <div className="flex-col w-full">
                        <MarketingMetaAdsSettings
                            defaultAdsCategory={brand?.meta_ads_settings?.ads_category}
                            defaultBeneficiary={brand?.meta_ads_settings?.beneficiary_payer?.filter(a => a.isDefault).at(0)?.beneficiary}
                            defaultPayer={brand?.meta_ads_settings?.beneficiary_payer?.filter(a => a.isDefault).at(0)?.payer}
                            adsCategory={audienceInfo.ads_category}
                            beneficiary={audienceInfo.beneficiary}
                            payer={audienceInfo.payer}
                            brandName={brand?.brand_name}
                            onAdsCategorySelection={(adsCategoryselected) => { setAdsCategorySelection(adsCategoryselected) }}
                            onBeneficiarySelection={(beneficiarySelected) => { setBeneficiarySelection(beneficiarySelected) }}
                            onBeneficiaryPayerUpdated={(beneficiary, payer) => {
                                dispatch(
                                    setNewAudienceInfo({
                                        ...audienceInfo,
                                        beneficiary: beneficiary,
                                        payer: payer
                                    })
                                );
                            }}
                            onAdsCategoryUpdated={(adsCategory) => {
                                dispatch(
                                    setNewAudienceInfo({
                                        ...audienceInfo,
                                        ads_category: adsCategory,
                                    })
                                );
                            }}
                            onBeneficiaryUpdated={(beneficiary) => {
                                dispatch(
                                    setNewAudienceInfo({
                                        ...audienceInfo,
                                        beneficiary: beneficiary,
                                    })
                                );
                            }}
                            onPayerUpdated={(payer) => {

                                console.log(`setting new payer ${payer}`)
                                dispatch(
                                    setNewAudienceInfo({
                                        ...audienceInfo,
                                        payer: payer,
                                    })
                                );
                            }} />

                        {/* <MarketingMetaAdsSettings></MarketingMetaAdsSettings> */}
                    </div>
                }
            </div>
            {
                !props.hideButtons && (
                    <div className={` h-[70px] ${!isCollapsed ? "mt-7" : "mt-12"} py-2 w-full border-t-1 border-border flex items-center justify-end pr-5`}>
                        <PrimaryButton
                            noFill
                            className="mr-2"
                            title={t("cancel")}
                            onClick={() => {
                                props.onClose();
                            }}
                        />
                        <PrimaryButton
                            title={t("save-audience")}
                            onClick={() => {
                                if ((adsCategorySelection || beneficiarySelection) && (audienceInfo.ads_category === undefined || audienceInfo.ads_category.length === 0)) {
                                    console.log(`please select ads category`)
                                    return;
                                }

                                if (beneficiarySelection && (audienceInfo.beneficiary === undefined || audienceInfo.beneficiary.length === 0 || audienceInfo.payer === undefined || audienceInfo.payer.length === 0)) {
                                    console.log(`please select beneficiary payer`)
                                    return;
                                }
                                addAudience({
                                    addAudienceRequest: {
                                        ...audienceInfo,
                                        name:
                                            audienceInfo.name !== undefined &&
                                                audienceInfo.name?.length > 0
                                                ? audienceInfo.name
                                                : "Audience",
                                        brand_id: brandInfo.id,
                                        audience_id: audienceInfo?.audience_id,
                                        occupation: audienceInfo?.occupation,
                                        location: audienceInfo.location,
                                        income_level: audienceInfo.income_level,
                                        age_group: audienceInfo.age_group,
                                        language: audienceInfo.language,
                                        education_level: audienceInfo.education_level,
                                        family: audienceInfo.family,
                                        type_of_area: audienceInfo.type_of_area,
                                        interest: audienceInfo.interest,
                                        loyalty: audienceInfo.loyalty,
                                        gender: audienceInfo.gender,
                                        ads_category: audienceInfo.ads_category,
                                        beneficiary: audienceInfo.beneficiary,
                                        payer: audienceInfo.payer
                                    },
                                });
                            }}
                        />
                    </div>
                )
            }
        </div >
    );
};

export default BrandHubCreateNewAudience;
