import * as React from "react";
import type { SVGProps } from "react";
const SvgBrandHubProductPhotosIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect opacity="0.1" width="28" height="28" rx="4" fill="#FF5555" />
    <path d="M14.0007 17C13.2934 17 12.6151 16.719 12.115 16.2189C11.6149 15.7188 11.334 15.0405 11.334 14.3333C11.334 13.626 11.6149 12.9478 12.115 12.4477C12.6151 11.9476 13.2934 11.6666 14.0007 11.6666C14.7079 11.6666 15.3862 11.9476 15.8863 12.4477C16.3864 12.9478 16.6673 13.626 16.6673 14.3333C16.6673 15.0405 16.3864 15.7188 15.8863 16.2189C15.3862 16.719 14.7079 17 14.0007 17Z" fill="#FF5555" />
    <path opacity="0.5" d="M18.6673 10.3334H17.814L17.604 9.70004C17.4714 9.30182 17.2167 8.95548 16.8761 8.71017C16.5356 8.46485 16.1264 8.33302 15.7067 8.33337H12.294C11.8743 8.33302 11.4651 8.46485 11.1245 8.71017C10.7839 8.95548 10.5293 9.30182 10.3967 9.70004L10.186 10.3334H9.33398C8.80371 10.3339 8.29531 10.5448 7.92036 10.9197C7.5454 11.2947 7.33451 11.8031 7.33398 12.3334V17.6667C7.33451 18.197 7.5454 18.7054 7.92036 19.0803C8.29531 19.4553 8.80371 19.6662 9.33398 19.6667H18.6673C19.1976 19.6662 19.706 19.4553 20.0809 19.0803C20.4559 18.7054 20.6668 18.197 20.6673 17.6667V12.3334C20.6668 11.8031 20.4559 11.2947 20.0809 10.9197C19.706 10.5448 19.1976 10.3339 18.6673 10.3334ZM14.0007 17C13.2934 17 12.6151 16.7191 12.115 16.219C11.6149 15.7189 11.334 15.0406 11.334 14.3334C11.334 13.6261 11.6149 12.9479 12.115 12.4478C12.6151 11.9477 13.2934 11.6667 14.0007 11.6667C14.7079 11.6667 15.3862 11.9477 15.8863 12.4478C16.3864 12.9479 16.6673 13.6261 16.6673 14.3334C16.6673 15.0406 16.3864 15.7189 15.8863 16.219C15.3862 16.7191 14.7079 17 14.0007 17Z" fill="#FF5555" />
  </svg>


);
export default SvgBrandHubProductPhotosIcon;
