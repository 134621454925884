<script>
	import { getContext, createEventDispatcher } from "svelte";
	import { Popup } from "@wx/svelte-core";
	import { correctPopupPosition, locateID } from "@xbs/lib-dom";

	export let pos = {};
	export let event = {};
	export let readonly = false;
	export let calendars = [];
	const templates = getContext("schedulerTemplates");
	const dispatch = createEventDispatcher();
	let popup;
	$: if (popup && pos) {
		pos = {
			...correctPopupPosition(pos, {
				width: popup.offsetWidth,
				height: popup.offsetHeight,
			}),
		};
	}
	$: calendar = calendars.find(type => type.id === event.type);
	function closeEventInfo(e) {
		const id = locateID(e);
		if (id == event.id) return;
		dispatch("action", {
			action: "close-event-info",
			data: {},
		});
	}
	function proxyAction(ev) {
		const { action, data } = ev.detail;
		const { event } = data;
		if (action === "delete-event" && event.recurring) {
			return dispatch("recurringAction", ev.detail);
		}
		dispatch("action", ev.detail);
	}

</script>

<Popup area={pos} cancel={closeEventInfo}>
	<div bind:this={popup}>
		<svelte:component
			this={templates.popup}
			{readonly}
			{event}
			{calendar}
			on:recurringAction
			on:action={proxyAction} />
	</div>
</Popup>
