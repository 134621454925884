import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { reformatText } from "../../../helpers/Utils";
import SvgGoogleAdsColor from "../../../assets/IconComponents/GoogleAdsColor";

interface ActivityCardProps {
    event: any;
    getSocialIcon: (event: any) => string;
    getColorByStatus: (status: string) => {
        background: string;
        border: string;
        textColor: string;
    } | undefined;
}

const ActivityCard: FC<ActivityCardProps> = ({ event, getSocialIcon, getColorByStatus }) => {
    const navigate = useNavigate();
    console.log("event", event);

    return (
        <div className="sm:w-full md:w-1/3 lg:w-1/4 pr-2 pb-2">
            <div
                onClick={() => navigate(`/app/content-calendar?selectedCampaign=${event.rootId}&redirectToActivity=${event.id}`)}
                className="hover:bg-lightBg border border-border rounded-lg h-52 cursor-pointer align-middle items-center p-2"
            >
                <div className="py-2.5 flex items-center">
                    <div className="flex flex-row items-center">
                        <div style={{ backgroundColor: "rgba(38, 112, 169, 0.1)" }} className="relative h-9 w-9 rounded-full"
                        >
                            {event.channels[0] === "GOOGLE_ADS" && (
                                <>
                                    <SvgGoogleAdsColor className="bottom-0.5 left-0.5 absolute rounded-full h-8 w-8 " />
                                </>
                            )}
                            {event.channels[0] !== "GOOGLE_ADS" && event?.publisherAccountProfileImageUrl && event?.publisherAccountProfileImageUrl !== "" &&
                                <img src={event?.publisherAccountProfileImageUrl ?? ''}
                                    alt="Publisher Account Profile"
                                    className="rounded-full h-full w-full object-cover" />
                            }

                            <div className="-right-2 top-4 absolute w-6 h-6 flex items-center">
                                <div className="border-white bg-white border-2 p-0.5 rounded-full"
                                    dangerouslySetInnerHTML={{ __html: getSocialIcon(event).replace(/width="[^"]*"/, 'width="20px"').replace(/height="[^"]*"/, 'height="20px"') }}>
                                </div>
                            </div>
                        </div>
                    </div>
                    <span className="text-BodyMedium font-medium text-black ml-3 mr-2 truncate">{event?.publisherAccountName}</span>
                    <div className="flex justify-center items-center ml-auto">
                        <div style={{ backgroundColor: `${getColorByStatus(event?.status ?? '')?.background ?? ""}` }} className={`h-[10px] w-[10px] rounded-sm mr-1`}></div>
                        <span className="text-footnote text-BodySmall truncate">{reformatText(event?.status)}</span>
                    </div>
                </div>
                <div className="border border-border text-BodyMedium rounded p-2 text-black h-32 overflow-y-scroll scrollbar-hide flex !justify-between">
                    {event.textContentDetails && event.textContentDetails.length > 0 &&
                        <div className={`${event.imageContentDetails!.length > 0 ? 'w-4/5' : ''}`}>
                            {event.textContentDetails.filter(a => a.active)[0]?.textContent}
                        </div>
                    }

                    {event.imageContentDetails && event.imageContentDetails.length > 0 &&
                        <div className={`rounded-sm ${event.textContentDetails!.length > 0 ? 'w-1/5' : 'w-2/5'}`}>
                            <img className="w-24" src={`${process.env.REACT_APP_BASE_URL}file/download/image/${event.imageContentDetails.filter(a => a.active)[0]?.imageFileId}`} alt={event.imageContentDetails[0].textContent} />
                        </div>
                    }
                </div>
            </div>
        </div>
    );
};

export default ActivityCard;
