import { t } from "i18next";
import { useAppDispatch, useAppSelector } from "../../helpers/hooks";
import {
  setActiveItem,
  setActivePage,
  setDialog,
  setMenuCollapsed,
  toggleDialog,
  toggleLoading,
  toggleBrandEditing,
  setCollapsedModeEnabled,
  toggleContentWizard,
  toggleNotificationsSidebar
} from "../../store/appSlice";
import {
  setAccessToken,
  setLastSelectedBrandId,
  setRefreshToken,
  toggleLoggedIn,
} from "../../store/persistedSlice";
import PrimaryButton from "../PrimaryButton";
import LeftMenuButton, { ButtonState } from "./LeftMenuButton";

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ShimmerTitle } from "react-shimmer-effects";
import {
  Add,
  LogoLight,
  Logout,
  Loops,
} from "../../assets/IconComponents";
import { Mediums } from "../../constants/Mediums";
import { useLazyLogoutQuery } from "../../services/AuthenticationApi.old/AuthenticationApi";
import { updateMenu } from "../../store/appSlice";
import { setNewContent } from "../../store/contentSlice";
import SvgInspirations from "../../assets/IconComponents/Inspirations";
import SvgLogoIcon from "../../assets/IconComponents/LogoIcon";
import { Id, useLazyList5Query } from "../../services/ContentApi";
import { useWindowSize } from "@uidotdev/usehooks";
import SvgCollapse from "../../assets/IconComponents/SvgCollapse";
import SvgPlusTritoneIcon from "../../assets/IconComponents/PlusTritoneIcon";
import SvgContentMenuCollapsedIcon from "../../assets/IconComponents/ContentMenuCollapsedIcon";
import SvgInspireMenuCollapsedIcon from "../../assets/IconComponents/InspireMenuCollapsedIcon";
import SvgCollapseReverse from "../../assets/IconComponents/SvgCollapseReverseIcon";
import SvgSettings from "../../assets/IconComponents/Settings";
import SvgSettingsCollapsedIcon from "../../assets/IconComponents/SettingsCollapsedIcon";

import SvgMarketPlannerCollapsedIcon from "../../assets/IconComponents/MarketPlannerCollapsedIcon";

import SvgAIContentManagerCollapsedIcon from "../../assets/IconComponents/AIContentManagerCollapsedIcon";
import SvgMarketPlannerIcon from "../../assets/IconComponents/MarketPlannerIcon";
import SvgAIContentManagerIcon from "../../assets/IconComponents/AIContentManagerIcon";
import SvgLogoutCollapsedIcon from "../../assets/IconComponents/LogoutCollapsed";
import MenuBrandSelector from "./MenuBrandSelector";
import { INewContent } from "../../models/NewContent";
import { ContentType } from "../../constants/ContentType";
import { ContentWizardView } from "../../models/ContentWizardView";
import moment from "moment";
import { Popover } from "antd";
import SvgSidebarNotificationsIcon from "../../assets/IconComponents/SidebarNotificationsIcon";
import SvgSidebarNotificationsCollapsedIcon from "../../assets/IconComponents/SidebarNotificationsCollapsedIcon";
import { NotificationResponse, useLazyGetNotificationsQuery } from "../../services/NotificationsManager";
import MenuNotificationComponent from "../../pages/Notifications/MenuNotificationComponent";
import AIMarketingToolsSubMenu from "./AIMarketingToolsSubMenu";

function LeftMenuAlternative() {
  const [
    logoutUser,
    {
      isLoading: isLogoutLoading,
      isSuccess: isLogoutSuccess,
      isError: isLogoutError,
    },
  ] = useLazyLogoutQuery();
  const updateMenuEvent = useAppSelector((state) => state.app.updateMenu);
  const collapsedModeEnabled = useAppSelector(
    (state) => state.app.collapsedModeEnabled
  );
  const isBrandEditing = useAppSelector((state) => state.app.brandEditing);
  const activePage = useAppSelector((state) => state.app.activePage);
  const isNotificationSidebarActive = useAppSelector((state) => state.app.notificationsSidebarActive);
  const activeItem = useAppSelector((state) => state.app.activeItem);
  const [getContentList, { isLoading: contenListLoading, data: contentList }] = useLazyList5Query();
  const dispatch = useAppDispatch();
  const lastSelectedBrandId = useAppSelector(
    (k) => k.persisted.lastSelectedBrandId
  );
  const [
    getNotifications,
    { data: notificationModels },
  ] = useLazyGetNotificationsQuery();
  const navigate = useNavigate();
  const isLoggedIn = useAppSelector((state) => state.persisted.isLoggedIn);
  const showMenu = useAppSelector((state) => state.app.showMenu);
  const isExpanded = useAppSelector((state) => state.app.isMenuCollapsed);
  const [logoutClosed, setLogoutClosed] = useState(true);
  const size = useWindowSize();
  const [isBrandPopoverOpen, setIsBrandPopoverOpen] = useState<boolean>(false);
  const [localActiveItem, setLocalActiveItem] = useState("");
  const newContent = useAppSelector((k) => k.content.newContent);
  const [unfilteredNotificationModels, setUnfilteredNotificationModels] =
    useState<NotificationResponse[]>([]);


  let container: HTMLDivElement;


  const prepareMenu = () => {
    getContentList({
      page: 0,
      size: 999,
    });
  };
  useEffect(() => {
    if (isLoggedIn) prepareMenu();
    dispatch(setCollapsedModeEnabled(true));
    // Setup interval to fetch data every 5 seconds
    const intervalId = setInterval(() => {
      if (lastSelectedBrandId) {
        getNotifications({
          size: 200,
          page: 0,
          brandId: [lastSelectedBrandId],
        });
      }
    }, 5000);

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
  }, [isLoggedIn]);

  useEffect(() => {
    if (notificationModels) {
      setUnfilteredNotificationModels(notificationModels);
    }
  }, [notificationModels]);


  useEffect(() => {
    if (activeItem) {
      console.log("Active item:", activeItem);
      setLocalActiveItem(activeItem ?? "");
    }
  }, [activeItem]);

  useEffect(() => {
    prepareMenu();
  }, [updateMenuEvent]);
  useEffect(() => {
    dispatch(updateMenu());
  }, [contentList]);

  const showLoop = (id?: Id) => {
    navigate(`loopdetails/${id?.content_id}`);

    // navigate(`revisionhisory`, { state: { content_id: id.content_id } })
  };


  const cleanUp = () => {
    dispatch(setLastSelectedBrandId(undefined));
    dispatch(setNewContent(undefined));
  };

  const logout = () => {
    dispatch(
      setDialog({
        actions: (
          <>
            <PrimaryButton
              noFill
              title={t("cancel")}
              onClick={() => {
                dispatch(toggleDialog(false));
                setLogoutClosed(true);
              }}
            />
            <PrimaryButton
              className="ml-2"
              title={t("logOut")}
              onClick={() => {
                cleanUp();
                dispatch(toggleDialog(false));

                dispatch(setActivePage(""));
                dispatch(setActiveItem(""));
                setLogoutClosed(true);
                logoutUser();
              }}
            />
          </>
        ),
        title: t("log-out"),
        message: t(
          "are-you-sure-you-would-like-to-log-out-of-your-evercopy-account"
        ),
        onClose: () => {
          dispatch(toggleDialog(false));
        },
      })
    );
    dispatch(toggleDialog(true));
  };

  // useEffect(() => {
  //     clickOutside(container, () => {
  //
  //     })
  // }, [])

  useEffect(() => {
    if (isLogoutSuccess || isLogoutError) {
      dispatch(setAccessToken(""));
      dispatch(setRefreshToken(""));
      dispatch(toggleLoggedIn(false));
    }
  }, [isLogoutSuccess, isLogoutError]);

  useEffect(() => {
    dispatch(toggleLoading(isLogoutLoading));
  }, [isLogoutLoading]);

  const getMediaIcon = (mediaName: string) => {
    const platformName = mediaName.split(" | ")[0];
    const icon = Mediums.find((k) => k.name === platformName)?.icon;
    return icon;
  };

  const isMobile = () => {
    return size.width && size.width <= 500;
  };
  const exitWizard = (loopId: Id | undefined, clickType: number) => {
    dispatch(
      setDialog({
        message:
          t(
            "are-you-sure-you-want-to-leave-this-page-unsaved-changes-will-be-lost"
          ) ?? "",
        title: t("unsaved-changes"),
        actions: (
          <>
            <PrimaryButton
              size="middle"
              noFill
              title={t("cancel")}
              onClick={() => {
                dispatch(toggleDialog(false));
                dispatch(setActivePage("brandhub"));
              }}
            />
            <PrimaryButton
              size="middle"
              className="ml-2"
              title={t("yes-leave")}
              onClick={() => {
                dispatch(toggleDialog(false));
                dispatch(toggleBrandEditing(false));
                if (clickType === 0) {
                  dispatch(setActivePage("homepage"));
                  dispatch(setActiveItem(loopId?.content_id));
                  ;
                  dispatch(toggleDialog(false));
                  showLoop(loopId);
                } else if (clickType === 1) {
                  ;
                  navigate(`loops`);
                } else if (clickType === 2) {
                  dispatch(setActivePage("inspirations"));
                  dispatch(setActiveItem(""));
                  ;
                  navigate(`inspirations`);
                } else if (clickType === 3) {
                  dispatch(setActivePage("brandhub"));
                  dispatch(setActiveItem(""));
                  ;
                  navigate(`brandhub`);
                } else if (clickType === 4) {
                  setLogoutClosed(false);
                  ;
                  logout();
                }
              }}
            />
          </>
        ),
        onClose: () => {
          dispatch(toggleDialog(false));
        },
      })
    );

    dispatch(toggleDialog(true));
  };

  //mobile open/close
  //loop detail auto open/close
  //normal show

  return (
    <>
      {showMenu && (
        <div
          ref={(r) => (container = r!)}
          className={`lg:flex bg-black  w-full h-full relative
            ${!(collapsedModeEnabled && !isExpanded && !isMobile())
              ? "lg:w-[220px]"
              : "lg:w-[50px]"
            }
            transition-all
            ${collapsedModeEnabled && !isMobile() ? "absolute" : ""}

             lg:h-screen  lg:max-h-screen overflow-hidden flex-col  z-[1000]`}
        >
          <div className="w-full flex items-center h-[56px] min-h-[56px] max-h-[56px]">
            {!(collapsedModeEnabled && !isExpanded && !isMobile()) && (
              <div className="flex flex-row w-full items-center">
                <LogoLight
                  width={150}
                  height={24}
                  preserveAspectRatio="xMinYMin meet"
                  viewBox="0 0 150 75"
                  className="ml-[18px]"
                />
                <MenuNotificationComponent
                  onClick={() => {
                    dispatch(toggleNotificationsSidebar());
                  }}
                  collapsed={false}
                  isActive={isNotificationSidebarActive ?? false}
                  unreadsExist={unfilteredNotificationModels.some(
                    (model) => !(model.isRead ?? true)
                  )}
                />
              </div>
            )}
            {collapsedModeEnabled && !isExpanded && (
              <SvgLogoIcon className="ml-[18px]" />
            )}
          </div>

          {collapsedModeEnabled && !isExpanded && (
            <MenuNotificationComponent
              onClick={() => {
                dispatch(toggleNotificationsSidebar());
              }}
              collapsed={true}
              isActive={isNotificationSidebarActive ?? false}
              unreadsExist={unfilteredNotificationModels.some(
                (model) => !(model.isRead ?? true)
              )}
            />
          )}

          <hr className="mb-3  -mr-3" style={{ borderColor: "#1F2022" }} />
          {!collapsedModeEnabled && (
            <>
              <div className="flex  mx-[10px] h-[40px]">
                <PrimaryButton
                  icon={<Add fill="#ffffff" className="" />}
                  className="w-full shadow-none"
                  title={t("create-a-content")}
                  onClick={() => {
                    if (logoutClosed) {
                      dispatch(setActiveItem(undefined));
                      dispatch(setNewContent(undefined));
                      navigate(`homepage`);
                    }
                  }}
                />
              </div>
              <div className="overflow-scroll scrollbar-hide flex flex-col max-h-[300px] lg:max-h-full">
                {contenListLoading && (
                  <ShimmerTitle
                    line={5}
                    gap={10}
                    variant="secondary"
                    className="w-36 ml-5 mt-5"
                  />
                )}
              </div>
            </>
          )}

          {collapsedModeEnabled && !isExpanded && !isMobile() && (
            <>
              <div
                onClick={() => {
                  dispatch(setActiveItem(undefined));
                  dispatch(setNewContent(undefined));
                  navigate(`homepage`);
                }}
                className="flex items-center justify-center h-[40px]"
              >
                <SvgPlusTritoneIcon />
              </div>
              <div className="overflow-scroll scrollbar-hide flex flex-col max-h-[300px] lg:max-h-full">
                {contenListLoading && (
                  <ShimmerTitle
                    line={5}
                    gap={10}
                    variant="secondary"
                    className="w-36 ml-5 mt-5"
                  />
                )}
              </div>
            </>
          )}

          {(!contenListLoading && contentList?.length == 0) ||
            (collapsedModeEnabled && !isExpanded && isMobile())}
          {(!isMobile() ||
            (collapsedModeEnabled && isExpanded && isMobile()) ||
            showMenu) && (
              <div className="flex flex-col h-full p-2 pt-0 pl-0 pb-[10px] max-lg:fixed max-lg:bottom-0 justify-between mt-3">
                <div className="flex flex-col">
                  <LeftMenuButton
                    state={
                      activePage === "loops"
                        ? ButtonState.focus
                        : ButtonState.normal
                    }
                    icon={Loops}
                    deactiveColor="#999999"
                    tooltip={t("contents") ?? ""}
                    onClick={() => {
                      if (isBrandEditing) {
                        exitWizard(undefined, 1);
                      } else if (!logoutClosed) {
                      } else {
                        navigate(`loops`);
                      }
                    }}
                    title={t("contents")}
                    collapsedModeIcon={SvgContentMenuCollapsedIcon}
                    collapsedMode={
                      collapsedModeEnabled && !isExpanded && !isMobile()
                    }
                    className="w-full"
                  />
                  {/*<LeftMenuButton
                    state={
                      activePage === "inspirations"
                        ? ButtonState.focus
                        : ButtonState.normal
                    }
                    tooltip={t("inspirations") ?? ""}
                    icon={SvgInspirations}
                    deactiveColor="#999999"
                    onClick={() => {
                      if (isBrandEditing) {
                        exitWizard(undefined, 2);
                      } else if (!logoutClosed) {
                      } else {
                        dispatch(setActivePage("inspirations"));
                        dispatch(setActiveItem(""));
                        navigate(`inspirations`);
                      }
                    }}
                    collapsedModeIcon={SvgInspireMenuCollapsedIcon}
                    collapsedMode={
                      collapsedModeEnabled && !isExpanded && !isMobile()
                    }
                    title={t("content-inspirations")}
                    className="w-full"
                  />*/}

                  {/*
              <LeftMenuButton
              state={logoutClosed ? ButtonState.normal : ButtonState.focus}
              icon={Logout}
              deactiveColor="#999999"
              onClick={() => {
                if (isBrandEditing) {
                  exitWizard(undefined, 4);
                } else {
                  setLogoutClosed(false);
                  ;
                  logout();
                }
              }}
              title={t("logOut")}
              className="w-full"
            />
              */}

                  <LeftMenuButton
                    state={
                      activePage === "marketing-planner"
                        ? ButtonState.focus
                        : ButtonState.normal
                    }
                    icon={SvgMarketPlannerIcon}
                    deactiveColor="#999999"
                    onClick={() => {
                      if (logoutClosed) {
                        dispatch(setActivePage("marketing-planner"));
                        dispatch(setActiveItem(""));
                        navigate(`marketing-planner`);
                      }
                    }}
                    collapsedModeIcon={SvgMarketPlannerCollapsedIcon}
                    collapsedMode={
                      collapsedModeEnabled && !isExpanded && !isMobile()
                    }
                    title={t("marketing-planner")}
                    className="w-full"
                  />

                  <LeftMenuButton
                    state={
                      activePage === "content-calendar"
                        ? ButtonState.focus
                        : ButtonState.normal
                    }
                    icon={SvgAIContentManagerIcon}
                    deactiveColor="#999999"
                    onClick={() => {
                      if (logoutClosed) {
                        dispatch(setActivePage("content-calendar"));
                        dispatch(setActiveItem(""));
                        navigate(`content-calendar`);
                      }
                    }}
                    collapsedModeIcon={SvgAIContentManagerCollapsedIcon}
                    collapsedMode={
                      collapsedModeEnabled && !isExpanded && !isMobile()
                    }
                    title={t("content-calendar")}
                    className="w-full"
                  />

                  <AIMarketingToolsSubMenu
                    onSubItemClick={(type) => {
                      if (type === "tts") {
                        console.log("clicked");
                        dispatch(setActivePage("text-to-speech"));
                        dispatch(setActiveItem(""));
                        navigate(`text-to-speech`);
                      } else if (type === "background-remover") {
                        dispatch(setActivePage("background-remover"));
                        dispatch(setActiveItem(""));
                        navigate(`background-remover`);
                      }
                    }}
                    deactiveColor="#999999"
                    onUncollapse={() => {
                      if (logoutClosed) {
                        dispatch(setCollapsedModeEnabled(false));
                        if (!collapsedModeEnabled && isExpanded) {
                          dispatch(setMenuCollapsed(!isExpanded));
                        }
                      }
                    }}
                    collapsedMode={
                      collapsedModeEnabled && !isExpanded && !isMobile()
                    }
                  />
                </div>
                <div className="flex flex-col">
                  <LeftMenuButton
                    state={
                      activePage === "settings"
                        ? ButtonState.focus
                        : ButtonState.normal
                    }
                    icon={SvgSettings}
                    deactiveColor="#999999"
                    onClick={() => {
                      if (isBrandEditing) {
                        exitWizard(undefined, 3);
                      } else if (!logoutClosed) {
                      } else {
                        dispatch(setActivePage("settings"));
                        dispatch(setActiveItem(""));
                        navigate(`settings`);
                      }
                    }}
                    collapsedModeIcon={SvgSettingsCollapsedIcon}
                    collapsedMode={
                      collapsedModeEnabled && !isExpanded && !isMobile()
                    }
                    title={t("settings-socials")}
                    className="w-full"
                  />

                  <LeftMenuButton
                    state={logoutClosed ? ButtonState.normal : ButtonState.focus}
                    icon={Logout}
                    deactiveColor="#999999"
                    collapsedModeIcon={SvgLogoutCollapsedIcon}
                    collapsedMode={
                      collapsedModeEnabled && !isExpanded && !isMobile()
                    }
                    onClick={() => {
                      if (isBrandEditing) {
                        exitWizard(undefined, 4);
                      } else {
                        setLogoutClosed(false);
                        logout();
                      }
                    }}
                    title={t("logOut")}
                    className="w-full"
                  />
                  {!isMobile() && (
                    <LeftMenuButton
                      state={ButtonState.normal}
                      icon={SvgCollapse}
                      deactiveColor="#999999"
                      onClick={() => {
                        if (logoutClosed) {
                          dispatch(
                            setCollapsedModeEnabled(!collapsedModeEnabled)
                          );
                          if (!collapsedModeEnabled && isExpanded) {
                            dispatch(setMenuCollapsed(!isExpanded));
                          }
                        }
                      }}
                      collapsedModeIcon={SvgCollapseReverse}
                      collapsedMode={
                        collapsedModeEnabled && !isExpanded && !isMobile()
                      }
                      title={t("collapsed-view")}
                      className="w-full transition-all"
                    />
                  )}

                  <hr className="mb-1 -mr-3" style={{ borderColor: "#1F2022" }} />
                  <div className="max-lg:hidden lg:flex flex-row justify-center mt-5 sm:mt-0 items-center">
                    <MenuBrandSelector
                      selectedBrandId={lastSelectedBrandId}
                      logoutClosed={logoutClosed}
                      isMenuCollapsed={collapsedModeEnabled}
                      BrandSelected={(e) => {
                        dispatch(setLastSelectedBrandId(e?.id));
                        setIsBrandPopoverOpen(false);
                      }}
                      tooltip={""}
                      isPopoverOpen={isBrandPopoverOpen}
                      setIsBrandPopoverOpen={(isOpen) => {
                        setIsBrandPopoverOpen(isOpen);
                      }}
                    />
                  </div>
                  {/* <LeftMenuButton
                  state={
                    activePage === "brandhub"
                      ? ButtonState.focus
                      : ButtonState.normal
                  }
                  icon={Brand}
                  deactiveColor="#999999"
                  collapsedModeIcon={SvgBrandhubCollapsedIcon}
                  collapsedMode={
                    collapsedModeEnabled && !isExpanded && !isMobile()
                  }
                  onClick={() => {
                    if (isBrandEditing) {
                      exitWizard(undefined, 3);
                    } else if (!logoutClosed) {
                    } else {
                      dispatch(setActivePage("brandhub"));
                      dispatch(setActiveItem(""));
                      navigate(`brandhub`);
                    }
                  }}
                  title={t("brand-hub")}
                  className="w-full"
                />
                */}
                </div>
              </div>
            )}
        </div>
      )}
      {!showMenu && (
        <div
          ref={(r) => (container = r!)}
          className={`lg:flex bg-black  w-full h-full relative
           lg:w-[50px]
             lg:h-screen  lg:max-h-screen overflow-hidden flex-col  z-[1000]`}
        >
          <div className="w-full flex items-center h-[56px] min-h-[56px] max-h-[56px]">
            <SvgLogoIcon className="ml-[18px]" />
          </div>
          <hr className="mb-1 -mr-3" style={{ borderColor: "#1F2022" }} />
        </div>
      )}
    </>
  );
}

export default LeftMenuAlternative;
