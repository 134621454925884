import { useEffect, useState } from "react";
import Modal from 'react-modal';
import SvgPlusTwo from "../../../assets/IconComponents/PlusTwo";
import SvgCloseLittle from "../../../assets/IconComponents/CloseLittle";
import InputText from "../../../components/InputText";
import { TechnicalDocumentTypes } from "../../../constants/TechnicalDocumentTypes";
import UploadCompanyMaterialNew from "../../../components/UploadFile/UploadCompanyMaterialNew";
import { useAppDispatch, useAppSelector } from "../../../helpers/hooks";
import { setNewBrandInfo } from "../../../store/brandSlice";
import SvgDelete from "../../../assets/IconComponents/Delete";
import SvgDownload from "../../../assets/IconComponents/Download";
import { useLazyDownloadQuery, useLazySummarizeQuery } from "../../../services/FileApi";
import { blobToDataURL } from "blob-util";
import { toggleLoading } from "../../../store/appSlice";
import { t } from "i18next"
import DontHaveAnyComponent from "./DontHaveAny";
import PrimaryButton from "../../../components/PrimaryButton";
import { Button, Input, Select, Table, Tooltip } from "antd";
import { PlusOutlined } from "@ant-design/icons";

const BrandHubCustomTraining = () => {
    const dispatch = useAppDispatch();
    const brandInfo = useAppSelector((k) => k.brand.newBrand);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [documentName, setDocumentName] = useState("")
    const [documentType, setDocumentType] = useState("")
    const [documentNameError, setDocumentNameError] = useState("")
    const [documentTypeError, setDocumentTypeError] = useState(false);
    const [file, setFile] = useState<any>()

    const handleAddTraining = () => {
        const date = new Date();
        const time = date.getTime();
        const isValid = handleValidation();
        if (isValid) {
            dispatch(
                setNewBrandInfo({
                    ...brandInfo,
                    technicalLinks: [...brandInfo.technicalLinks ?? [], { id: file.uploadedId, name: file.name, file_type: documentType, uploaded: true, file_name: documentName, create_date: time, summary: file.summary }]
                })
            );
            setDocumentName("");
            setDocumentType("");
            setFile(undefined);
            setIsModalOpen(false);
        }
    }

    useEffect(() => {
        if (file) {
            if (!documentName)
                setDocumentName(file.name)
        }
    }, [file])

    const [
        downloadFile,
        {
            data: fileDownloaddData,
            isSuccess: fileDownloaded,
            isLoading: fileDownloading,
            currentData: dt,
        },
    ] = useLazyDownloadQuery();



    const handleItemRemoval = (id) => {
        dispatch(
            setNewBrandInfo({
                ...brandInfo,
                technicalLinks: brandInfo.technicalLinks?.filter(obj => obj.id !== id),
            })
        )
    }

    const downloadFileToDesktop = (uploadId: any) => {
        downloadFile({
            id: uploadId,
        })
            .unwrap()
            .then(async (k) => {
                const dataUrl = await blobToDataURL(k.file);

                var link = document.createElement("a");
                link.download = k.name;
                link.href = dataUrl;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            });
    };

    const handleValidation = () => {
        !documentName ? setDocumentNameError("Document Name can't be empty.") : setDocumentNameError("");
        !documentType ? setDocumentTypeError(true) : setDocumentTypeError(false)
        if (!file || !documentType || !documentName) {
            return false;
        }
        else {
            return true;
        }
    }

    return (
        <>
            {(!brandInfo.technicalLinks || !brandInfo.technicalLinks.length) && (
                <DontHaveAnyComponent
                    title={"You don’t have any custom training yet"}
                    description={"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."}
                    handleClick={() => {
                        setIsModalOpen(true)
                    }} />
            )}

            {brandInfo.technicalLinks && brandInfo.technicalLinks.length > 0 && (
                <div className="flex flex-col mt-9 gap-1">
                    <div className="flex flex-col w-full  bg-white border-1 rounded-lg">
                        <div className="flex flex-row justify-between items-center px-4 mt-4">
                            <span className="text-H5 font-medium text-black ">Custom Trainings</span>
                            <Button className="" type="primary" icon={<PlusOutlined />}
                                onClick={() => {
                                    setIsModalOpen(true)
                                }}>
                                Add New
                            </Button>
                        </div>
                        <Table
                            size="small"
                            className="w-full px-4 py-3"
                            rowKey="id"
                            dataSource={brandInfo.technicalLinks}
                            columns={[
                                {
                                    title: 'Name',
                                    dataIndex: 'file_name',
                                    key: 'file_name',
                                },

                                {
                                    title: 'Type',
                                    dataIndex: 'type',
                                    key: 'type',
                                    render: (text, record) => (
                                        <span>
                                            {record.file_type ? TechnicalDocumentTypes.find((k) => k.value === record.file_type)?.label : ""}
                                        </span>
                                    ),
                                },
                                {
                                    title: '',
                                    dataIndex: 'action',
                                    render: (text, record) => (
                                        <div className="flex items-center gap-[10px] self-stretch cursor-pointer" style={{ justifyContent: "end" }}>
                                            <Tooltip title={t("download")}>
                                                <SvgDownload fill="#8E8E8E" onClick={() => downloadFileToDesktop(record.id)} />
                                            </Tooltip>
                                            <Tooltip title={t("delete")}>
                                                <SvgDelete fill="#8E8E8E" onClick={() => handleItemRemoval(record.id)} />
                                            </Tooltip>
                                        </div>
                                    ),
                                },
                            ]}
                            pagination={false} />
                    </div>
                </div>
            )}

            <Modal
                isOpen={isModalOpen}
                ariaHideApp={false}
                className=" absolute top-2/4 left-2/4 bg-white inline-flex flex-col w-[450px] h-[420px] overflow-scroll  scrollbar-hide"
                style={{
                    overlay: {
                        backgroundColor: 'rgba(0,0,0,0.2)',
                    },
                    content: {
                        transform: `translate(-50%, -50%)`,
                        border: '1px solid #EAEBE6',
                        borderRadius: "12px"
                    }
                }}
            >
                <div className="flex self-stretch p-[15px] justify-between items-center">
                    <div className="text-H5 font-medium text-black">
                        Add Custom Training
                    </div>
                    <SvgCloseLittle fill="#000" viewBox="0 0 20 20" className="mt-[3px] ml-[3px] cursor-pointer" onClick={() => {
                        setIsModalOpen(false)
                        setFile(null)
                        setDocumentName("")
                        setDocumentType("")
                    }} />
                </div>
                <hr />
                <div className="flex self-stretch p-[15px] flex-col items-start gap-3">
                    <div className="flex flex-col items-start gap-[12px] self-stretch">
                        <div className="flex flex-row justify-between items-center w-full">
                            <span className="text-H7 font-normal text-black">{"Document Name"}</span>
                            <Input
                                className="w-2/3"
                                placeholder={"Document Name"}
                                size="middle"
                                value={documentName}
                                maxLength={100}
                                onChange={(e) => {
                                    setDocumentName(e.target.value);
                                    e.target.value.length === 0 ? setDocumentNameError("Document Name can't be empty.") : setDocumentNameError("");

                                }} />
                        </div>
                        <div className="ml-36 justify-center">
                            <span className=" text-red text-BodySmall font-normal">{documentNameError}</span>
                        </div>


                        <div className="flex flex-col w-full ">
                            <div className="flex flex-row justify-between items-center">
                                <span className="text-H7 font-normal text-black">{"Choose a Type"}</span>
                                <Select
                                    className="w-2/3"
                                    placeholder="Please Select"
                                    size="middle"
                                    options={TechnicalDocumentTypes}
                                    onChange={(e) => {
                                        if (e) {
                                            setDocumentType(e)
                                        }
                                    }}
                                />
                            </div>
                        </div>
                        <div className="flex flex-col w-full md:flex-row">
                            <UploadCompanyMaterialNew
                                maxFileCount={1}
                                description="Upload .doc or .pdf .ppt or .txt"
                                filesChanged={async (f: any) => {
                                    setFile(f[0])
                                }}
                                uploadEnabled={file ? false : true}
                                brandHubUpload={true}
                                deleteEnabled={true}
                            />
                        </div>


                    </div>


                </div>
                <div className="flex self-stretch justify-end items-center gap-[10px] p-[15px]">
                    <PrimaryButton
                        noFill
                        size="middle"
                        title={t("cancel") ?? ""}
                        onClick={() => {
                            setIsModalOpen(false)
                            setFile(null)
                            setDocumentName("")
                            setDocumentType("")
                        }}
                    />
                    <PrimaryButton
                        size="middle"
                        className={`${(file && documentName && documentType) ? "bg-red" : "bg-gray"} ml-2`}
                        title={"Add Training"}
                        onClick={() => {
                            handleAddTraining()
                        }}
                    />
                </div>

            </Modal>
        </>

    )
}

export default BrandHubCustomTraining;