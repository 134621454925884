import {
  FunctionComponent,
  LegacyRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { Add } from "../../assets/IconComponents";
import { HexColorInput, HexColorPicker } from "react-colorful";
import { clickOutside } from "../../helpers/ClickOutside";
import { useClickAway } from "@uidotdev/usehooks";
import PrimaryButton from "../PrimaryButton";
import { Delete } from "../../assets/IconComponents";
import { ColorPicker } from "antd";

interface ColorPickerProps {
  className?: string;
  colorSelected: (color: string | undefined) => void;
  selectedColor?: string | undefined;
  isDisabled?: boolean;
  popoverClassName?: string;
  hideDeleteButton?: boolean;
  title?: string;
}

const BrandColorPicker: FunctionComponent<ColorPickerProps> = (
  props: ColorPickerProps
) => {
  let popover: HTMLDivElement;
  const [isOpen, toggle] = useState(false);
  const [color, setColor] = useState<string | undefined>();
  const [colorSelected, setColorSelected] = useState(false);
  const close = useCallback(() => toggle(false), []);

  useEffect(() => {
    if (props.selectedColor && props.selectedColor !== "") {
      setColor(props.selectedColor!);
      setColorSelected(true);
    }
  }, [props.selectedColor]);

  useEffect(() => {
    props.colorSelected(color);
  }, [colorSelected]);

  useEffect(() => {
    if (props.isDisabled) {
      close();
    }
  }, [props.isDisabled]);

  const ref = useClickAway(() => {
    close();
  });

  useEffect(() => {
    clickOutside(popover, () => {
      close();
    });
  }, []);

  return (
    <div className="flex flex-row w-full  justify-between   mt-1 h-9 items-center">
      <div className="flex w-1/3">
        <text className="text-H7 font-normal text-black w-72 sm:w-full truncate text-ellipsis overflow-hidden">
          {props.title ?? ""}
        </text>
      </div>
      <div className="flex flex-row w-full items-center">
        <ColorPicker
          defaultValue={'#FFFFFF'}
          showText={(color) => <span>{color.toHexString()}</span>}
          value={color}
          className="flex justify-between w-32 "
          onChange={(e) => {
            setColor(e.toHexString());
            setColorSelected(true);
            props.colorSelected(e.toHexString());
          }}
        />
      </div>
    </div>
  );
};

export default BrandColorPicker;
