import { useEffect, useRef, useState } from "react";
import { setActivePage, toggleDialog, toggleLoading } from "../../store/appSlice";
import { UserSubscription, useLazyUserSettingsQuery } from "../../services/UsertApi";
import { useDispatch } from "react-redux";
import { t } from "i18next";
import ContentContainer from "../../components/ContentContainer";
import PrimaryButton from "../../components/PrimaryButton";
import { Add, BlogColor, Facebook, FacebookColor, Instagram, InstagramColor, Linkedin, LinkedinColor, TwitterColor } from "../../assets/IconComponents";
import Calendar from "../../components/Calendar/Calendar";
import AIActivityModal from "../../components/ContentCalendar/AIActivityModal";
import { useAppSelector } from "../../helpers/hooks";
import { Button, Checkbox, Modal, Select, TreeSelect } from "antd";
import dayjs from "dayjs";
import { ActivityResponse, MarketingPlannerResponse } from "../../services/MarketingPlanner";
import { useNavigate } from "react-router-dom";
import WarnIcon from "../../assets/IconComponents/WarnIcon";
import {
  useLazyGetAllActivityQuery,
  useLazyGetActivitiesFromCampaignQuery,
  useSaveActivityMutation,
  useDeleteActivityByIdMutation, ActivityRequest
} from "../../services/Activity";
import { setActivities } from "../../store/activitySlice";
import GoogleAdsColor from "../../assets/IconComponents/GoogleAdsColor";
import SvgMetaSocialIcon from "../../assets/IconComponents/MetaSocialIcon";
import NewsLetterColor from "../../assets/IconComponents/NewsLetterColor";
import Twitter from "../../assets/IconComponents/Twitter";
import { blobToDataURL } from "blob-util";
import { useLazyDownloadQuery } from "../../services/FileApi";
import { useLazyDownloadQuery as useLazyDownloadQueryOld } from "../../services/ImageApi.old/ImageApi";
import { reformatText } from "../../helpers/Utils";
import svgIcons from "../../assets/IconComponents/svgs";
import { setNewContent } from "../../store/contentSlice";
import PrepareIcon from "../../assets/IconComponents/PrepareIcon";
import ConfirmationPopupOpener from "../../components/ConfirmationPopup/ConfirmationPopup";
import PlanEditPopupOpener from "../PlanPage/PlanEditPopup";
import DontHaveAnyComponent from "../BrandHub/CreateNew/DontHaveAny";

const campaignsInfo = [
  { key: 'FEATURE_UNVEILING', icon: svgIcons.FEATURE_UNVEILING_SVG },
  { key: 'SALES_CONVERSATION', icon: svgIcons.SALES_CONVERSATION_SVG },
  { key: 'TRAFFIC', icon: svgIcons.TRAFFIC_SVG },
  { key: 'AWARENESS', icon: svgIcons.AWARENESS_SVG },
]

const channelOptions = (param, channelList) => {
  return param?.map(k => ({ value: k, label: <div className={'flex items-center'}><span style={{ height: '10px', width: '10px', transform: 'translate(10%, -40%)' }} dangerouslySetInnerHTML={{ __html: `${channelList.filter(channel => channel.key === k)?.[0].icon}` }}></span> <span className={'pl-4'}>{reformatText(k)}</span></div> }))
}

const campaignOptions = (param) => {
  const options = param?.map(item => ({
    ...item,
    value: JSON.stringify({ value: item.value, parent: '' }),
    title: <div className={'flex items-center'}><span style={{ height: '10px', width: '10px', transform: 'translate(-20%, -60%)' }} dangerouslySetInnerHTML={{ __html: `${campaignsInfo.filter(channel => channel.key === item.title.toUpperCase())?.[0]?.icon || svgIcons.FEATURE_UNVEILING_SVG}` }}></span> <span className={'pl-4'}>{reformatText(item.title)}</span></div>,
    children: item.children.map(subItem => ({
      ...subItem,
      title: <div className={'flex items-center'}><span style={{ height: '10px', width: '10px', transform: 'translate(-20%, -60%)' }} dangerouslySetInnerHTML={{ __html: `${campaignsInfo.filter(channel => channel.key === subItem.title.toUpperCase())?.[0]?.icon || svgIcons.TRAFFIC_SVG}` }}></span> <span className={'pl-4'}>{reformatText(subItem.title)}</span></div>,
      value: JSON.stringify({ value: subItem.value, parent: item.value })
    }))
  }))

  if (options?.length) {
    options.unshift({ value: 'onlyActives', title: 'Show active campaigns' })
  }

  return options
}
const ContentCalendar = (props) => {

  const dispatch = useDispatch();
  const urlParams = new URLSearchParams(window.location.search);
  const success = urlParams.get('success');
  const selectedCampaign = urlParams.get('selectedCampaign');
  const activityToRedirectTo = urlParams.get("redirectToActivity");
  const navigate = useNavigate()

  const { activity } = useAppSelector((k) => k.activity);
  const [save, { data: createdData, isLoading: createIsLoading }] = useSaveActivityMutation()

  const [currentPlan, setCurrentPlan] = useState<UserSubscription>();
  const calendarRef = useRef({});
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);
  const [selectedActivity, setSelectedActivity] = useState<any>();
  const calendarToolbar = useRef<HTMLDivElement>(null);
  const { activities } = useAppSelector((k) => k.activity);
  const [calendarData, setCalendarData] = useState<any[]>()
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [channelFilter, setChannelFilter] = useState<string[]>();
  const [treeCampaignData, setTreeCampaignData] = useState<any>();
  const lastSelectedBrandId = useAppSelector(k => k.persisted.lastSelectedBrandId)
  const [getAllActivity, { data: allActivities, isLoading: allActivityIsLoading }] = useLazyGetAllActivityQuery()
  const [deleteActivityTrigger, { isSuccess: activityDeleted }] = useDeleteActivityByIdMutation()
  const [getActivitiesFromCampaign] = useLazyGetActivitiesFromCampaignQuery()
  const [dontRedirectToActivity, setDontRedirectToActivity] = useState(false);
  const [showPlanUpdateSuccessPopup, setShowPlanUpdateSuccessPopup] = useState(false);
  const [showUpgradePlanDialog, setShowUpgradePlanDialog] = useState(false);
  const [isUpgradePlanPopupOpen, setIsUpgradePlanPopupOpen] = useState(false);
  const [effectiveAt, setEffectiveAt] = useState("");

  const [channels, setChannels] = useState([
    { key: 'FACEBOOK', label: 'Facebook', isSelected: true, isPaid: false, icon: svgIcons.fbSVG, colorIcon: FacebookColor },
    { key: 'X', label: 'X (Twitter)', isSelected: true, isPaid: false, icon: svgIcons.xSVG, colorIcon: TwitterColor },
    { key: 'INSTAGRAM', label: 'Instagram', isSelected: true, isPaid: false, icon: svgIcons.instagramSVG, colorIcon: InstagramColor },
    { key: 'LINKEDIN', label: 'Linkedin', isSelected: true, isPaid: false, icon: svgIcons.linkedInSVG, colorIcon: LinkedinColor },
    { key: 'BLOG', label: 'Blog', isSelected: true, isPaid: false, icon: svgIcons.blogSVG, colorIcon: BlogColor },
    { key: 'NEWSLETTER', label: 'Newsletter', isSelected: true, isPaid: false, icon: svgIcons.newsletterSVG, colorIcon: NewsLetterColor },
    { key: 'GOOGLE_ADS', label: 'Google Ads', isSelected: true, isPaid: true, icon: svgIcons.googleSVG, colorIcon: GoogleAdsColor },
    { key: 'META_ADS', label: 'Meta Ads', isSelected: true, isPaid: true, icon: svgIcons.metaSVG, colorIcon: SvgMetaSocialIcon },
  ])
  const [channelList, setChannelList] = useState<any>();

  const [
    getUser,
    {
      isLoading: userSettingsLoading,
      isFetching: userSettingsFetching,
      data: userSettings,
    },
  ] = useLazyUserSettingsQuery();

  useEffect(() => {
    dispatch(toggleLoading(allActivityIsLoading));
  }, [allActivityIsLoading])

  useEffect(() => {
    dispatch(setActivePage("content-calendar"));
    if (success) {
      setIsSuccessModalOpen(true)
    }
    return () => {
      handleCancel();
    }
  }, []);

  const handleSuccessModalOk = () => {
    navigate(`/app/content-calendar`);
    setIsSuccessModalOpen(false)
  }

  var getColorByStatus = (status: string) => {
    if (status == "Awaiting Confirmation") {
      return {
        background: "#e3c7ff",
        border: "#6001AF",
        textColor: "#121315"
      }
    }
    else if (status == "Awaiting Approval") {
      return {
        background: "#F59D1A1A",
        border: "#F59D1A80",
        textColor: "#121315"
      }
    }
    else if (status == "Content Generation") {
      return {
        background: "#e3c7ff",
        border: "#6001AF",
        textColor: "#121315"
      }
    }
    else if (status == "Scheduled") {
      return {
        background: "#1A8CF51A",
        border: "#1A8CF580",
        textColor: "#121315"
      }
    }
    else if (status == "Draft") {
      return {
        background: "#CECECE1A",
        border: "#8E8E8E80",
        textColor: "#121315"
      }
    }
    else if (status == "Posted") {
      return {
        background: "#16C26C1A",
        border: "#16C26C80",
        textColor: "#121315"
      }
    }
  }

  const getChannelIcon = (channels?: string[]) => {
    if (channels == undefined || channels == null) {
      return undefined
    }

    //(item.channels != undefined && item.channels.length > 0) ? channels.filter(k => k.key == item.channels![0] ?? "")[0].colorIcon : undefined

    return <FacebookColor />
  }

  const getHeroImage = (activity: ActivityResponse) => {

    if (activity.imageContentDetails != undefined && activity.imageContentDetails.length > 0) {
      let firstImage = activity.imageContentDetails[0]?.imageFileId
      return firstImage
    }
    return undefined;
  }

  useEffect(() => {
    setCalendarData([])
  }, [activities]);

  useEffect(() => {

    if (activities) {
      dispatch(toggleLoading(true));
      // @ts-ignore
      if (calendarRef.current) {
        // to clear calendarData before set new data
        // @ts-ignore;
        calendarData?.map(k => window.eventCalendar.deleteEvent({ id: k.id }))
      }

      // @ts-ignore
      setTreeCampaignData(campaignOptions(activities.actionHeaderFilterList))
      // @ts-ignore
      setChannelList(channelOptions(activities.channelTypeList, channels))

      const data = activities?.activityResponseList?.map((item, i) => {
        const eventObj = {
          ...item,
          id: item.id,
          text: (item.status === "Awaiting Confirmation" || item.status === "Content Generation") ? item.objective : (item.textContentDetails != undefined && item.textContentDetails.length > 0 ? item.textContentDetails[0]?.textContent?.trim() : ""),
          type: item.status,
          start_date: dayjs(item.startDate).toDate(),
          details: JSON.stringify(item.channels),
          color: getColorByStatus(item.status ?? ""),
          icon: getChannelIcon(item.channels),
          heroImage: getHeroImage(item)
        } as any

        eventObj.end_date = dayjs(item.endDate).toDate()
        // @ts-ignore
        if (!calendarRef.current?.getEvent({ id: eventObj.id })) {
          // @ts-ignore
          calendarRef.current?.addEvent({ event: eventObj });
        } else {
          // @ts-ignore
          calendarRef.current?.updateEvent({ event: eventObj, id: eventObj.id });
        }

        return eventObj as MarketingPlannerResponse
      })
      setCalendarData(data)
      dispatch(toggleLoading(false));
    }
  }, [activities]);

  const getActivities = (param) => {
    const params = {
      brandId: lastSelectedBrandId
    } as any
    if (selectedCampaign) {
      params.campaignId = selectedCampaign;
    }
    if (param.dataAfterDelete) {
      // @ts-ignore
      setTreeCampaignData(campaignOptions(param.dataAfterDelete.actionHeaderFilterList))
      // @ts-ignore
      setChannelList(channelOptions(param.dataAfterDelete.channelTypeList, channels))
      // @ts-ignore
      dispatch(setActivities({ ...param.dataAfterDelete.data, activityResponseList: filterActivities({ campaign: param.campaign, channel: param.channel, showHolidays: param.showHolidays, list: param.dataAfterDelete.data?.activityResponseList }) }))
    } else {
      if (selectedCampaign?.length) {
        getActivitiesFromCampaign(params).then(activities => {
          // @ts-ignore
          setTreeCampaignData(campaignOptions(activities.actionHeaderFilterList))
          // @ts-ignore
          setChannelList(channelOptions(activities.channelTypeList, channels))
          // @ts-ignore
          dispatch(setActivities({ ...activities.data, activityResponseList: filterActivities({ campaign: param.campaign, channel: param.channel, showHolidays: param.showHolidays, list: activities.data?.activityResponseList }) }))


          if (activityToRedirectTo?.length && !dontRedirectToActivity) {
            if (activities.data) {
              if (activities.data.activityResponseList) {
                const activityFound = activities.data.activityResponseList.find(
                  (act) => act.id == activityToRedirectTo
                );
                if (activityFound) {
                  if (calendarRef.current && !props.isInReview) {
                    // @ts-ignore
                    setSelectedActivity(activityFound);
                    setIsEditModalOpen(true);
                    // @ts-ignore
                    calendarRef.current?.hideEventInfo();
                    setDontRedirectToActivity(true);
                  }
                }
              }
            }
          }
        })
      } else {
        getAllActivity(params).then(activities => {
          // @ts-ignore
          setTreeCampaignData(campaignOptions(activities.actionHeaderFilterList))
          // @ts-ignore
          setChannelList(channelOptions(activities.channelTypeList, channels))
          // @ts-ignore
          dispatch(setActivities({ ...activities.data, activityResponseList: filterActivities({ campaign: param.campaign, channel: param.channel, showHolidays: param.showHolidays, list: activities.data?.activityResponseList }) }))
        })
      }
    }
  }

  useEffect(() => {
    getUser();
    if (!props.isInReview && !success) {
      getActivities({ selectedCampaign });
    }
  }, [lastSelectedBrandId]);

  useEffect(() => {
    if (userSettings) {
      setCurrentPlan(userSettings.subscription);
    }
  }, [userSettings]);



  useEffect(() => {
    dispatch(
      toggleLoading(
        userSettingsLoading
      )
    );
  }, [userSettingsLoading]);

  const handleCancel = () => {
    getActivities({ selectedCampaign });
    setSelectedActivity(undefined)
    if (isModalOpen) {
      setIsModalOpen(false)
    } else if (isEditModalOpen) {
      setIsEditModalOpen(false)
    }

    dispatch(toggleDialog(false));
  }

  const handleOk = () => {
    if (isModalOpen) {
      setIsModalOpen(false)
    } else if (isEditModalOpen) {
      setIsEditModalOpen(false)
    }
    //dispatch(toggleDialog(false));
  }

  useEffect(() => {
    if (calendarToolbar.current) {
      document.getElementsByClassName('wx-event-calendar-toolbar')[0].appendChild(calendarToolbar.current)
    }
  }, []);

  const [campaignValue, setCampaignValue] = useState<string>();

  const onCampaignChange = (value: string) => {
    setCampaignValue(value);
    getActivities({ selectedCampaign, campaign: value, channel: channelFilter })
  };

  const handleChannelChange = (value: string[]) => {
    setChannelFilter(value);
    getActivities({ selectedCampaign, channel: value, campaign: campaignValue })
  };


  const filterActivities = ({ campaign, channel, list, showHolidays }) => {
    let holidaylist = list
    let activityResponseList: any[] = []
    const finalList: any = [];
    const selectedCampaigns: any[] = [];
    let showOnlyActives = false;

    if (showHolidays) {
      holidaylist = list.filter(c => c.type === 'holiday')
    }

    holidaylist?.forEach(item => {
      if (channel?.length) {
        if (!!item?.channels?.filter(k => !!channel.filter(c => c === k).length).length) {
          activityResponseList.push(item)
        }
      } else {
        activityResponseList.push(item)
      }
    });

    if (campaign?.length) {
      campaign.forEach(c => {
        if (c !== 'onlyActives') {
          selectedCampaigns.push(JSON.parse(c))
        } else {
          showOnlyActives = true
        }
      })
    }

    if (showOnlyActives) {
      activityResponseList = activityResponseList?.filter(c => {
        return (dayjs(c.startDate).valueOf() < dayjs().valueOf() && dayjs().valueOf() < dayjs(c.endDate).valueOf())
      })
    }


    if (campaign?.length) {
      selectedCampaigns.forEach(c => {
        activityResponseList?.forEach(item => {

          if ((item.eventType == c.parent.toUpperCase() || (!item.eventType && item.campaignType === c.value.toUpperCase())) && item.campaignType === c.value.toUpperCase()) {
            finalList.push(item)
          }
        })
      })
    } else {
      return activityResponseList
    }


    return finalList;
  }

  useEffect(() => {
    if (activityDeleted && !props.isInReview) {
      getActivities({ selectedCampaign });
    }
  }, [activityDeleted])

  const deleteActivity = (activity: ActivityResponse) => {
    deleteActivityTrigger({
      id: activity.id || "",
      rootId: activity.rootId || "",
    }).then(data => getActivities({ dataAfterDelete: data }))
  }

  const showCreditErrorPopup = () => {
    setIsModalOpen(false);
    setIsEditModalOpen(false);
    setShowUpgradePlanDialog(true);
  }

  return (
    <div className={`${props.isInReview ? 'h-full' : 'h-[calc(100vh-100px)]'}`}>
      <AIActivityModal
        selectedActivity={selectedActivity}
        open={isModalOpen || isEditModalOpen}
        close={() => handleCancel()}
        submit={() => handleOk()}
        title={`${isModalOpen ? t('new-activity') : isEditModalOpen ? 'Edit Activity' : ''}`}
        hideFooter={false}
        disabledClickOutside
        refreshCalendar={() => { getActivities({ selectedCampaign, campaign: campaignValue, channel: channelFilter }); }}
        onCreditError={showCreditErrorPopup}
      />
      {!props.isInReview && <div
        className="relative min-w-full h-[56px] text-xl flex flex-row bg-white border-1 border-border border-l-0 items-center justify-between mb-4">
        <span className="text-H6 text-black px-5">{t("content-calendar")}</span>
      </div>}

      {!props.isInReview && calendarData && calendarData.length === 0 && (
        <div className="w-full flex justify-center pt-20" >
          <div className="w-2/3">
            <DontHaveAnyComponent
              handleClick={() => {
                dispatch(setNewContent({}))
                setIsModalOpen(true)
              }}
              title={"Plan your marketing activities on your calendar"}
              description={"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."}
              titleClassName="text-H3"
              actionButton="Create Your First Activity"
            />
          </div>
        </div>
      )}

      <div id="content-calendar-root" className={`w-full h-full pl-3 pr-3 ${calendarData && calendarData.length === 0 ? 'hidden' : ''}`}>
        <div className="flex w-full scrollbar-hide flex-col h-0">
          <ContentContainer
            className={`w-full h-full`}
            hoverable={false}
            children={
              <div className={'h-full'}>
                <div ref={calendarToolbar}
                  className="flex items-center justify-between w-[60%]">
                  <div className={'w-full flex items-center'}>
                    <div className={'w-[200px] flex justify-center items-center'}>
                      <Checkbox onChange={(e) => {
                        getActivities({
                          selectedCampaign,
                          campaign: campaignValue,
                          channel: channelFilter,
                          showHolidays: e.target.checked
                        })
                      }}>Show holidays</Checkbox>
                    </div>
                    <div className="w-full flex items-center">
                      <TreeSelect
                        showSearch
                        size={'large'}
                        value={campaignValue}
                        dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                        placeholder="Campaigns"
                        className={'w-full'}
                        allowClear
                        multiple
                        treeDefaultExpandAll
                        onChange={onCampaignChange}
                        treeData={treeCampaignData}
                        maxTagCount='responsive'
                      />
                      <Select
                        allowClear
                        size={'large'}
                        placeholder="Channels"
                        className={'ml-2 w-full'}
                        value={channelFilter}
                        onChange={handleChannelChange}
                        options={channelList}
                        maxTagCount='responsive'
                        mode='multiple'
                      />
                    </div>
                  </div>
                  <div>
                    {!props.isInReview && <PrimaryButton
                      onClick={() => {
                        // dispatch(setPlan({}));
                        dispatch(setNewContent({}))
                        setIsModalOpen(true)
                      }}
                      icon={<Add fill="#ffffff" className="mr-2" />}
                      title={'New Activity'}
                      className="h-[39px] w-[146px] ml-2"
                    />}
                  </div>
                </div>
                <div className="w-full text-black h-full">
                  <div id={'content-calendar'} className={'h-full'}>
                    <Calendar
                      isInReview={props.isInReview}
                      events={calendarData}
                      date={new Date()}
                      triggeredEdit={(act) => {
                        if (calendarRef.current && !props.isInReview) {
                          // @ts-ignore
                          setSelectedActivity(act)
                          setIsEditModalOpen(true)
                          // @ts-ignore
                          calendarRef.current?.hideEventInfo();
                        }
                      }}
                      getCalendar={(val) => {
                        calendarRef.current = val;
                      }}
                      deleteActivity={(currentActivity) => {
                        // @ts-ignore
                        calendarRef.current?.hideEventInfo();
                        deleteActivity(currentActivity)
                      }}
                      editActivity={(currentActivity) => {
                        save({ activityRequest: activities?.activityResponseList?.filter(item => item.id == currentActivity.id)[0] as ActivityRequest })
                      }}
                    />
                  </div>
                </div>
              </div>
            }
          />
        </div>
      </div>

      <Modal open={isSuccessModalOpen} onOk={handleSuccessModalOk} onCancel={handleSuccessModalOk} footer={() => {
        return (<div className={'flex justify-center'}>
          <Button key="submit" type="primary" onClick={handleSuccessModalOk}>
            Great!
          </Button>
        </div>)
      }} closeIcon={false} cancelText={'Back'} okText={'OK'}>
        <div className={'flex justify-center items-center flex-col text-center p-3'}>
          <PrepareIcon />
          <h1 className={'text-H5 text-black mt-3'}>Preparing your activities ...</h1>
          <p className={'text-gray block mt-3'}>Evercopy AI is currently creating all activity details and personalized
            contents which will take ~2 mins.</p>
          <p className={'text-gray block mt-3'}>You'll be notified by email when it's ready.</p>
        </div>
      </Modal>

      {showPlanUpdateSuccessPopup && (
        <ConfirmationPopupOpener
          heading={"Your Plan Has Been Updated Successfully!"}
          subHeading={effectiveAt == "IMMEDIATELY" ? "Your plan has been successfully updated. Adjusted features are now in effect." : "The current plan continues until the end of your billing cycle. Your new plan will start in the next billing cycle."}
          buttonText={"Continue"}
          onClose={() => {
            setShowPlanUpdateSuccessPopup(false);
            dispatch(toggleDialog(false));
          }}
        />
      )}
      {isUpgradePlanPopupOpen &&
        (
          <PlanEditPopupOpener
            onClosed={() => {
              setIsUpgradePlanPopupOpen(false);
              dispatch(toggleDialog(false));
            }}
            currentPlanId={userSettings?.subscription?.plan_id}
            paymentMethod={userSettings?.payment_method}
            onPlanUpdateSucceeded={(effectiveAt?: string) => {
              setEffectiveAt(effectiveAt ?? "");
              setIsUpgradePlanPopupOpen(false);
              dispatch(toggleDialog(false));
              setShowPlanUpdateSuccessPopup(true);
            }}
            onUpdatePaymentMethod={() => {
              setIsUpgradePlanPopupOpen(false);
              dispatch(toggleDialog(false));
            }}
          />
        )}
      {showUpgradePlanDialog &&
        (
          <ConfirmationPopupOpener
            heading={"Need More Credits?"}
            subHeading={"Upgrade your plan for more credits and never hit a roadblock in your workflow.."}
            buttonText={"View Plans"}
            onClose={function (): void {
              setShowUpgradePlanDialog(false);
              dispatch(toggleDialog(false));
              setIsUpgradePlanPopupOpen(true);
            }}
          />
        )}

    </div>
  );
};

export default ContentCalendar;
