import * as React from "react";
import type { SVGProps } from "react";
const SvgDontHaveAny = (props: SVGProps<SVGSVGElement>) => (
  <svg width="200" height="80" viewBox="0 0 200 80" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="16.5" y="0.5" width="167" height="67" rx="6.5" fill="white" />
    <rect x="16.5" y="0.5" width="167" height="67" rx="6.5" stroke="#EAEBE6" />
    <rect x="8.5" y="8.5" width="183" height="67" rx="6.5" fill="white" />
    <rect x="8.5" y="8.5" width="183" height="67" rx="6.5" stroke="#EAEBE6" />
    <rect x="0.5" y="16.5" width="199" height="63" rx="6.5" fill="white" />
    <rect x="0.5" y="16.5" width="199" height="63" rx="6.5" stroke="#EAEBE6" />
    <rect x="8" y="24" width="48" height="48" rx="3" fill="#ECECEC" />
    <rect x="66" y="31" width="113" height="6" rx="3" fill="#ECECEC" />
    <rect x="66" y="45" width="113" height="6" rx="3" fill="#ECECEC" />
    <rect x="66" y="59" width="113" height="6" rx="3" fill="#ECECEC" />
  </svg>

);
export default SvgDontHaveAny;
