import { FunctionComponent, useEffect, useState } from "react"
import ContentContainer from "../../../components/ContentContainer";
import { Button, Checkbox, Divider, Input, Radio, Select, SelectProps } from "antd";
import SvgQuestionMark from "../../../assets/IconComponents/QuestionMark";
import IconSelect from "../../../assets/IconComponents/IconSelect";
import BrandHubBeneficiaryPayer from "./BrandHubBeneficiaryPayer";
import { useAppDispatch, useAppSelector } from "../../../helpers/hooks";
import { setNewBrandInfo } from "../../../store/brandSlice";
import SvgPlusTwo from "../../../assets/IconComponents/PlusTwo";
import { t } from "i18next";
import { BeneficiaryPayer, MetaAdsSettings } from "../../../services/BrandApi";
import { PlusOutlined, PlusSquareOutlined } from "@ant-design/icons";



interface IBrandHubMetaAdsSettingsProps {
}


const BrandHubMetaAdsSettings: FunctionComponent<
    IBrandHubMetaAdsSettingsProps
> = (props) => {

    const brandInfo = useAppSelector(k => k.brand.newBrand)
    const dispatch = useAppDispatch()

    const adsCategoryOptions: SelectProps['options'] = [
        {
            label: 'Credit',
            value: 'Credit',

        },
        {
            label: 'Employment',
            value: 'Employment',

        },
        {
            label: 'Housing',
            value: 'Housing',

        },
    ];


    return (<div className="flex flex-col p-[12px] gap-[12px] w-full rounded-[4px] border-1">
        <div className="flex flex-col gap-[4px]">
            <div className="text-H7 font-normal text-black">Meta Ads Settings</div>
            <div className="text-BodySmall text-footnote">Set your default settings for special categories and EU target audiences for Meta Ads. When creating a campaign, you can modify these values at the audience step or proceed with the default settings.
            </div>
        </div>
        <div className="border-t-1 h-[1px]"></div>

        <div className="flex flex-col gap-3">
            {(brandInfo) &&
                <div className="flex flex-col gap-3">
                    <div className="flex flex-row gap-2 items-center">
                        <div className="text-H7 font-normal text-black">Is Your Ad In A Special Category?</div>
                        <span className="mt-1">
                            <SvgQuestionMark
                                data-tooltip-id={"app-tooltip"}
                                data-tooltip-html={t("special-category-ads-tooltip")} />
                        </span>

                    </div>

                    <Checkbox className="text-H7 font-normal" defaultChecked={brandInfo.metaAdsSettings?.ads_category !== undefined} onChange={(a) => {

                        dispatch(
                            setNewBrandInfo({
                                ...brandInfo,
                                metaAdsSettings: {
                                    ...brandInfo.metaAdsSettings,
                                    ads_category: a.target.checked ? "Employement" : undefined,
                                }
                            }))
                    }}>Yes, my ad is related to credit, employment or housing.</Checkbox>

                </div>
            }
            {(brandInfo && brandInfo.metaAdsSettings && brandInfo.metaAdsSettings.ads_category !== undefined) &&
                <div className="flex flex-row justify-between items-center">
                    <div className="text-H7 font-normal text-black">Category</div>
                    <Select className={"w-[570px]"} defaultValue={brandInfo.metaAdsSettings?.ads_category} options={adsCategoryOptions} onChange={(a) => {
                        dispatch(
                            setNewBrandInfo({
                                ...brandInfo,
                                metaAdsSettings: {
                                    ...brandInfo.metaAdsSettings,
                                    ads_category: a
                                }
                            }))
                    }} placeholder={"Select Ads Category"} />
                </div>
            }
        </div>
        <div className="border-t-1 h-[1px]"></div>
        <div className="flex flex-col gap-3">

            {
                (brandInfo) && <div className="flex flex-col gap-3">
                    <div className="flex flex-row gap-2 items-center">
                        <div className="text-H7 font-normal text-black">Do your ads target audiences in the EU?</div>
                        <span className="mt-1">
                            <SvgQuestionMark data-tooltip-id={"app-tooltip"}
                                data-tooltip-html={t("eu-audience-tooltip")} />
                        </span>

                    </div>
                    <Checkbox className="text-H7 font-normal" defaultChecked={brandInfo.metaAdsSettings?.beneficiary_payer !== undefined && brandInfo.metaAdsSettings?.beneficiary_payer.length > 0} onChange={(a) => {
                        dispatch(
                            setNewBrandInfo({
                                ...brandInfo,
                                metaAdsSettings: {
                                    ...brandInfo.metaAdsSettings,
                                    beneficiary_payer: a.target.checked ? [{
                                        isDefault: true,
                                        beneficiary: brandInfo.brandName,
                                        payer: brandInfo.brandName
                                    }] : undefined,
                                }
                            }))

                    }}>Yes, my ad sets target audiences in these areas.</Checkbox>
                </div>
            }
            {(brandInfo && brandInfo.metaAdsSettings && brandInfo.metaAdsSettings.beneficiary_payer && brandInfo.metaAdsSettings.beneficiary_payer.length > 0) &&
                <div className="flex flex-col gap-2">

                    {
                        brandInfo.metaAdsSettings.beneficiary_payer.map((a, index) => {
                            return <BrandHubBeneficiaryPayer
                                index={index}
                                onValuesUpdated={(index, isdefault, beneficiary, payer) => {

                                    if (beneficiary === undefined || payer === undefined) {
                                        return;
                                    }
                                    var beneficiariesCloned = [...brandInfo.metaAdsSettings?.beneficiary_payer ?? []];
                                    var updatedBeneficiary = {
                                        beneficiary: beneficiary,
                                        payer: payer,
                                        isDefault: isdefault
                                    }
                                    beneficiariesCloned[index] = updatedBeneficiary;
                                    if (isdefault) {
                                        beneficiariesCloned = beneficiariesCloned.map((a, indexInner) => {
                                            if (indexInner !== index) {
                                                return {
                                                    beneficiary: a.beneficiary,
                                                    payer: a.payer,
                                                    isDefault: false
                                                }
                                            } else {
                                                return a;
                                            }
                                        })
                                    }

                                    dispatch(
                                        setNewBrandInfo({
                                            ...brandInfo,
                                            metaAdsSettings: {
                                                ...brandInfo.metaAdsSettings,
                                                beneficiary_payer: beneficiariesCloned
                                            }
                                        }))
                                }}
                                beneficiary={a.beneficiary}
                                payer={a.payer}
                                isDefault={a.isDefault ?? false} />

                        })
                    }

                    <div className="flex flex-row justify-between">
                        <div></div>
                        <div className="flex flex-row gap-4">
                            <Button className="" icon={<PlusOutlined />} onClick={() => {
                                dispatch(
                                    setNewBrandInfo({
                                        ...brandInfo,
                                        metaAdsSettings: {
                                            ...brandInfo.metaAdsSettings,
                                            beneficiary_payer: [...brandInfo.metaAdsSettings?.beneficiary_payer ?? [], { beneficiary: "", payer: "", isDefault: false }]

                                        }
                                    }))
                            }}>
                                Add New
                            </Button>
                            <div className="w-[290px]"></div>
                            <div className="w-[120px]"></div>
                        </div>

                    </div>

                </div>
            }
        </div>
    </div >)
}

export default BrandHubMetaAdsSettings;