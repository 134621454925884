import { t } from "i18next";
import { FunctionComponent, useEffect, useState } from "react";

import { useAppDispatch, useAppSelector } from "../../../helpers/hooks";
import { setDialog, toggleDialog, toggleLoading } from "../../../store/appSlice";
import SvgAddPlusIcon from "../../../assets/IconComponents/SvgAddPlus";
import { AudienceResponse, useDeleteAudienceMutation, useLazyGetAudienceQuery } from "../../../services/AudiencetApi";
import IconButton from "../../../components/IconButton";
import { Delete, Edit } from "../../../assets/IconComponents";
import BrandHubCreateNewAudience from "./BrandHubCreateNewAudience";
import PrimaryButton from "../../../components/PrimaryButton";
import { setNewBrandInfo } from "../../../store/brandSlice";
import SvgList from "../../../assets/IconComponents/List";
import SvgDontHaveAny from "../../../assets/IconComponents/DontHaveAny";
import { Button, Table, Tooltip } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import DontHaveAnyComponent from "./DontHaveAny";
import BrandHubCreateNewAudienceModal from "./BrandHubCreateNewAudienceModal";
import SvgDelete from "../../../assets/IconComponents/Delete";

interface IBrandHubAudiencesProps {
  className?: string;
}

const BrandHubAudiences: FunctionComponent<IBrandHubAudiencesProps> = (
  props: IBrandHubAudiencesProps
) => {
  const brandInfo = useAppSelector((k) => k.brand.newBrand);
  const dispatch = useAppDispatch();

  const [isCollapsed, setIsCollapsed] = useState(true);
  const [selectedAudience, setSelectedAudience] = useState<AudienceResponse | undefined>(undefined);
  const [getAudiencesList, { isLoading: audiencesListLoading, data: audiencesListApi }] = useLazyGetAudienceQuery();
  const [
    deleteAudience,
    { isSuccess: audienceDeleted, isLoading: audienceDeleting, isError: audienceDeleteFailed },
  ] = useDeleteAudienceMutation();

  const [deletingAudienceId, setDeletingAudienceId] = useState<string>();


  useEffect(() => {
    if (brandInfo.id) {
      getAudiencesList({ brandId: brandInfo.id ?? "" });
    }
  }, []);


  useEffect(() => {
    dispatch(toggleLoading(audiencesListLoading || audienceDeleting));
  }, [audiencesListLoading, audienceDeleting]);

  useEffect(() => {
    if (audienceDeleted && deletingAudienceId && brandInfo.associatedAudiences) {
      const modifiedList = brandInfo.associatedAudiences.filter(a => a.audience_id !== deletingAudienceId)
      dispatch(
        setNewBrandInfo({
          ...brandInfo,
          associatedAudiences: modifiedList,
        })
      );
    }
  }, [audienceDeleted])

  useEffect(() => {
    setDeletingAudienceId(undefined);
  }, [audienceDeleteFailed])

  useEffect(() => {
    if (audiencesListApi) {
      dispatch(
        setNewBrandInfo({
          ...brandInfo,
          associatedAudiences: audiencesListApi,
        })
      );
    }
  }, [audiencesListApi]);

  useEffect(() => {
    if (brandInfo.associatedAudiences) {
      console.log("Associated Aud: ", brandInfo.associatedAudiences);
    }
  }, [brandInfo.associatedAudiences]);



  const deleteAudienceDialog = (audience) => {
    dispatch(
      setDialog({
        message: t("are-you-sure-you-want-to-delete-this-audience") ?? "",
        title: t("delete-audience"),
        actions: (
          <>
            <div className="flex w-full flex-col lg:flex-row lg:justify-end">
              <PrimaryButton
                size="middle"
                noFill
                title={t("cancel")}
                onClick={() => {
                  dispatch(toggleDialog(false));
                }}
              />
              <PrimaryButton
                className="ml-2"
                size="middle"
                title={t("yes-delete")}
                onClick={() => {
                  dispatch(toggleDialog(false));
                  deleteAudience({
                    audienceId: audience.audience_id ?? "",
                  });
                }}
              />
            </div>
          </>
        ),
        onClose: () => {
          dispatch(toggleDialog(false));
        },
      })
    );

    dispatch(toggleDialog(true));
  };

  const showAudienceDialog = () => {
    dispatch(
      setDialog({
        title: "",
        message: "",
        actions: <></>,
        customTemplate: (
          <BrandHubCreateNewAudienceModal
            hideMetaAdsSettings
            onAudienceSaved={(audience) => {
              dispatch(toggleDialog(false));
            }}
            onClose={() => {
              setSelectedAudience(undefined);
              dispatch(toggleDialog(false));
            }}
            audience={selectedAudience}
          />
        ),
      })
    );

    dispatch(toggleDialog(true));
  };

  useEffect(() => {
    if (selectedAudience) {
      showAudienceDialog();
    }
  }, [selectedAudience]);
  return (
    <>
      {(!brandInfo.associatedAudiences || !brandInfo.associatedAudiences.length) && (
        <DontHaveAnyComponent
          title={"You don’t have any audience yet"}
          description={"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."}
          handleClick={() => {
            setSelectedAudience(undefined);
            showAudienceDialog();
          }} />
      )}

      {brandInfo.associatedAudiences && brandInfo.associatedAudiences.length > 0 && (
        <div className="flex flex-col mt-9 gap-1">
          {(!selectedAudience) && (
            <>
              <div className="flex flex-col w-full  bg-white border-1 rounded-lg">
                <div className="flex flex-row justify-between items-center px-4 mt-4">
                  <span className="text-H5 font-medium text-black ">Audiences</span>
                  <Button className="" type="primary" icon={<PlusOutlined />}
                    onClick={() => {
                      setSelectedAudience(undefined);
                      showAudienceDialog();
                    }}>
                    Add New
                  </Button>
                </div>
                <Table
                  size="small"
                  rowKey="audience_id"
                  className="w-full px-4 py-3"
                  dataSource={brandInfo.associatedAudiences}
                  columns={[
                    {
                      title: 'Name',
                      dataIndex: 'name',
                      key: 'name',
                    },
                    {
                      title: 'Locations',
                      dataIndex: 'location',
                      key: 'location',
                      render: (text, record) => (
                        <div className="max-w-[120px] truncate">
                          {record.location?.map((loc, index) => (
                            <span key={index}>{loc.name}{index + 1 == record.location?.length ? '' : ','}</span>
                          ))}
                        </div>
                      ),
                    },
                    {
                      title: 'Age',
                      dataIndex: 'age',
                      key: 'age',
                      render: (text, record) => (
                        <span>
                          {record.age_group ? record.age_group[0] : ''} - {record.age_group ? record.age_group[1] : ''}
                        </span>
                      ),
                    },
                    {
                      title: 'Gender',
                      dataIndex: 'gender',
                      key: 'gender',
                      render: (text, record) => (
                        <span>
                          {record.gender?.length ? record.gender[0]?.charAt(0).toUpperCase() + record.gender[0]?.slice(1) : ""}
                        </span>
                      ),
                    },
                    {
                      title: 'Languages',
                      dataIndex: 'language',
                      key: 'language',
                      render: (text, record) => (
                        <div className="max-w-[120px] truncate">
                          {record.language?.map((language, index) => (
                            <span key={index}>
                              {language}
                              {index + 1 !== record.language?.length && ', '}
                            </span>
                          ))}
                        </div>
                      ),
                    },
                    {
                      title: '',
                      key: 'action',
                      render: (text, record) => (
                        <div className="flex items-center gap-[10px] self-stretch cursor-pointer" style={{ justifyContent: "end" }}>
                          <Tooltip title={t("edit")}>
                            <Edit fill="#8E8E8E" onClick={() => setSelectedAudience(record)} />
                          </Tooltip>
                          <Tooltip title={t("delete")}>
                            <SvgDelete fill="#8E8E8E" onClick={() => {
                              setDeletingAudienceId(record.audience_id);
                              deleteAudienceDialog(record);
                            }} />
                          </Tooltip>
                        </div>
                      ),
                    },
                  ]}
                  pagination={false} />
              </div>
            </>
          )}
        </div>
      )}
    </>

  );
};

export default BrandHubAudiences;
