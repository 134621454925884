import { FunctionComponent, useEffect, useState } from "react";
import { t } from "i18next";
import { Font } from "../../services/FontApi";
import { FileProps } from "../../services/BrandApi";
import SvgCloseLittle from "../../assets/IconComponents/CloseLittle";
import SvgErrorIcon from "../../assets/IconComponents/ErrorIcon";
import { useAppDispatch } from "../../helpers/hooks";
import { toggleLoading, setDialog, toggleDialog } from "../../store/appSlice";
import { useDeleteFileDeleteByIdMutation, useLazyDownloadQuery, useUploadMutation } from "../../services/FileApi";
import { blobToDataURL, dataURLToBlob } from "blob-util";
import PrimaryButton from "../PrimaryButton";
import { useRef } from "react";
import { refreshPage } from "../../store/appSlice";
import { Input, Select, UploadProps } from "antd";
import SvgUploadIconOutline from "../../assets/IconComponents/UploadIconOutline";
import { Close } from "../../assets/IconComponents";
import Dragger from "antd/es/upload/Dragger";
import { InboxOutlined } from "@ant-design/icons";
const { Option } = Select;

interface FontSelectorProps {
  className?: string
  title: string
  fonts: Font[]
  fontSelected: (font: FileProps | undefined) => void
  selectedFont?: FileProps
}


const FontSelector: FunctionComponent<FontSelectorProps> = (props: FontSelectorProps) => {
  const [error, setError] = useState("");
  const dispatch = useAppDispatch();
  const uploadedFileRef = useRef<FileProps | undefined>(undefined);
  const newFontNameRef = useRef<string>("");
  const latestUploadedNameRef = useRef<string>("");
  const [
    uploadFiles,
    {
      data: uploadedFileData,
      isSuccess: filesUploaded,
      isLoading: filesUploading,
    },
  ] = useUploadMutation();
  const [
    deleteFile,
    { data: fileDeletedData, isSuccess: fileDeleted, isLoading: fileDeleting },
  ] = useDeleteFileDeleteByIdMutation();
  const [
    downloadFile,
    {
      data: fileDownloaddData,
      isSuccess: fileDownloaded,
      isLoading: fileDownloading,
      currentData: dt,
    },
  ] = useLazyDownloadQuery();

  useEffect(() => {
    console.log("changed")
  }, [newFontNameRef.current]);

  const handleFinalizeFontSelection = () => {
    const updatedFile = { ...uploadedFileRef.current };

    // Modify the specific attribute in the copied object
    if (newFontNameRef.current !== "") {
      updatedFile.name = newFontNameRef.current;
    }

    props.fontSelected(updatedFile);
    dispatch(toggleDialog(false));
    latestUploadedNameRef.current = "";
    uploadedFileRef.current = undefined;
    newFontNameRef.current = "";
  };


  const downloadFileToDesktop = (uploadId: string) => {
    downloadFile({
      id: uploadId,
    })
      .unwrap()
      .then(async (k) => {
        const dataUrl = await blobToDataURL(k.file);

        var link = document.createElement("a");
        link.download = k.name;
        link.href = dataUrl;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
  };

  const showSuccessDialog = (isDisabled: boolean = true) => {
    dispatch(
      setDialog({
        actions: <></>,
        title: "",
        message: "",
        customTemplate: (
          <>
            {/*Header*/}
            <div className="h-[40px] w-full flex items-center mb-1 justify-between">
              <text className="text-H5 font-medium text-black ml-3 ">
                {"Upload Your Font"}
              </text>
              <button
                className="text-black"
                onClick={() => {
                  dispatch(toggleDialog(false));
                  latestUploadedNameRef.current = "";
                  uploadedFileRef.current = undefined;
                  newFontNameRef.current = "";
                }}
              >
                <Close width={18} height={18} fill="rgba(0, 0, 0, 0.45)" />
              </button>
            </div>
            <hr className="bg-border -ml-5 -mr-5" />
            {/*Body*/}
            <div className="p-[15px] pt-[10px] h-full  overflow-scroll scrollbar-hide">
              <div className="flex flex-col w-full ">
                <div className="flex flex-row justify-between items-center">
                  <span className="text-H7 font-normal text-black">{t("give-your-font-a-name") ?? ""}</span>
                  <Input
                    className="w-2/3"
                    placeholder={t("font-name") ?? ""} size="middle"
                    onChange={(e) => {
                      newFontNameRef.current = e.target.value;
                      showSuccessDialog(checkIsApplyButtonDisabled());

                    }} />
                </div>

                <div className="my-4">
                  <Dragger {...uploadProps}>
                    <p className="ant-upload-drag-icon">
                      <InboxOutlined />
                    </p>
                    <p className="text-H5 font-normal text-black">
                      Click or drag file to this area to upload
                    </p>
                    <p className="text-H7 font-normal text-footnote">
                      .woff, .otf or .tff files. MAX 5MB
                    </p>
                  </Dragger>
                </div>


              </div>
            </div>
            <hr className="bg-border -ml-5 -mr-5" />

            <div className="h-8 flex justify-end items-end mt-3">
              <PrimaryButton
                noFill
                size="middle"
                title={t("cancel") ?? ""}
                onClick={() => {
                  dispatch(toggleDialog(false));
                  latestUploadedNameRef.current = "";
                  uploadedFileRef.current = undefined;
                  newFontNameRef.current = "";
                }}
              />
              <PrimaryButton
                size="middle"
                className=" ml-2 bg-gray"
                title={t("add-font") ?? ""}
                isDisabled={isDisabled}
                onClick={() => {
                  handleFinalizeFontSelection();
                }}
              />
            </div>

          </>
        ),
      })
    );
    dispatch(toggleDialog(true));
  };

  const checkIsApplyButtonDisabled = () => {
    return !uploadedFileRef.current || newFontNameRef.current === "";
  };

  const fileToBlob = (file?: any) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onload = () => {
        if (!!reader.result) {
          resolve(reader.result);
        } else {
          reject(Error("Failed converting to base64"));
        }
      };
    });
  };

  const selectFile = async (files: any[]) => {
    dispatch(toggleLoading(true));

    let selectedFile = files[0];

    let a = (await fileToBlob(selectedFile)) as string;
    let blob = dataURLToBlob(a);
    const formData: FormData = new FormData();
    formData.append("files", blob, selectedFile.name);
    uploadFiles({
      body: formData,
    })
      .unwrap()
      .then((k) => {
        selectedFile["uploadedId"] = k.ids ?? "";
        let f = {
          id: selectedFile.uploadedId,
          name: selectedFile.name,
          uploaded: true,
        } as FileProps;
        uploadedFileRef.current = f;
        dispatch(refreshPage());
        dispatch(toggleLoading(false));
      });
  };

  const handleFilesChangedNew: UploadProps['onChange'] = async (info) => {
    const { status, originFileObj } = info.file;

    if (status === "uploading") {
      // Dosyayı önizlemek için URL oluşturuyoruz
      if (originFileObj) {
        const file = Object.assign(originFileObj, {
          preview: URL.createObjectURL(originFileObj),
        });
        selectFile([file]);
      }
    }

    if (status === "done") {
      // Yükleme başarılı olduğunda yapılacak işlemler
      const uploadedId = info.file.response?.ids;
      if (uploadedId) {
        // Seçilen dosyanın ID'si ve adı üzerinden işlem yapıyoruz
        uploadedFileRef.current = {
          id: uploadedId,
          name: info.file.name,
          uploaded: true,
        };
      }
    } else if (status === "error") {
      setError(`${info.file.name} yüklenirken bir hata oluştu.`);
    }
  };

  const uploadProps: UploadProps = {
    name: "file",
    multiple: false,
    maxCount: 1,
    accept: ".ttf, .woff, .woff2, .otf",
    onChange: handleFilesChangedNew,
    customRequest: async ({ file, onSuccess, onError }) => {
      try {
        // Dosyayı işle ve yükle
        const a = (await fileToBlob(file)) as string;
        const blob = dataURLToBlob(a);
        const formData = new FormData();
        formData.append("files", blob, file.toString());

        // save the uploaded file to the server
        const response = await uploadFiles({ body: formData }).unwrap();

        onSuccess?.(response);
        showSuccessDialog(checkIsApplyButtonDisabled());
      } catch (err) {
        console.error("Upload failed", err);
        onError ? (err) : null;
      }
    },
  };

  return (
    <div className={`flex flex-row w-full ${props.className}`}>
      <p className="text-H7 font-normal text-black w-1/3 mt-2">{props.title}</p>
      <div className="flex flew-row w-full items-center">
        {props.selectedFont && props.selectedFont.uploaded === true && (
          <div
            className="flex flex-row mt-2 bg-lightBg rounded-lg w-full h-8 items-center justify-between px-2 py-4 border-1 border-border cursor-pointer"
            onClick={() => {
              downloadFileToDesktop(props.selectedFont?.id ?? "");
            }}
          >
            <p className="text-BodyMedium text-black">
              {props.selectedFont.name}
            </p>
            <button
              className="rounded-full w-5 h-5 border-1 bg-bg border-border flex items-center justify-center"
              onClick={(e) => {
                e.stopPropagation();

                const id = props.selectedFont?.id;

                props.fontSelected(undefined);
                deleteFile({
                  id: id ?? "",
                });
              }}
            >
              <SvgCloseLittle fill="#000" />
            </button>
          </div>
        )}
        {(!props.selectedFont || props.selectedFont.uploaded === false) && (
          <>
            <Select
              value={props.selectedFont?.id ?? ""}
              className={`w-4/5 `}
              size="middle"
              style={{ fontFamily: props.selectedFont?.name }}
              onChange={(e) => {
                let s = props.fonts.find((k) => k.file_id === e)!;
                props.fontSelected({
                  id: s.file_id,
                  name: s.name,
                  uploaded: false,
                } as FileProps);
              }}
            >
              {props.fonts.map((font) => (
                <Option key={font.name} value={font.file_id} style={{ fontFamily: font.name }}>
                  {font.name}
                </Option>
              ))}
            </Select>
            <text className="mx-4 text-BodyMedium text-gray">or</text>
            <button
              className="flex flex-row border-1  border-border rounded-md h-8 items-center px-2 w-1/5 py-1 "
              onClick={() => showSuccessDialog(true)}
            >
              <SvgUploadIconOutline fill="#121315" className="mr-2" />
              <span className="text-H7 font-normal text-black">
                {t("upload")}
              </span>
            </button>
          </>
        )}
      </div>
      {error && (
        <div className="flex flex-row mt-2 items-start">
          <SvgErrorIcon
            width="20"
            height="20"
            fill="#FF5555"
            className="mr-2"
          />
          <text className="text-red text-BodySmall">{error}</text>
        </div>
      )}
    </div>
  );
}

export default FontSelector;
