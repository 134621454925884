import { t } from "i18next";
import { useEffect, useState } from "react";
import { Popover } from "react-tiny-popover";
import { BrandRequest, useLazyList6Query } from "../../services/BrandApi";
import MenuBrandSelectorPopup from "./MenuBrandSelectorPopup";
import SvgUnion from "../../assets/IconComponents/Union";
import { useAppSelector } from "../../helpers/hooks";
import { useNavigate } from "react-router-dom";
import SvgDefaultBrandLogoNew from "../../assets/IconComponents/DefaultBrandLogoNew";
import SidebarBrandLogo from "./SidebarBrandLogo";


export interface IMenuBrandSelectorProps {
  BrandSelected: (data: BrandRequest | undefined) => void;
  tooltip?: string;
  selectedBrandId: string | undefined;
  brandUpdated?: boolean;
  isPopoverOpen: boolean;
  isMenuCollapsed?: boolean;
  setIsBrandPopoverOpen: (boolean) => void;
  logoutClosed?: boolean;
}

function MenuBrandSelector(props: IMenuBrandSelectorProps) {
  const [selectedBrand, setSelectedBrand] = useState<
    BrandRequest | undefined
  >();
  const navigate = useNavigate();

  const [getBrands, { data: brandList, isLoading: brandsLoading }] =
    useLazyList6Query();


  useEffect(() => {
    getBrands();
  }, []);


  useEffect(() => {
    getBrands();
  }, [props.brandUpdated]);

  const lastSelectedBrandId = useAppSelector(
    (k) => k.persisted.lastSelectedBrandId
  );

  useEffect(() => {
    getBrands();
  }, [lastSelectedBrandId]);

  useEffect(() => {
    if (brandList && props.selectedBrandId && brandList.length > 0) {
      let selectedBrand = brandList!.find(
        (k) => k.id === props.selectedBrandId
      )!;
      setSelectedBrand(selectedBrand);
    } else if (brandList && brandList.length > 0) {
      let firstBrand = brandList![0];
      setSelectedBrand(firstBrand);
      props.BrandSelected(firstBrand);
    }
  }, [brandList, props.selectedBrandId]);


  return (
    <>
      <div className="max-lg:hidden lg:flex w-full mt-3 cursor-pointer">
        <Popover
          isOpen={props.isPopoverOpen && (props.logoutClosed ?? true)}
          positions={["bottom"]}
          align="center"
          containerClassName="z-[3000]"
          padding={12}
          onClickOutside={() => {
            props.setIsBrandPopoverOpen(false);
          }}
          content={
            brandList && brandList?.length != 0 ? (
              <MenuBrandSelectorPopup
                data={brandList ?? []}
                closePopup={() => props.setIsBrandPopoverOpen(false)}
                selectedBrand={selectedBrand}
                selectedBrandChanged={(e) => {
                  props.BrandSelected(e);
                }}
              />
            ) : (
              <></>
            )
          }
        >
          <div
            className="h-12"
            onClick={() => props.setIsBrandPopoverOpen(!props.isPopoverOpen)}
            data-tooltip-id={props.tooltip ? "app-tooltip" : ""}
            data-tooltip-html={props.tooltip}
          >
            <div
              className={`h-full w-[240px] p-1 rounded-lg flex flex-row items-center`}
              onClick={() => { }}
            >
              <div className="flex flex-row items-center">
                {selectedBrand === undefined && (
                  <>
                    {(props.isMenuCollapsed ?? false) && (
                      <div className="ml-1 flex justify-center items-center h-[40px] w-[40px] rounded border border-border"
                        data-tooltip-content={"Add New Brand"}
                        onClick={() => {
                          navigate("/app/brandhub/new");
                        }}>
                        <SvgDefaultBrandLogoNew width={20} height={20} fill={"#000000"} />
                      </div>
                    )}
                    {!(props.isMenuCollapsed ?? false) && (
                      <button
                        className={`
                        bg-menuBg
                        focus:bg-menuBrandHoverBg
                        hover:bg-menuBrandHoverBg
                          h-[46px]
                          w-[230px] pl-3
                          rounded
                          `}
                        title={"props.title"}
                      >
                        <div className="flex flex-row items-center "
                          onClick={() => {
                            navigate("/app/brandhub/new");
                          }}
                        >
                          <div className="p-2 border border-border rounded">
                            <SvgDefaultBrandLogoNew
                              fill="#999999"
                              className="w-[20px] h-[20px]"
                            />
                          </div>

                          <p
                            className={` w-full text-red underline lg:w-[130px] ml-[15px] whitespace-nowrap text-left  text-BodyMedium text-ellipsis overflow-hidden`}
                          >
                            {"Add New Brand"}
                          </p>
                        </div>
                      </button>
                    )}
                  </>
                )}
                {selectedBrand !== undefined && (
                  <>
                    <div className={`flex w-[240px] justify-between flex-row  items-center
                      ${props.isMenuCollapsed ? 'px-1' : 'py-4 px-3'}
                      `}>
                      <div className="flex flex-row items-center">
                        <div className="rounded">
                          <SidebarBrandLogo brand={selectedBrand} />
                        </div>
                        {!(props.isMenuCollapsed ?? false) && (
                          <div className="flex flex-col ml-3 max-w-[120px]">
                            <span className={`text-BodyMedium font-medium text-black truncate`}>
                              {selectedBrand.brand_name}
                            </span>
                            <span className="text-BodyMedium text-footnote truncate">
                              {`${selectedBrand?.connected_account_count ?? 0} Channel${(selectedBrand?.connected_account_count ?? 0) > 1 ? 's' : ''} `}
                            </span>
                          </div>

                        )}
                      </div>
                      <div className="mr-2">
                        <SvgUnion />
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </Popover>
      </div>
    </>
  );
}

export default MenuBrandSelector;
