<script>
	import { getPopupPosition, locateID } from "@xbs/lib-dom";
	import { Month } from "@wx/svelte-core";
	import { format } from "date-fns";
	import { fly } from "svelte/transition";
	import { getContext, createEventDispatcher } from "svelte";
	import { localeContext, dblClickOnYearCalendar } from "@xbs/lib-scheduler";

	export let month = {};
	export let activeDate;
	export let transition = { x: 0, duration: 700 };
	const dispatch = createEventDispatcher();
	const _dates = getContext(localeContext).getRaw().dateFnsLocale;
	const { markers, current } = month;
	let node;
	let dblClicked = false;
	function clickHandler(ev) {
		setTimeout(() => {
			if (dblClicked) {
				return (dblClicked = false);
			}
			const id = locateID(ev);
			if (id) {
				const date = new Date(+id);
				dispatch("action", {
					action: "set-date",
					data: { value: date },
				});
				dispatch("showPopup", {
					pos: getPopupPosition(node, id, node, ev),
					date,
				});
			}
		});
	}

	function dblClick(ev) {
		dblClicked = true;
		dblClickOnYearCalendar(ev, dispatch);
	}

</script>

<div
	class="wx-event-calendar-month-wrapper"
	on:click={clickHandler}
	on:dblclick={dblClick}
	in:fly={transition}
	bind:this={node}>
	<div class="wx-event-calendar-month-title">
		{format(current, 'MMMM', { locale: _dates })}
	</div>
	<Month
		part="normal"
		{markers}
		{current}
		buttons={false}
		cancel={false}
		bind:value={activeDate} />
</div>

<style>
	.wx-event-calendar-month-wrapper {
		width: 260px;
		--wx-calendar-padding: 20px 10px !important;
		--wx-calendar-cell-gap: 8px !important;
	}
	.wx-event-calendar-month-title {
		width: 100%;
		text-align: center;
		margin-bottom: 12px;
		color: var(--wx-color-font);
		font-weight: var(--wx-font-weight-md);
	}
	:global(.wx-event-calendar-month-wrapper .day) {
		position: relative;
	}

</style>
