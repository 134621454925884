import * as React from "react";
import type { SVGProps } from "react";
const SvgUploadIconOutline = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={18}
    fill="none"
    {...props}
  >
    <path
      fill={`${props.fill ? props.fill : "#999"}`}
      d="M8.25 9.6375L7.575 10.2938C7.4375 10.4313 7.26563 10.5 7.05938 10.5C6.85313 10.5 6.675 10.425 6.525 10.275C6.3875 10.1375 6.31875 9.9625 6.31875 9.75C6.31875 9.5375 6.3875 9.3625 6.525 9.225L8.475 7.275C8.625 7.125 8.8 7.05 9 7.05C9.2 7.05 9.375 7.125 9.525 7.275L11.475 9.225C11.6125 9.3625 11.6844 9.53438 11.6906 9.74063C11.6969 9.94688 11.625 10.125 11.475 10.275C11.3375 10.4125 11.1656 10.4844 10.9594 10.4906C10.7531 10.4969 10.575 10.4313 10.425 10.2938L9.75 9.6375V13.5H13.875C14.4 13.5 14.8438 13.3188 15.2063 12.9563C15.5688 12.5938 15.75 12.15 15.75 11.625C15.75 11.1 15.5688 10.6563 15.2063 10.2938C14.8438 9.93125 14.4 9.75 13.875 9.75H12.75V8.25C12.75 7.2125 12.3844 6.32813 11.6531 5.59688C10.9219 4.86563 10.0375 4.5 9 4.5C7.9625 4.5 7.07813 4.86563 6.34688 5.59688C5.61563 6.32813 5.25 7.2125 5.25 8.25H4.875C4.15 8.25 3.53125 8.50625 3.01875 9.01875C2.50625 9.53125 2.25 10.15 2.25 10.875C2.25 11.6 2.50625 12.2188 3.01875 12.7313C3.53125 13.2438 4.15 13.5 4.875 13.5H6C6.2125 13.5 6.39062 13.5719 6.53438 13.7156C6.67813 13.8594 6.75 14.0375 6.75 14.25C6.75 14.4625 6.67813 14.6406 6.53438 14.7844C6.39062 14.9281 6.2125 15 6 15H4.875C3.7375 15 2.76562 14.6063 1.95938 13.8188C1.15313 13.0312 0.75 12.0688 0.75 10.9313C0.75 9.95625 1.04375 9.0875 1.63125 8.325C2.21875 7.5625 2.9875 7.075 3.9375 6.8625C4.25 5.7125 4.875 4.78125 5.8125 4.06875C6.75 3.35625 7.8125 3 9 3C10.4625 3 11.7031 3.50938 12.7219 4.52813C13.7406 5.54688 14.25 6.7875 14.25 8.25C15.1125 8.35 15.8281 8.72188 16.3969 9.36563C16.9656 10.0094 17.25 10.7625 17.25 11.625C17.25 12.5625 16.9219 13.3594 16.2656 14.0156C15.6094 14.6719 14.8125 15 13.875 15H9.75C9.3375 15 8.98438 14.8531 8.69063 14.5594C8.39688 14.2656 8.25 13.9125 8.25 13.5V9.6375Z"
    />
  </svg>
);
export default SvgUploadIconOutline;
