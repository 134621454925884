import { t } from "i18next";
import { Tabs, Input } from "antd";
import { CSSObjectWithLabel, StylesConfig } from "react-select";
import PickerView, { PickerOptions } from "../../components/PickerView";
import { TextToSpeechVoices } from "../../constants/TextToSpeechVoices";
import PrimaryButton from "../../components/PrimaryButton";
import AudioPlayer from "./AudioPlayer";
import { FileProps, useFileToSpeechMutation, useTextToSpeechMutation } from "../../services/ToolsApi";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toggleLoading } from "../../store/appSlice";
import { useLazyDownloadQuery } from "../../services/FileApi";
import { TextToSpeechFileFormats } from "../../constants/TextToSpeechFileFormats";
import UploadTextToSpeechFile from "./UploadTextToSpeechTextFile";
import { PaymentErrorCode } from "../../services/Error/PaymentErrorCode";
import { instanceOfApiErrorWithCode } from "../../services/Core/BaseApi";

export interface ITextToSpeechTabContentProps {
  isUploadPage: boolean;
  audioSrcChanged: (audioSrc: string) => void;
  audioFileChanged: (audioFile: FileProps) => void;
  onCreditError: () => void
}

function TextToSpeechTabContent(props: ITextToSpeechTabContentProps) {
  const [audioSrc, setAudioSrc] = useState<string | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { TextArea } = Input;
  const [
    downloadFile,
    {
      data: fileDownloaddData,
      isSuccess: fileDownloaded,
      isLoading: fileDownloading,
      currentData: dt,
    },
  ] = useLazyDownloadQuery();

  const [audioFileId, setAudioFileId] = useState<FileProps | undefined>(
    undefined
  );
  const [textFileId, setTextFileId] = useState<FileProps | undefined>(
    undefined
  );
  const [textToBeConverted, setTextToBeConverted] = useState("");
  const [voice, setVoice] = useState("female1");
  const [outputFormat, setOutputFormat] = useState("mp3");
  const dispatch = useDispatch();
  const [
    textToSpeech,
    {
      isLoading: isTextToSpeechLoading,
      isSuccess: textToSpeechSuccess,
      data: textToSpeechData,
      error: textToSpeechError,
    },
  ] = useTextToSpeechMutation();

  const [
    fileToSpeech,
    {
      isLoading: isFileToSpeechLoading,
      isSuccess: fileToSpeechSuccess,
      data: fileToSpeechData,
      error: fileToSpeechError,
    },
  ] = useFileToSpeechMutation();

  useEffect(() => {
    if (textToSpeechSuccess) {
      if (textToSpeechData) {
        console.log("Successful: ", textToSpeechData);
        if (textToSpeechData.speechFile)
          setAudioFileId(textToSpeechData.speechFile);

      }
    }
  }, [textToSpeechSuccess]);

  useEffect(() => {
    if (fileToSpeechSuccess) {
      if (fileToSpeechData) {
        console.log("Successful: ", fileToSpeechData);
        if (fileToSpeechData.speechFile)
          setAudioFileId(fileToSpeechData.speechFile);
      }
    }
  }, [fileToSpeechSuccess]);


  useEffect(() => {
    if (textFileId) {
      console.log("Text file id changed, ", textFileId)
    }
  }, [textFileId]);

  useEffect(() => {
    if (audioFileId) {
      const match = voice.match(/(\D+)(\d+)/);
      let formattedVoice = voice;
      if (match && match.length === 3) {
        const type = match[1]; // "male", "female", etc.
        const number = match[2]; // "1", "2", etc.
        formattedVoice = `${type.charAt(0).toUpperCase()}${type.slice(
          1
        )}_${number}`;
      }

      const formattedFileName = `${audioFileId.file_name}_${formattedVoice}`;
      const fetchAudio = async () => {
        try {
          downloadFile({
            id: audioFileId.id ?? "",
          })
            .unwrap()
            .then(async (k) => {
              const url = URL.createObjectURL(k.file);
              console.log("URL is: ", url);
              props.audioSrcChanged(url);
              props.audioFileChanged({ ...audioFileId, file_name: (formattedFileName) });
              setAudioSrc(url);
            });
        } catch (error) {
          console.error("Error fetching audio:", error);
        }
      };

      fetchAudio();

      return () => {
        if (audioSrc) {
          URL.revokeObjectURL(audioSrc);
        }
      };
    }
  }, [audioFileId]);

  useEffect(() => {
    dispatch(
      toggleLoading(
        isTextToSpeechLoading || fileDownloading || isFileToSpeechLoading
      )

    );
    setIsLoading(
      isTextToSpeechLoading || fileDownloading || isFileToSpeechLoading
    );
  }, [isTextToSpeechLoading, fileDownloading, isFileToSpeechLoading]);

  useEffect(() => {
    dispatch(toggleLoading(false));
    if (textToSpeechError && instanceOfApiErrorWithCode(textToSpeechError)) {
      const errorData = textToSpeechError.data;
      if (errorData.errorCode === PaymentErrorCode.CreditLimitExceeded) {
        props.onCreditError()
      } else {
        console.log(errorData.message);
      }
    }
  }, [textToSpeechError]);

  useEffect(() => {
    dispatch(toggleLoading(false));
    if (fileToSpeechError && instanceOfApiErrorWithCode(fileToSpeechError)) {
      const errorData = fileToSpeechError.data;
      if (errorData.errorCode === PaymentErrorCode.CreditLimitExceeded) {
        props.onCreditError()
      } else {
        console.log(errorData.message);
      }
    }
  }, [fileToSpeechError]);


  const getPickerStyles = (
    smallItemms?: boolean,
    optionsOnSide?: boolean,
    isFontPicker?: boolean
  ): StylesConfig<PickerOptions> => {
    return {
      multiValue: (styles) =>
      ({
        ...styles,
        backgroundColor: "#FAFBF9",
        borderColor: "#EAEBE6",
        borderWidth: 1,
        maxHeight: 40,
        borderRadius: 7,
        padding: 6,
        margin: 0,
        gap: 10,
      } as CSSObjectWithLabel),
      singleValue: (styles, { data }) =>
      ({
        ...styles,
        fontFamily: isFontPicker ? data.title : "",
      } as CSSObjectWithLabel),
      valueContainer: (styles) =>
      ({
        ...styles,
        paddingBottom: 6,
        paddingTop: 6,
        paddingLeft: 6,
        paddingRight: 6,
        gap: 10,
      } as CSSObjectWithLabel),
      multiValueLabel: (styles) =>
      ({
        ...styles,
        fontSize: 15,
        padding: 0,
      } as CSSObjectWithLabel),
      multiValueRemove: (styles) =>
      ({
        ...styles,
        color: "black",
        padding: 10,
      } as CSSObjectWithLabel),
      indicatorSeparator: (styles) => ({ color: "white" }),
      control: (styles) =>
      ({
        ...styles,
        backgroundColor: "white",
        borderColor: "#EAEBE6",
        borderWidth: 1,
        borderRadius: 4,
        minHeight: 40,
        fontSize: 15,
        border: "1px solid #EAEBE6",
        width: optionsOnSide ? "150px" : undefined,
        boxShadow: "none",
        "&:hover": {
          border: "1px solid #EAEBE6",
          boxShadow: "none",
        },
      } as CSSObjectWithLabel),
      menu: (styles) =>
      ({
        ...styles,
        borderRadius: 12,
        overflow: "hidden",
        borderColor: "#EAEBE6",
        borderWidth: 1,
        padding: 5,
        paddingTop: 0,
        outline: "none",
        zIndex: 9,
      } as CSSObjectWithLabel),

      option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
          ...styles,
          padding: 10,
          paddingLeft: smallItemms ? 5 : 10,
          backgroundColor: isFocused
            ? "#FAFBF9"
            : isSelected
              ? "#FAFBF9"
              : "white",
          borderWidth: isFocused ? 1 : isSelected ? 1 : 0,
          borderColor: isSelected ? "#EAEBE6" : "",
          fontSize: smallItemms ? 12 : 15,
          borderRadius: 7,
          height: 41,
          color: "#121315",
          fontFamily: isFontPicker ? data.title : "",
          cursor: isDisabled ? "not-allowed" : "default",

          ":active": {
            ...styles[":active"],
            backgroundColor: "#FAFBF9",
          } as CSSObjectWithLabel,
        } as CSSObjectWithLabel;
      },
    };
  };

  return (
    <>
      {isLoading && (
        <div className="fixed w-full h-full top-0 left-0 z-[2000]" />
      )}
      <div className="flex flex-col mt-2 w-full">
        {!props.isUploadPage && (
          <TextArea
            showCount
            maxLength={10000}
            onChange={(e) => {
              console.log("x", e.target.value);
              setTextToBeConverted(e.target.value);
            }}
            placeholder="Type or paste your text here and click the generate button"
            style={{ height: 220, resize: "none", marginTop: 10 }}
          />
        )}

        {props.isUploadPage && (
          <UploadTextToSpeechFile
            description=".txt, .pdf or .doc files. MAX 5MB"
            fileChanged={(file) => {
              console.log("Set text file id: ", file);
              setTextFileId(file);
            }}
          />
        )}
      </div>

      <div className="flex w-full mt-3 gap-2">
        <div className="flex-1 flex-col">
          <span className="text-BodyMedium text-black">Voice</span>
          <PickerView
            className="h-[45px] mt-1"
            title={""}
            allowMultiSelect={false}
            items={TextToSpeechVoices}
            selectedValue={[voice]}
            placeholder={"Set Voice"}
            info={""}
            onChange={(e) => {
              console.log("Voice type is:", e[0].value);
              setVoice(e[0].value);
            }}
            getPickerStyles={getPickerStyles}
          />
        </div>
        <div className="flex-1">
          <span className="text-BodyMedium text-black">Output Format</span>
          <PickerView
            className="h-[45px] mt-1"
            title={""}
            items={TextToSpeechFileFormats}
            allowMultiSelect={false}
            selectedValue={[outputFormat]}
            placeholder={"Set Output Format"}
            info={""}
            onChange={(e) => {
              console.log("Output Format is: ", e[0].value);
              setOutputFormat(e[0].value);
            }}
            getPickerStyles={getPickerStyles}
          />
        </div>
      </div>
      <PrimaryButton
        className="h-10 mt-5 w-full text-BodySmall"
        title={"Generate"}
        isDisabled={
          props.isUploadPage
            ? textFileId === undefined
            : textToBeConverted === ""
        }
        onClick={() => {
          if (props.isUploadPage) {
            fileToSpeech({
              fileToSpeechRequest: {
                file_id: textFileId?.id ?? "",
                voice: voice,
                response_format: outputFormat,
              },
            });
          } else {
            textToSpeech({
              textToSpeechRequest: {
                text: textToBeConverted,
                voice: voice,
                response_format: outputFormat,
              },
            });
          }
        }}
      />
    </>
  );
}

export default TextToSpeechTabContent;
