import { t } from "i18next";
import { useEffect, useState } from "react";
import InfiniteScroll from 'react-infinite-scroller';
import { useLocation, useNavigate } from "react-router-dom";
import SearchInput from "../../components/SearchInput";
import { useAppDispatch } from "../../helpers/hooks";

import { BrandRequest } from "../../services/BrandApi";
import { LoopsResponse, useLazyLoopsQuery } from "../../services/ContentApi";
import { refreshPage, setActivePage, updateMenu } from "../../store/appSlice";
import { setNewContent } from "../../store/contentSlice";
import LoopGridCard from "./LoopGridCard";
import LoopListCard from "./LoopListCard";
import LoopsFilter from "./LoopsFilter";
import LoopsPageListTypeSelector, { SelectedLoopsPageListType } from "./LoopsPageListTypeSelector";
import Icon from "../../components/Icon";
import { IChannelContentData } from "../../components/ChannelContentSelector/ChannelContentSelector";
import SvgFilters from "../../assets/IconComponents/Filters";
import PrimaryButton from "../../components/PrimaryButton";
import { Like, Liked } from "../../assets/IconComponents";
import { stringify } from "querystring";

function LoopsPage() {
  const dispatch = useAppDispatch();
  const [selectedDisplayType, setSelectedDisplayType] = useState<SelectedLoopsPageListType>(SelectedLoopsPageListType.Grid)
  const [selectedChannels, setSelectedChannels] = useState<string[]>()
  const [selectedBrandId, setSelectedBrandId] = useState<string | undefined>()
  const [selectedChannelContentTypes, setSelectedChannelContentTypes] = useState<string[]>()
  const [searchText, setSearchText] = useState("")
  const [selectedContentTypes, setSelectedContentTypes] = useState<string[]>()
  const [selectedBrand, setSelectedBrand] = useState<BrandRequest | undefined>()
  const [getContentList, { isLoading: contenListLoading, error, data: contentList }] = useLazyLoopsQuery();
  const [favoriteSearch, setFavoriteSearch] = useState(false)
  const [filterAreaCollapsed, setFilterAreaCollapsed] = useState<boolean>(false)
  const [hasMore, setHasMore] = useState(true)
  const navigate = useNavigate()
  const { state } = useLocation()

  const [items, setItems] = useState<LoopsResponse[]>([])
  const [page, setPage] = useState(0)
  var scrollParentRef: HTMLDivElement | null

  useEffect(() => {
    dispatch(setActivePage("loops"))

    if (state?.searchText != undefined) {
      setSearchText(state.searchText)
    }
  }, [])

  const getLoopList = (page) => {
    var payload = {
      page: page,
      size: 20,
      favorite: favoriteSearch,
      filterText: searchText,
    }
    setPage(page)
    if (selectedContentTypes && selectedContentTypes.length > 0) {
      const selectedContentType = selectedContentTypes[0];
      payload["contentType"] = selectedContentType.toLowerCase() === "text" ? "text" : "image"
    } else {
      payload["contentType"] = ""
    }

    if (selectedChannels && selectedChannels.length > 0) {
      const selectedChannel = selectedChannels.at(0)
      payload["platform"] = selectedChannel ?? ""
    } else {
      payload["platform"] = ""
    }

    if (selectedBrandId) {
      payload["brandId"] = selectedBrandId ?? ""
    } else {
      payload["brandId"] = ""
    }

    getContentList(payload)
  }

  useEffect(() => {
    dispatch(setNewContent(undefined))
    setHasMore(true)
    setItems([])
    getLoopList(0)

    return () => {
      setItems([])
    }
  }, [])

  useEffect(() => {
    setHasMore(true)
    getLoopList(0)
  }, [selectedChannels, searchText, selectedContentTypes, favoriteSearch, selectedBrandId, selectedChannelContentTypes])

  useEffect(() => {
    if (contentList && contentList.length > 0) {
      if (page === 0) {
        setItems(contentList)
      }
      else {
        setItems([...items, ...contentList])
      }

      setHasMore(true)
    } else {
      setHasMore(false)
      if (page === 0) {
        setItems([])
      }
    }
  }, [contentList])


  return (
    <>
      <div className="relative min-w-full h-[56px] text-xl flex flex-row bg-white lg:-ml-[8px] -mr-[12px] border-1 border-border items-center justify-between">
        <span className="text-H6 text-black px-5 ">{t("contents")}</span>
        <div className="w-28 h-14 px-5 flex-col justify-center items-center gap-2.5 inline-flex">
          <LoopsPageListTypeSelector
            onChange={(selectedType) => {
              setSelectedDisplayType(selectedType);
            }}
          />
        </div>
      </div>
      <div className="w-full flex max-h-[calc(100vh-90px)] p-2 ">
        <div className="w-full gap-[20px] px-0 md:px-[30px] mt-2 md:mt-5 flex flex-col">
          <div className="flex flex-col gap-[10px]">
            <div className="w-full flex flex-row gap-[10px] justify-start items-start">
              <SearchInput
                text={searchText}
                placeholder={t("searchContents") ?? ""}
                className="flex-1"
                onChange={(e) => {
                  setSearchText(e);
                }}
              />
              <button
                onClick={() => {
                  setFilterAreaCollapsed(!filterAreaCollapsed);
                }}
                className={`border-border border-1 md:w-[84px] rounded-[7px] h-12 pr-2 pl-3 max-md:pr-3 py-2.5 bg-white border-stone-200 justify-start items-center inline-flex gap-[5px]`}
              >
                <Icon width="20" height="20" component={SvgFilters} />
                <span className="text-[13px] text-black max-md:hidden">
                  {"Filters"}
                </span>
              </button>
              <div className="">
                <PrimaryButton
                  noFill
                  iconComponent={
                    favoriteSearch ? (
                      <Icon
                        width="20"
                        height="20"
                        component={Liked}
                        color={"#FF5555"}
                      />
                    ) : (
                      <Icon
                        width="20"
                        height="20"
                        component={Like}
                        color="#999999"
                      />
                    )
                  }
                  className=""
                  onClick={() => {
                    setFavoriteSearch(!favoriteSearch);
                  }}
                />
              </div>
            </div>
            {!filterAreaCollapsed && (
              <LoopsFilter
                defaultSelectedChannels={selectedChannels ?? []}
                defaultSelectedContentTypes={selectedContentTypes ?? []}
                defaultSelectedBrand={selectedBrandId}
                defaultSelectedChannelContentTypes={
                  selectedChannelContentTypes ?? []
                }
                favoriteSelected={false}
                onChannelsChanged={(channels) => {
                  setSelectedChannels(channels.map((c) => c.title));
                }}
                onContentTypesChanged={(contentTypes) => {
                  setSelectedContentTypes(contentTypes.map((ct) => ct.title));
                }}
                onBrandChanged={(itemId) => {
                  setSelectedBrandId(itemId);
                }}
                isFavorited={(e) => {
                  setFavoriteSearch(e);
                }}
                onChannelContentTypesChanged={(
                  items: IChannelContentData[]
                ) => {
                  setSelectedChannelContentTypes(items.map((i) => i.id));
                }}
              />
            )}
          </div>
          <hr />
          <div className="w-full flex-row grow overflow-scroll scrollbar-hide">
            <InfiniteScroll
              pageStart={0}
              initialLoad={false}
              loadMore={getLoopList}
              useWindow={false}
              hasMore={hasMore}
            >
              <div
                className={`overflow-scroll scrollbar-hide grid ${selectedDisplayType === SelectedLoopsPageListType.Grid
                  ? "grid-cols-1 sm:grid-cols-2 lg:grid-cols-3"
                  : "grid-cols-1"
                  } gap-2`}
              >
                {items?.map((k, index) => {
                  return selectedDisplayType ===
                    SelectedLoopsPageListType.Grid ? (
                    <LoopGridCard
                      key={index}
                      displayType={selectedDisplayType}
                      loop={k}
                      updateLoops={() => {
                        setHasMore(true);
                        setItems([]);
                        getLoopList(0);
                        dispatch(updateMenu());
                        dispatch(refreshPage());
                      }}
                      onSelect={(contentId) => {
                        navigate(`/app/loopdetails/` + contentId);
                      }}
                    />
                  ) : (
                    <LoopListCard
                      key={index}
                      displayType={selectedDisplayType}
                      loop={k}
                      updateLoops={() => {
                        setHasMore(true);
                        setItems([]);
                        getLoopList(0);
                        dispatch(updateMenu());
                        dispatch(refreshPage());
                      }}
                      onSelect={(contentId) => {
                        navigate(`/app/loopdetails/` + contentId);
                      }}
                    />
                  );
                })}
              </div>
            </InfiniteScroll>
          </div>
        </div>
      </div>
    </>
  );
}

export default LoopsPage;
