import { t } from "i18next";
import { useEffect, useState } from "react";
import { Popover } from "react-tiny-popover";
import BrandLogo from "../../../../components/BrandLogo";
import { BrandRequest, useLazyList6Query } from "../../../../services/BrandApi";
import LoopDetailBrandSelectorPopup from "./LoopDetailBrandSelectorPopup";
import ActionSheet from "../../../../components/ActionSheet/ActionSheet";
import PrimaryButton from "../../../../components/PrimaryButton";
import LoopDetailBrandSelectorItem from "./LoopDetailBrandSelectorItem";
import { setNewBrandInfo } from "../../../../store/brandSlice";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../../../helpers/hooks";
import BrandHubNoBrandLineVertical from "../../../BrandHub/BrandHubNoBrandVertical";

export interface ILoopDetailBrandSelectorProps {
    BrandSelected: (data: BrandRequest | undefined) => void
    OnBrandEdit: (data: BrandRequest | undefined) => void
    tooltip?: string
    selectedBrand: BrandRequest | undefined
    selectedBrandId: string | undefined
    brandUpdated?: boolean
    isPopoverOpen: boolean
    setIsBrandPopoverOpen: (boolean) => void
}

function LoopDetailBrandSelector(props: ILoopDetailBrandSelectorProps) {
    const [selectedBrand, setSelectedBrand] = useState<BrandRequest | undefined>()
    const [getBrands, { data: brandList, isLoading: brandsLoading }] = useLazyList6Query()
    const [actionSheetOpened, setActionSheetOpened] = useState(false)
    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    useEffect(() => {
        getBrands()
    }, [])

    useEffect(() => {
        getBrands()
    }, [props.brandUpdated])

    useEffect(() => {

        setSelectedBrand(props.selectedBrand)
        // props.setIsBrandPopoverOpen(false)
    }, [props.selectedBrand])

    useEffect(() => {

        const selected = brandList?.find(k => k.id === props.selectedBrandId)
        if (selected !== undefined)
            setSelectedBrand(selected)
    }, [brandList, props.selectedBrandId])


    return (
        <>
            <div className="max-lg:hidden lg:flex">
                <Popover
                    isOpen={props.isPopoverOpen}
                    positions={['bottom']}
                    align="center"
                    padding={10}
                    onClickOutside={() => { props.setIsBrandPopoverOpen(false) }}
                    content={
                        brandList?.length != 0 ?
                            <LoopDetailBrandSelectorPopup
                                data={brandList ?? []}
                                closePopup={() => props.setIsBrandPopoverOpen(false)}
                                selectedBrand={selectedBrand}
                                selectedBrandChanged={(e) => {
                                    props.BrandSelected(e)
                                }}
                                onBrandEdit={
                                    (e) => {
                                        /*
                                        props.setIsBrandPopoverOpen(false)
                                        props.OnBrandEdit(e)
                                        */
                                        navigate("/app/brandhub/new", {
                                            state: { brand: e },
                                        });
                                    }
                                }
                            /> :
                            <BrandHubNoBrandLineVertical />
                    }
                >
                    <div className="h-[41px]"
                        onClick={() => props.setIsBrandPopoverOpen(!props.isPopoverOpen)}

                        data-tooltip-id={props.tooltip ? "app-tooltip" : ""}
                        data-tooltip-html={props.tooltip}
                    >
                        <button
                            className={`
                                    h-full
                                    p-[10px] pl-[6px]
                                    rounded-lg flex flex-row items-center
                                    border-1 ${selectedBrand === undefined ? "text-H7 border-blue bg-[#55ADFF1a]" : "border-border bg-white"} 
                                `}
                            onClick={() => {

                            }}>

                            <div className="flex flex-row items-center">
                                {selectedBrand === undefined &&
                                    <>
                                        <span className={`text-H7 text-blue`}>{t('select-brand-template')}</span>
                                    </>
                                }
                                {selectedBrand !== undefined &&
                                    <>

                                        <span className={`text-H7 text-gray ml-2`}>{t('brand')}</span>
                                        <span className={`text-H7 text-gray ml-0`}>:</span>
                                        <span className={`text-H7 text-blue ml-1`}>{selectedBrand.brand_name}</span>
                                    </>
                                }
                            </div>
                        </button>
                    </div>
                </Popover>
            </div>
            <div className="lg:hidden max-lg:flex">
                <ActionSheet
                    title="Select Brand"
                    isExpanded={actionSheetOpened}
                    setIsExpanded={setActionSheetOpened}
                    triggerView={(
                        <button
                            className={`
                                h-full
                                p-[12px] pl-[6px]
                                rounded-lg flex  items-center
                                border-1 ${selectedBrand === undefined ? "text-H7 border-blue bg-[#55ADFF1a]" : "border-border bg-white"} 
                            `}
                            onClick={() => {

                            }}>

                            <div className="flex flex-row items-center">
                                {selectedBrand === undefined &&
                                    <>
                                        <span className={`text-H7 text-blue`}>{t('select-brand-template')}</span>
                                    </>
                                }
                                {selectedBrand !== undefined &&
                                    <>
                                        <span className={`text-H7 text-gray ml-2`}>{t('brand')}</span>
                                        <span className={`text-H7 text-gray ml-0`}>:</span>
                                        <span className={`text-H7 text-blue ml-1`}>{selectedBrand.brand_name}</span>
                                    </>
                                }
                            </div>
                        </button>
                    )}
                    body={(
                        <div className="w-full p-[15px] pt-[5px] flex flex-col">
                            <div className="h-full">
                                <div className="max-h-[170px] overflow-scroll scrollbar-hide">
                                    {
                                        (brandList ?? []).map(k => (
                                            <LoopDetailBrandSelectorItem
                                                data={k}
                                                selected={selectedBrand?.id === k.id}
                                                itemSelected={(e) => {
                                                    props.BrandSelected(e)
                                                    setActionSheetOpened(false)
                                                }}
                                                onItemEdit={
                                                    (e) => {
                                                        /*
                                                        setActionSheetOpened(false)
                                                        props.OnBrandEdit(e)
                                                        */
                                                        navigate(
                                                            "/app/brandhub/new",
                                                            { state: { brand: k } }
                                                        );
                                                    }
                                                }
                                            />
                                        ))
                                    }
                                </div>
                                <PrimaryButton
                                    className="w-full h-[40px] mt-3"
                                    title={t('add-new-brand')}
                                    onClick={() => {
                                        dispatch(setNewBrandInfo({}))
                                        navigate("/app/brandhub/new")
                                    }} />
                            </div>
                        </div>
                    )}
                />
            </div>
        </>
    );
}

export default LoopDetailBrandSelector;
