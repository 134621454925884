<script>
	import { getContext } from "svelte";
	import { localeContext, getEventStyles } from "@xbs/lib-scheduler";
	import { isPast } from "date-fns";
	export let selected;
	export let event;
	export let calendars;
	const templates = getContext("schedulerTemplates");
	const config = getContext("schedulerConfig");

	$: ({ dimPastEvents, eventHeight } = $config);
	const dateFormat =
		getContext(localeContext).getGroup("scheduler")("agendaDateFormat");
	$: calendar = calendars.find(c => c.id === event.type);
	$: id = event.id;

</script>

<div
	class="wx-event-calendar-agenda-event-wrapper"
	class:wx-event-calendar-agenda-event-selected={$selected?.id === id}
	data-id={id}
	data-id-type={typeof id}
	use:getEventStyles={{ event: { eventData: event, position: { height: `${eventHeight}px` } }, backgroundFactor: 'background', calendars, dimPastEvents, isPast: isPast(event?.end_date) }}
	style="min-height: {$config.eventHeight}px; --wx-background: {calendar.color.border};">
	<svelte:component
		this={templates.agendaEvent}
		{event}
		{calendar}
		{dateFormat} />
</div>

<style>
	.wx-event-calendar-agenda-event-wrapper {
		padding: 0px 12px;
		margin-top: 4px;
	}
	.wx-event-calendar-agenda-event-wrapper:hover {
		background: var(--wx-color-disabled);
	}
	.wx-event-calendar-agenda-event-selected {
		background: var(--wx-background-hover);
	}

</style>
