import { useDispatch } from "react-redux";
import ContentImage from "../../components/ContentImage";
import { useAppSelector } from "../../helpers/hooks";
import ContentContainer from "../../components/ContentContainer";
import SvgForYouLightBulb from "../../assets/IconComponents/ForYouLightBulb";
import { CreativeTheme } from "../../services/CreativeApi";
import { setGuidelinesState, setNewContent } from "../../store/contentSlice";
import { ContentWizardView } from "../../models/ContentWizardView";
import moment from "moment";
import { INewContent } from "../../models/NewContent";
import { toggleContentWizard } from "../../store/appSlice";
import { ContentType } from "../../constants/ContentType";
import { Mediums } from "../../constants/Mediums";
import SvgMagic from "../../assets/IconComponents/Magic";
import ReactFlipCard from "reactjs-flip-card";
import { Button } from "antd";

interface ICreativePopularThemesGridProps {
  trendingThemes?: CreativeTheme[];
  onRefresh: () => void;
  onClick: (revisionId: string) => void;
  isCreative?: boolean;
}
function CreativePopularThemesGrid(props: ICreativePopularThemesGridProps) {
  const gifVideo = require("../../assets/gifs/evercopy_theme.webm");
  const dispatch = useDispatch();
  const lastSelectedBrandId = useAppSelector(
    (k) => k.persisted.lastSelectedBrandId
  );
  const newContent = useAppSelector((k) => k.content.newContent);

  return (
    <div className="flex flex-col w-[1050px] items-center">
      <div className="flex flex-row w-full grid grid-cols-5 gap-2">
        <div className="flex-col col-span-2">
          <ContentContainer
            hoverable
            overlayActiveOnHover={false} // You can adjust this based on your design
            children={
              <div className="flex-col">
                <div className={`w-full relative h-[270px] rounded-[8px]`}>
                  <video
                    src={gifVideo}
                    autoPlay
                    loop
                    muted
                    className="py-1 h-[270px] bg-bg w-full rounded-[8px] object-contain"
                  />
                </div>
              </div>
            }
          />
        </div>

        {props.trendingThemes?.map((trendingTheme) => {
          return (
            <div
              key={trendingTheme.id}
              className="flex flex-col h-full relative"
            >
              <ReactFlipCard
                containerStyle={{ width: "100%", height: "100%" }}
                frontComponent={
                  <div className="flex-col h-full p-0.5 bg-white rounded-[10px] border-1">
                    <div
                      className={`w-full relative h-full rounded-[8px] cursor-pointer`}
                    >
                      <ContentImage
                        id={trendingTheme?.imageId ?? ""}
                        className="py-1 h-full bg-bg w-full rounded-[8px] object-contain"
                      />
                    </div>
                  </div>
                }
                backComponent={
                  <div className="flex-col relative h-full">
                    <div className="relative h-full w-full bg-white rounded-[10px] border-1 border-red">
                      <ContentImage
                        id={trendingTheme?.imageId ?? ""}
                        className="w-full h-full object-contain rounded-[8px]"
                      />

                      <div
                        className="absolute inset-0 rounded-[8px]"
                        style={{
                          background:
                            "linear-gradient(180deg, rgba(18, 19, 21, 0) 0%, #121315 100%)",
                        }}
                      ></div>

                      <div className="absolute bottom-20 left-5">
                        <span className="text-white text-BodyMedium">
                          {trendingTheme.subChildConcept}
                        </span>
                      </div>

                      <div className="absolute bottom-12 left-5 flex gap-1">
                        <button
                          className="px-1 py-2 text-white text-BodySmall flex items-center justify-center overflow-hidden whitespace-nowrap overflow-ellipsis"
                          style={{
                            background: "rgba(255, 255, 255, 0.1)",
                            borderRadius: "100px",
                            border: "none",
                            height: "24px",
                          }}
                          onClick={() => {
                            // Handle Explore More action
                          }}
                        >
                          {trendingTheme.parentConcept}
                        </button>
                        {/*
                        <button
                          className="px-1 py-2 text-white text-BodySmall flex items-center justify-center"
                          style={{
                            background: "rgba(255, 255, 255, 0.1)",
                            borderRadius: "100px",
                            border: "none",
                            height: "24px",
                          }}
                          onClick={() => {
                            // Handle Explore More action
                          }}
                        >
                          Industry
                        </button>
                        */}
                      </div>

                      <div className="absolute bottom-2 w-full flex ">
                        <div
                          className="mx-2 w-full h-[24px] bg-red text-white flex items-center justify-center rounded-[4px] cursor-pointer"
                          onClick={(e) => {
                            dispatch(setGuidelinesState(1));
                            e.stopPropagation();
                            dispatch(
                              setNewContent({
                                ...newContent,
                                medium: Mediums.filter(
                                  (m) =>
                                    m.contentType === ContentType.image &&
                                    m.isCustom === true
                                ).at(0),
                                contentType: ContentType.image,
                                wizardView: ContentWizardView.contentDetails,
                                loopName: `Loop_${moment().format(
                                  "DD_MM_yyyy_hh_mm"
                                )}`,
                                brand: { id: lastSelectedBrandId },
                                isCreative: true,
                                creative_theme: trendingTheme.id,
                                sliderCount: 4,
                              } as INewContent)
                            );
                            dispatch(toggleContentWizard());
                          }}
                        >
                          Generate Similar
                        </div>
                      </div>
                    </div>
                  </div>
                }
              />
              {/*
                <ContentContainer
                hoverable
                overlayActiveOnHover={true}
                onHoverElement={
                  <button
                    onClick={(e) => {
                      dispatch(setGuidelinesState(1));
                      e.stopPropagation();
                      dispatch(
                        setNewContent({
                          ...newContent,
                          medium: Mediums.filter(
                            (m) =>
                              m.contentType === ContentType.image &&
                              m.isCustom === true
                          ).at(0),
                          contentType: ContentType.image,
                          wizardView: ContentWizardView.contentDetails,
                          loopName: `Loop_${moment().format(
                            "DD_MM_yyyy_hh_mm"
                          )}`,
                          brand: { id: lastSelectedBrandId },
                          isCreative: true,
                          creative_theme: trendingTheme.id,
                        } as INewContent)
                      );
                      dispatch(toggleContentWizard());
                    }}
                    style={{
                      left: "50%",
                      top: "45%",
                      transform: "translateX(-50%)",
                    }}
                    className="flex flex-row  p-1 py-1.5 items-center text-H5 font-normal text-black justify-center  absolute bottom-10 bg-white w-2/3 h-9 border rounded"
                  >
                    <SvgMagic
                      width={14}
                      height={14}
                      className="mr-2"
                      fill="currentColor"
                    />
                    Create Similar
                  </button>
                }
                children={
                  <div className="flex-col">
                    <div
                      className={`w-full relative h-[160px] rounded-[8px] cursor-pointer`}
                    >
                      <ContentImage
                        id={trendingTheme?.imageId ?? ""}
                        className="py-1 h-[160px] bg-bg w-full rounded-[8px] object-contain"
                      />
                    </div>
                  </div>
                }
              />
                */}
              <div
                className={`flex flex-col ml-1 justify-start w-full mt-2 mb-2`}
              >
                <span className="text-BodyMedium font-inter-tight font-medium text-black">
                  {trendingTheme.subChildConcept}
                </span>
                {/* New text added below the subChildConcept */}
                <span className="text-BodySmall font-inter-tight text-gray-500">
                  {trendingTheme.parentConcept}
                </span>
              </div>
            </div>
          );
        })}
      </div>
      <Button
        type="default"
        className="flex flex-col items-center  mt-2 mb-4 justify-center "
        onClick={() => props.onRefresh()}
      >
        Load More
      </Button>
    </div>
  );
}

export default CreativePopularThemesGrid;


