<script>
	import { Button } from "@wx/svelte-core";
	import { localeContext, navigationClick } from "@xbs/lib-scheduler";
	import { createEventDispatcher, getContext } from "svelte";
	import NavigationTitle from "./NavigationTitle.svelte";

	const dispatch = createEventDispatcher();

	const locale = getContext(localeContext);
	const _ = locale.getGroup("scheduler");

	const config = getContext("schedulerConfig");
	$: ({ readonly } = $config);

	export let mode;
	export let title;
	export let date = new Date();
	export let datepicker;
	export let toggleSidebar;
	let prevWidth = 0;

	function resize(node) {
		const ro = new ResizeObserver(entries =>
			window.requestAnimationFrame(() => {
				const { clientWidth, scrollWidth } = entries[0].target;
				if (clientWidth !== prevWidth) {
					mobileMode = clientWidth !== scrollWidth;
				}
				prevWidth = clientWidth;
			})
		);
		ro.observe(node);
	}

	let mobileMode;

	$: selectedMode = $mode;

	$: if (selectedMode) {
		dispatch("action", {
			action: "set-mode",
			data: { value: selectedMode },
		});
	}

</script>

<div
	class="wx-event-calendar-toolbar_wrapper"
	on:click={ev => navigationClick(ev, dispatch, _)}>
	{#if mobileMode}
		<NavigationTitle {title} {date} {datepicker} on:action />
	{/if}
	<div class="wx-event-calendar-toolbar" use:resize>
		<div class="wx-event-calendar-left">
			{#if toggleSidebar}
				<div
					class="wx-event-calendar-icon wx-event-calendar-menu"
					data-id="toggle-sidebar" />
			{/if}
			{#if !readonly}
				<div data-id="add">
					<Button type="primary">
						<div class="wx-event-calendar-btn-add">
							<i class="wxi-plus wx-event-calendar-plus" />
							{#if !mobileMode}
								<span class="text">{_('Create event')}</span>
							{/if}
						</div>
					</Button>
				</div>
			{/if}
		</div>
		{#if !mobileMode}
			<NavigationTitle {title} {date} {datepicker} on:action />
		{/if}

		<div class="wx-event-calendar-right">
			<div data-id="today">
				<Button type="secondary">{_('Today')}</Button>
			</div>
			<slot name="modeControl" mode={selectedMode} />
		</div>
	</div>
</div>

<style>
	.wx-event-calendar-toolbar_wrapper {
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	.wx-event-calendar-toolbar {
		display: flex;
		flex-basis: 56px;
		justify-content: space-between;
		align-items: center;
		padding: 0 12px;
		border-bottom: var(--wx-border);
		width: 100%;
		gap: 10px;
	}

	.wx-event-calendar-left {
		display: flex;
		align-items: center;
		gap: 12px;
	}

	.wx-event-calendar-right {
		display: flex;
		gap: 12px;
		align-items: center;
		flex-grow: 1;
		justify-content: flex-end;
	}

	.wx-event-calendar-btn-add {
		display: flex;
		align-items: center;
		gap: 10px;
	}

	.wx-event-calendar-icon {
		width: 28px;
		height: 28px;
		cursor: pointer;
	}

	.wx-event-calendar-icon:hover {
		opacity: 0.7;
	}

	.wx-event-calendar-menu {
		background: url("data:image/svg+xml,%3Csvg width='18' height='12' viewBox='0 0 18 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 0H18V2H0V0ZM0 5H18V7H0V5ZM0 10H18V12H0V10Z' fill='%239FA1AE'/%3E%3C/svg%3E%0A")
			no-repeat center;
	}

	.wx-event-calendar-plus {
		display: flex;
		align-items: center;
	}
	.wx-event-calendar-plus::before {
		font-weight: var(--wx-font-weight-b) !important;
	}

	.wx-event-calendar-view-select {
		width: 120px;
	}

</style>
