import { FunctionComponent, useEffect, useRef, useState } from "react";
import SvgFacebookColor from "../../../assets/IconComponents/FacebookColor";
import { Button, Select } from "antd";
import { BrandSocialsModel, SocialApiKeyModel, useConnectApiKeyMutation, useDisconnectApiKeysByBrandIdMutation } from "../../../services/AdManagerApi";
import SvgGoogleAdsColor from "../../../assets/IconComponents/GoogleAdsColor";
import SvgInstagramColor from "../../../assets/IconComponents/InstagramColor";
import SvgLinkedinColor from "../../../assets/IconComponents/LinkedinColor";
import SvgTwitterColor from "../../../assets/IconComponents/TwitterColor";
import PrimaryButton from "../../../components/PrimaryButton";
import BrandHubMetaAdsSettings from "./BrandHubMetaAdsSettings";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../helpers/hooks";
import { setNewBrandInfo } from "../../../store/brandSlice";
import { PlusOutlined } from "@ant-design/icons";
import { t } from "i18next";
import AddSocialAccountsPopupOpener from "../../AccountSettings/SocialAccount/AddSocialAccountPopup";
import { toggleDialog } from "../../../store/appSlice";

interface IBrandHubSocialAccountSelectionCardProps {
    brandId: string
    apiType: "FACEBOOK"
    | "INSTAGRAM"
    | "X"
    | "LINKEDIN"
    | "BLOG"
    | "NEWSLETTER"
    | "GOOGLE_ADS"
    | "META_ADS"
    | "FACEBOOK_PAGE"
    | "FACEBOOK_AD_ACCOUNT"
    apiTypeSecond?: "FACEBOOK"
    | "INSTAGRAM"
    | "X"
    | "LINKEDIN"
    | "BLOG"
    | "NEWSLETTER"
    | "GOOGLE_ADS"
    | "META_ADS"
    | "FACEBOOK_PAGE"
    | "FACEBOOK_AD_ACCOUNT"
    firstAccount?: BrandSocialsModel
    secondAccount?: BrandSocialsModel
    selecteblaApiKeys: SocialApiKeyModel[]
    selecteblaApiKeysSecond?: SocialApiKeyModel[],
    onNewAccountAdded?: () => void
}

const BrandHubSocialAccountSelectionCard: FunctionComponent<
    IBrandHubSocialAccountSelectionCardProps
> = (props) => {

    const [mainIcon, setMainIcon] = useState(<></>)
    const [title, setTitle] = useState("")
    const [firstPlaceHolder, setFirstPlaceHolder] = useState("")
    const [secondPlaceHolder, setSecondPlaceHolder] = useState("")
    const [connectApiKey, { }] = useConnectApiKeyMutation()
    // const [firstValueSelected, setFirstValueSelected] = useState(false)
    // const [secondValueSelected, setSecondValueSelected] = useState(false)
    const [noContentWarning, setNoContentWarning] = useState("")
    const [secondNoContentWarning, setSecondNoContentWarning] = useState("")
    const navigate = useNavigate();
    const [disconnectApiKey, { }] = useDisconnectApiKeysByBrandIdMutation();
    const [firstSelectedAccount, setFirstSelectedAccount] = useState<string>();
    const [secondSelectedAccount, setSecondSelectedAccount] = useState<string>();
    const [showAddSocialAccountsDialog, setShowAddSocialAccountsDialog] = useState(false);
    const brandInfo = useAppSelector(k => k.brand.newBrand);
    const dispatch = useAppDispatch();
    const [isFirstAccountDropdownOpen, setIsFirstAccountDropdownOpen] = useState(false);
    const [isSecondAccountDropdownOpen, setIsSecondAccountDropdownOpen] = useState(false);

    useEffect(() => {
        if (props.firstAccount) { setFirstSelectedAccount(props.firstAccount.apiKeyModel?.id) }
        if (props.secondAccount) { setSecondSelectedAccount(props.secondAccount.apiKeyModel?.id) }
    }, [props])


    useEffect(() => {
        if (props.apiType === "FACEBOOK" || props.apiType === "META_ADS" || props.apiType === "FACEBOOK_PAGE" || props.apiType === "FACEBOOK_AD_ACCOUNT") {
            setMainIcon(<SvgFacebookColor className="w-5 h-5 rounded-full" />)
        } else if (props.apiType === "GOOGLE_ADS") {
            setMainIcon(<SvgGoogleAdsColor className="w-5 h-5 rounded-full" />)
        } else if (props.apiType === "INSTAGRAM") {
            setMainIcon(<SvgInstagramColor className="w-5 h-5 rounded-full" />)
        } else if (props.apiType === "LINKEDIN") {
            setMainIcon(<SvgLinkedinColor className="w-5 h-5 rounded-full" />)
        } else if (props.apiType === "X") {
            setMainIcon(<SvgTwitterColor className="w-5 h-5 rounded-full" />)
        }
    }, [props])

    useEffect(() => {
        if (props.apiType === "FACEBOOK" || props.apiType === "META_ADS" || props.apiType === "FACEBOOK_PAGE" || props.apiType === "FACEBOOK_AD_ACCOUNT") {
            setTitle("Facebook")
        } else if (props.apiType === "GOOGLE_ADS") {
            setTitle("Google Ads")
        } else if (props.apiType === "INSTAGRAM") {
            setTitle("Instagram")
        } else if (props.apiType === "LINKEDIN") {
            setTitle("Linkedin")
        } else if (props.apiType === "X") {
            setTitle("X (Twitter)")
        }
    }, [props])

    useEffect(() => {
        if (props.apiType === "FACEBOOK" || props.apiType === "META_ADS" || props.apiType === "FACEBOOK_PAGE" || props.apiType === "FACEBOOK_AD_ACCOUNT") {
            setFirstPlaceHolder("Select a Facebook Page")
        } else if (props.apiType === "GOOGLE_ADS") {
            setFirstPlaceHolder("Select a Google Ad Account")
        } else if (props.apiType === "INSTAGRAM") {
            setFirstPlaceHolder("Select an Instagram Page")
        } else if (props.apiType === "LINKEDIN") {
            setFirstPlaceHolder("Select a Linkedin Page")
        } else if (props.apiType === "X") {
            setFirstPlaceHolder("Select an X Page")
        }
    }, [props])

    useEffect(() => {
        if (props.apiType === "FACEBOOK" || props.apiType === "META_ADS" || props.apiType === "FACEBOOK_PAGE" || props.apiType === "FACEBOOK_AD_ACCOUNT") {
            setNoContentWarning("Facebook Page")
        } else if (props.apiType === "GOOGLE_ADS") {
            setNoContentWarning("Google Ad Account")
        } else if (props.apiType === "INSTAGRAM") {
            setNoContentWarning("Instagram Page")
        } else if (props.apiType === "LINKEDIN") {
            setNoContentWarning("Linkedin Page")
        } else if (props.apiType === "X") {
            setNoContentWarning("X Page")
        }
    }, [props])


    useEffect(() => {
        if (props.apiType === "FACEBOOK" || props.apiType === "META_ADS" || props.apiType === "FACEBOOK_PAGE" || props.apiType === "FACEBOOK_AD_ACCOUNT") {
            setSecondPlaceHolder("Select a Facebook Ad Account")
        } else if (props.apiType === "INSTAGRAM") {
            setSecondPlaceHolder("Select a Facebook Ad Account")
        }
    }, [props])


    useEffect(() => {
        if (props.apiType === "FACEBOOK" || props.apiType === "META_ADS" || props.apiType === "FACEBOOK_PAGE" || props.apiType === "FACEBOOK_AD_ACCOUNT") {
            setSecondNoContentWarning("Facebook Ad Account")
        } else if (props.apiType === "INSTAGRAM") {
            setSecondNoContentWarning("Facebook Ad Account")
        }
    }, [props])

    function handleAddNewAccont(closeDropdown: any) {
        closeDropdown(false);
        dispatch(toggleDialog(true));
        setShowAddSocialAccountsDialog(true);
    }

    return (

        <div className="flex flex-row w-full items-center rounded-lg border-1 py-2">
            <div className="flex flex-col w-full px-2 gap-3">
                <div className="flex flex-row w-full gap-3 justify-between items-center">
                    <div className="flex flex-row items-center gap-[10px]">
                        <div className="relative">
                            {mainIcon}
                        </div>
                        <div className="text-black text-H7 font-normal">{title}</div>

                    </div>
                    <div className="flex flex-row gap-[12px]">
                        {<Select placeholder={firstPlaceHolder} style={{ width: "250px" }}
                            key={props.firstAccount?.apiKeyModel?.id}
                            allowClear
                            open={isFirstAccountDropdownOpen}
                            onDropdownVisibleChange={(open) => setIsFirstAccountDropdownOpen(open)}
                            onClear={() => {
                                setFirstSelectedAccount(undefined);
                                disconnectApiKey({ brandId: props.brandId, apiKey: secondSelectedAccount ?? "" });
                                if (props.apiType === "FACEBOOK_PAGE") {
                                    console.log(`delete emeta info`)
                                    dispatch(
                                        setNewBrandInfo({
                                            ...brandInfo,
                                            metaAdsSettings: undefined
                                        }))
                                }
                            }}
                            onChange={(a) => {
                                connectApiKey({ brandId: props.brandId, connectApiKeyRequest: { key_id: a, apiType: props.apiType } })
                                setFirstSelectedAccount(a);
                            }}
                            size="middle"
                            optionLabelProp="labelForSelection"
                            options={props.selecteblaApiKeys.map(key => {
                                return {
                                    value: key.id,
                                    label: (
                                        <div className="flex items-center gap-2">
                                            {key.connectionParameters?.profilePictureUrl && (
                                                <img
                                                    src={key.connectionParameters?.profilePictureUrl}
                                                    alt={key.name}
                                                    className="rounded-full w-8 h-8 border-1"
                                                />
                                            )}

                                            <div className="flex flex-col gap-1">
                                                <span className="text-H7 font-medium text-black">{key.name}</span>
                                                <span className="text-H7 font-medium text-footnote">{noContentWarning}</span>
                                            </div>
                                        </div>
                                    ),
                                    labelForSelection:
                                        (
                                            <div className="flex items-center gap-2">
                                                {key.connectionParameters?.profilePictureUrl && (
                                                    <img
                                                        src={key.connectionParameters?.profilePictureUrl}
                                                        alt={key.name}
                                                        className="rounded-full w-6 h-6 border-1"
                                                    />
                                                )}
                                                <span>{key.name}</span>
                                            </div>
                                        )
                                }
                            })}
                            dropdownRender={menu => (
                                <>
                                    {menu}

                                    {props.selecteblaApiKeys.length > 0 && (

                                        <Button type="link" className="-ml-3 text-H7 font-medium" danger icon={<PlusOutlined />}
                                            onClick={() => {
                                                handleAddNewAccont(setIsFirstAccountDropdownOpen);
                                            }}
                                        >
                                            {t("add-new")}
                                        </Button>
                                    )}
                                </>
                            )}
                            defaultValue={props.firstAccount?.apiKeyModel?.id}
                            notFoundContent={(
                                <div className="flex flex-col items-center px-2 py-5 gap-5">
                                    <div className="flex flex-col items-center gap-2">
                                        <div className="text-black text-H7 font-medium  text-center">{`No ${noContentWarning} found`}</div>
                                        <div className="text-black  text-BodySmall text-center">{`Add a ${noContentWarning} to your account and link with your brand.`}</div>
                                    </div>
                                    <PrimaryButton
                                        size="middle"
                                        onClick={function (): void {
                                            navigate('/app/settings?view=social_accounts')
                                        }}
                                        title={"Go To Settings"}
                                    ></PrimaryButton>
                                </div>
                            )}
                        />
                        }
                        {(props.apiTypeSecond === "FACEBOOK" || props.apiTypeSecond === "FACEBOOK_AD_ACCOUNT") &&
                            < Select placeholder={secondPlaceHolder} style={{ width: "250px" }}
                                key={props.secondAccount?.apiKeyModel?.id}
                                allowClear
                                open={isSecondAccountDropdownOpen}
                                onDropdownVisibleChange={(open) => setIsSecondAccountDropdownOpen(open)}
                                onClear={() => {
                                    setSecondSelectedAccount(undefined);
                                    disconnectApiKey({ brandId: props.brandId, apiKey: firstSelectedAccount ?? "" })
                                    if (props.apiTypeSecond === "FACEBOOK_AD_ACCOUNT") {
                                        dispatch(
                                            setNewBrandInfo({
                                                ...brandInfo,
                                                metaAdsSettings: undefined
                                            }))
                                    }
                                }}
                                onChange={(a) => {
                                    connectApiKey({ brandId: props.brandId, connectApiKeyRequest: { key_id: a, apiType: props.apiTypeSecond } })
                                    setSecondSelectedAccount(a)
                                }}
                                size="middle"
                                optionLabelProp="labelForSelection"
                                options={props.selecteblaApiKeysSecond?.map(key => {
                                    return {
                                        value: key.id,
                                        label: (
                                            <div className="flex items-center gap-2">
                                                {key.connectionParameters?.profilePictureUrl && (
                                                    <img
                                                        src={key.connectionParameters?.profilePictureUrl}
                                                        alt={key.name}
                                                        className="rounded-full w-8 h-8 border-1"
                                                    />
                                                )}

                                                <div className="flex flex-col gap-1">
                                                    <span className="text-H7 font-medium text-black">{key.name}</span>
                                                    <span className="text-H7 font-medium text-footnote">{secondNoContentWarning}</span>
                                                </div>
                                            </div>
                                        ),
                                        labelForSelection:
                                            (
                                                <div className="flex items-center gap-2">
                                                    {key.connectionParameters?.profilePictureUrl && (
                                                        <img
                                                            src={key.connectionParameters?.profilePictureUrl}
                                                            alt={key.name}
                                                            className="rounded-full w-6 h-6 border-1"
                                                        />
                                                    )}
                                                    <span>{key.name}</span>
                                                </div>
                                            )
                                    }
                                })}
                                dropdownRender={menu => (
                                    <>
                                        {menu}

                                        {props.selecteblaApiKeysSecond && props.selecteblaApiKeysSecond.length > 0 && (

                                            <Button type="link" className="-ml-3 text-H7 font-medium" danger icon={<PlusOutlined />}
                                                onClick={() => {
                                                    handleAddNewAccont(setIsSecondAccountDropdownOpen);
                                                }}
                                            >
                                                {t("add-new")}
                                            </Button>
                                        )}
                                    </>
                                )}
                                defaultValue={props.secondAccount?.apiKeyModel?.id}
                                notFoundContent={(
                                    <div className="flex flex-col items-center px-2 py-5 gap-5">
                                        <div className="flex flex-col items-center gap-2">
                                            <div className="text-black text-H7 font-medium text-center">{`No ${secondNoContentWarning} found`}</div>
                                            <div className="text-black  text-BodySmall text-center">{`Add a ${secondNoContentWarning} to your account and link with your brand.`}</div>
                                        </div>
                                        <PrimaryButton
                                            size="middle"
                                            onClick={function (): void {
                                                navigate('/app/settings?view=social_accounts')
                                            }}
                                            title={"Go To Settings"}
                                        ></PrimaryButton>
                                    </div>
                                )}
                            />
                        }
                    </div>
                </div>
                {/* meta ads settings */}
                {
                    (firstSelectedAccount && secondSelectedAccount) &&
                    <BrandHubMetaAdsSettings></BrandHubMetaAdsSettings>
                }

            </div>
            {
                showAddSocialAccountsDialog && (
                    <AddSocialAccountsPopupOpener
                        onClose={() => {
                            dispatch(toggleDialog(false));
                            setShowAddSocialAccountsDialog(false);
                            props.onNewAccountAdded?.();
                        }}
                    ></AddSocialAccountsPopupOpener>
                )
            }
        </div>
    )
}

export default BrandHubSocialAccountSelectionCard;