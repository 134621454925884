import type { SVGProps } from "react";
const SvgCheckers = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={20}
        height={20}
        viewBox="0 0 20 20"
        fill="none"
        {...props}
    >
        <path fill={props.fill} id="Union" opacity="0.2" d="M14.4 1.59961H11.84V4.15961H14.4V1.59961ZM14.4 6.71961H11.84V9.27961H14.4V6.71961ZM11.84 11.8396H14.4V14.3996H11.84V11.8396Z" />
        <path fill={props.fill} id="Union_2" opacity="0.4" d="M9.28003 4.16016H11.84V6.72016H9.28003V4.16016ZM9.28003 9.28016H11.84V11.8402H9.28003V9.28016Z" />
        <path fill={props.fill} id="Union_3" opacity="0.6" d="M9.27997 1.59961H6.71997V4.15961H9.27997V1.59961ZM9.27997 6.71961H6.71997V9.27961H9.27997V6.71961ZM6.71997 11.8396H9.27997V14.3996H6.71997V11.8396Z" />
        <path fill={props.fill} id="Union_4" opacity="0.8" d="M4.15997 4.16016H6.71997V6.72016H4.15997V4.16016ZM4.15997 9.28016H6.71997V11.8402H4.15997V9.28016Z" />
        <path fill={props.fill} id="Union_5" opacity="1.0" d="M4.15998 1.59961H1.59998V4.15961H4.15998V1.59961ZM4.15998 6.71961H1.59998V9.27961H4.15998V6.71961ZM1.59998 11.8396H4.15998V14.3996H1.59998V11.8396Z" />

    </svg>
);
export default SvgCheckers;
