import { FunctionComponent, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../helpers/hooks";
import { FileProps, useLazyFindById4Query } from "../../../services/BrandApi";
import {
  setNewContent,
  setSelectedForYouInspiration,
  setSelectedThemeImage,
} from "../../../store/contentSlice";
import ChooseAnAspirationOrTheme from "../CreativeDetails/ChooseAnInspirationOrTheme";
import { toggleContentWizard, toggleLoading } from "../../../store/appSlice";
import {
  useLazyGetCreativeInspirationQuery,
  useLazySearchQuery,
} from "../../../services/InspirationApi";
import PrimaryButton from "../../../components/PrimaryButton";
import {
  useLazySearch1Query,
  useLazyThemesQuery,
} from "../../../services/CreativeApi";
import Header from "./Header";
import Search from "antd/es/input/Search";
import { Tag } from "antd";

interface IInspirationsLibraryProps {
  selectionList: any[];
}

const InspirationsLibrary: FunctionComponent<IInspirationsLibraryProps> = (
  props: IInspirationsLibraryProps
) => {
  const newContent = useAppSelector((k) => k.content.newContent);
  const dispatch = useAppDispatch();

  const [tempPicks, setTempPicks] = useState<FileProps>();
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [uploadedImagesList, setUploadedImagesList] = useState<FileProps[]>([]);
  const [inspirations, setInspirations] = useState<FileProps[]>([]);
  const [isThemeGallery, setIsThemeGallery] = useState<boolean>(false);
  const [jointInspirations, setJointInspirations] = useState<FileProps[]>([]);
  const [jointThemesList, setJointThemesList] = useState<FileProps[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(0);

  const [
    getInspirations,
    {
      error: errorInspirationsLoading,
      isFetching: inspirationsFetching,
      data: inspirationsList,
    },
  ] = useLazyGetCreativeInspirationQuery();

  const [searchInspirations, { data: inspirationsSearchResult }] =
    useLazySearchQuery();

  const [
    getThemes,
    { error: errorThemesLoading, isFetching: themesFetching, data: themesList },
  ] = useLazyThemesQuery();

  const [searchThemes, { data: searchThemesList }] = useLazySearch1Query();

  useEffect(() => {
    if (newContent?.mediumOption?.galleryTpe === "theme") {
      setIsThemeGallery(true);
    }
  }, [newContent]);

  useEffect(() => {
    console.log("IsThemeGallery: ", isThemeGallery);
  }, [isThemeGallery]);

  useEffect(() => {
    console.log("Joint Themes List is: ", jointThemesList);
  }, [jointThemesList]);

  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    if (isThemeGallery) {
      if (!searchTerm) {
        if (selectedTags.length !== 0) {
          searchThemes({ returnSize: 10, tags: selectedTags }).then(
            (response) =>
              response.data ? setJointThemesList(response.data) : null
          );
        } else {
          getThemes({ page: currentPage, size: 20 }).then((response) =>
            response.data ? setJointThemesList(response.data) : null
          );
        }
      } else {
        searchThemes({
          returnSize: 10,
          searchTerm: searchTerm,
          tags: selectedTags,
        }).then((response) =>
          response.data ? setJointThemesList(response.data) : null
        );
      }
    } else {
      if (searchTerm) {
        searchInspirations({ returnSize: 20, searchTerm: searchTerm }).then(
          (response) => setInspirationList(response.data)
        );
      } else {
        getInspirations({ page: currentPage, size: 20 }).then((response) =>
          setInspirationList(response.data)
        );
      }
    }
  }, [isThemeGallery, uploadedImagesList, searchTerm, selectedTags]);

  const setInspirationList = (data) => {
    if (data) {
      const inspoFileList: FileProps[] = data?.map((inspo) => {
        const file: FileProps = {
          id: inspo.fileId,
          adId: inspo.adId,
          name: inspo.subCategory,
          uploaded: false,
          objective: inspo.objective,
          industry: inspo.industry,
        };
        return file;
      });
      setJointInspirations([...uploadedImagesList, ...inspoFileList]);
    }
  };

  useEffect(() => {
    dispatch(toggleLoading(inspirationsFetching || themesFetching));
  }, [inspirationsFetching, themesFetching]);

  const handleChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSearch = (term) => {
    setSearchTerm(term);
  };

  const tagsList = [
    "Aesthetics and Design",
    "Cultural Movements",
    "Fashion and Style",
    "Seasonal and Event-Based",
    "Technology and Gaming",
    "Hobbies and Interests",
    "Nature and Environment",
    "Materials and Textures",
    "Art and Craftsmanship",
    "Societal and Global Issues",
    "Luxury and Opulence",
    "Important Days World",
  ];

  const handleTagSelection = (tag: string, checked: boolean) => {
    const nextSelectedTags = checked ? [tag] : []; // Only keep the selected tag
    setSelectedTags(nextSelectedTags);
  };

  return (
    <div className="flex-col w-full h-full inline-flex scrollbar-hide outline-none">
      <Header isThemeGallery={isThemeGallery} />
      <div className="flex-col w-full h-full inline-flex border-b-1 overflow-hidden">
        <div className="self-stretch justify-start items-center m-[20px] flex-1">
          <div className="sticky top-0 w-full flex flex-col h-fit mb-3 justify-between items-center rounded bg-white relative">
            <Search
              disabled={false}
              onChange={handleChange}
              placeholder={isThemeGallery ? "Search a theme" : "Search"}
              onSearch={handleSearch}
            />
            {isThemeGallery && (
              <div className="flex flex-wrap gap-x-1 gap-y-2 mt-4 w-full">
                {tagsList.map((item, index) => (
                  <Tag.CheckableTag
                    key={item}
                    checked={selectedTags.includes(item)}
                    onChange={(checked) => handleTagSelection(item, checked)}
                    style={{
                      border: selectedTags.includes(item)
                        ? ""
                        : "1px solid #d9d9d9",
                      display: "inline-flex", // Use flexbox to center content
                      borderRadius: "3px",
                      justifyContent: "center", // Center horizontally
                      alignItems: "center", // Center vertically
                      height: "28px",
                      paddingTop: "4px !important",
                      whiteSpace: "nowrap", // Prevent text wrapping
                      padding: "0 10px", // Add some padding for spacing
                      textOverflow: "ellipsis",
                    }}
                  >
                    {item}
                  </Tag.CheckableTag>
                ))}
              </div>
            )}
          </div>
          <ChooseAnAspirationOrTheme
            type={isThemeGallery ? "theme" : "inspiration"}
            isGrid={true}
            uploadButtonEnabled={false}
            uploadedFiles={isThemeGallery ? jointThemesList : jointInspirations}
            title="Add up to 2 Logo Files (Ex. Light and Dark Logos)"
            description="Upload .jpg, .png files. Max 25mb."
            currentPage={currentPage}
            onRefresh={(demandedPage) => {
              if (isThemeGallery) {
                if (jointThemesList.length <= demandedPage * 20 + 1) {
                  getThemes({ page: currentPage + 1, size: 20 }).then(
                    (response) => {
                      if (response?.data) {
                        const newList: FileProps[] = response.data;
                        setJointThemesList((prevState) => [
                          ...prevState,
                          ...newList,
                        ]);
                        setCurrentPage(demandedPage);
                      }
                    }
                  );
                }
              } else {
                if (jointInspirations.length <= demandedPage * 20 + 1) {
                  getInspirations({ page: currentPage + 1, size: 20 }).then(
                    (response) => {
                      if (response?.data) {
                        const inspoFileList: FileProps[] = response.data?.map(
                          (inspo) => {
                            const file: FileProps = {
                              id: inspo.fileId,
                              adId: inspo.adId,
                              name: inspo.subCategory,
                              uploaded: false,
                              objective: inspo.objective,
                              industry: inspo.industry,
                            };
                            return file;
                          }
                        );
                        setJointInspirations((prevState) => [
                          ...prevState,
                          ...inspoFileList,
                        ]);
                        setCurrentPage(demandedPage);
                      }
                    }
                  );
                }
              }
            }}
            fileChanged={(f: any) => {
              if (f) {
                const lastFile: FileProps = {
                  id: f.uploadedId,
                  name: f.uploadedId,
                  uploaded: true,
                };
                setUploadedImagesList((prevItems) => [lastFile, ...prevItems]);
              }
            }}
            selectionChanged={(pickedFile) => {
              if (pickedFile) {
                setTempPicks(pickedFile);
              }
            }}
            selectionEnabled={true}
            selectedFileId={undefined}
          />
        </div>
      </div>
      <div className="w-full h-[85px] py-[15px] bg-white shadow justify-between items-center flex shrink-0 absolute bottom-0 left-0">
        <div className="w-[52px] self-stretch px-2.5 py-2 opacity-0 bg-white rounded-[5px] border border-stone-200 justify-center items-center gap-2.5 flex">
          <div className="text-neutral-900 text-sm font-semibold font-['Inter Tight'] leading-tight">
            Back
          </div>
        </div>
        <div className="w-[250px] self-stretch justify-start items-center gap-2.5 flex">
          <PrimaryButton
            title={"Cancel"}
            noFill
            className="w-[72px] h-11 mr-2"
            onClick={function (): void {
              if (newContent) {
                dispatch(
                  setNewContent({
                    ...newContent,
                    wizardView: 0,
                  })
                );
              }
            }}
          />
          <PrimaryButton
            isDisabled={!tempPicks?.id?.length}
            title={isThemeGallery ? "Choose Theme" : "Choose Inspiration"}
            className="w-[131px] h-11 mr-2"
            onClick={function (): void {
              if (newContent) {
                if (isThemeGallery) {
                  dispatch(setSelectedThemeImage(tempPicks));
                  dispatch(
                    setNewContent({
                      ...newContent,
                      wizardView: 0,
                      creative_theme: tempPicks?.id,
                      adId: undefined,
                      creativeImageId: undefined,
                    })
                  );
                } else if (!isThemeGallery && !tempPicks?.adId) {
                  dispatch(setSelectedForYouInspiration(tempPicks));
                  dispatch(
                    setNewContent({
                      ...newContent,
                      wizardView: 0,
                      creativeImageId: tempPicks?.id,
                      creative_theme: undefined,
                      adId: undefined,
                    })
                  );
                } else {
                  dispatch(setSelectedForYouInspiration(tempPicks));
                  dispatch(
                    setNewContent({
                      ...newContent,
                      wizardView: 0,
                      adId: tempPicks?.adId,
                      creative_theme: undefined,
                      creativeImageId: undefined,
                    })
                  );
                }
              }
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default InspirationsLibrary;
