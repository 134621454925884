<script>
	import { fade } from "svelte/transition";
	import {
		Button,
		Text,
		Area,
		Field,
		DatePicker,
		Timepicker,
		Checkbox,
		Combo,
		ColorPicker,
		MultiCombo,
		RadioButtonGroup,
	} from "@wx/svelte-core";
	import { form } from "@xbs/lib-svelte";
	import {
		saveForm,
		setDefaults,
		localeContext,
		EditorStore,
		validateForm,
		popupClick,
	} from "@xbs/lib-scheduler";
	import { clickOutside, locateID } from "@xbs/lib-dom";
	import { createEventDispatcher, getContext, onMount } from "svelte";
	import ColorPickerSchema from "../Controls/ColorPickerSchema.svelte";
	import FilesControl from "../../Uploader/FilesControl.svelte";
	import RecurringEvent from "../Controls/RecurringEvent.svelte";

	export let event = {};
	export let editMode;
	export let editorShape = [];
	export let datepicker;
	export let model;
	export let calendars;
	export let unassignedToggle;

	const m = model;
	$: editorShape = editorShape.map(control => ({
		...control,
		label: _(control.label),
		config: locateConfig(control.config),
	}));
	const templates = getContext("schedulerTemplates");
	const config = getContext("schedulerConfig");
	const colors = getContext("schedulerColors");
	const { showModal } = getContext("wx-helpers");
	$: ({ autoSave, defaultEventDuration, editorValidation, timeStep } =
		$config);
	$: ({ isChanged, showEditor } = $formState);

	const locale = getContext(localeContext);
	const _ = locale.getGroup("scheduler");
	const dispatch = createEventDispatcher();
	const formState = new EditorStore({
		isChanged: false,
		formValues: [],
		mode: editMode,
		showEditor: false,
		disabledTimepicker: event?.allDay,
	});
	const date = $datepicker.current;
	let saving = false;
	let isModalVisible = false;
	$: dispatch("toggleEditor", { show: showEditor });
	const values = form({}, val => {
		validateForm(val, defaultEventDuration, formState, values);

		if (autoSave) {
			clearTimeout(saving);
			saving = setTimeout(() => {
				saving = false;
				save();
			}, 500);
		}
	});

	async function modal() {
		if (isModalVisible) return;
		isModalVisible = true;
		return showModal({
			message: _("confirmUnsaved"),
		});
	}
	function reset(event) {
		const calendar = calendars.find(({ id }) => id === event.type);
		if (event.readonly || calendar?.readonly) return;
		values.reset(
			setDefaults({
				event,
				editorShape,
				mode: editMode,
				defaultEventDuration,
				store: formState,
				currentDate: date,
				setter: m.addEvent,
				locale,
				timeStep,
			})
		);
		if (showEditor && editMode === "add" && autoSave) {
			save();
		}
	}
	$: {
		if (formState.isChanged()) {
			modal().then(
				() => reset(event),
				() => {
					isModalVisible = false;
				}
			);
		} else {
			reset(event);
		}
	}

	onMount(() => {
		formState.set({ showEditor: true });
		if (editMode === "add" && autoSave) {
			save();
		}
	});

	function handleClose(saved) {
		if (formState.isChanged()) {
			modal().then(close, () => {
				isModalVisible = false;
			});
		} else {
			close(saved);
		}
	}
	function close(saved) {
		if (editMode === "add" && !autoSave && !saved) {
			model.removeEvent(formState.get().formValues.id);
		}
		if (autoSave && isChanged) {
			save();
		}
		formState.set({ showEditor: false });

		setTimeout(() => {
			dispatch("action", { action: "edit-event" });
		}, 150);
	}

	function outsideClickHandler(ev) {
		const id = locateID(ev);
		if (
			editMode === "add" &&
			id !== event.id &&
			id !== "add" &&
			!isModalVisible
		) {
			handleClose();
		}
	}

	function save() {
		return saveForm(editorShape, dispatch, formState, editorValidation);
	}

	function saveClick() {
		if (save()) {
			handleClose(true);
		}
	}

	function keyHandlers(ev) {
		if (ev.key === "Enter") {
			saveClick();
		}
		if (ev.key === "Escape") {
			handleClose();
		}
	}

	function deleteClick() {
		if ($formState.mode === "update") {
			popupClick(
				"delete",
				event,
				dispatch,
				() => dispatch("action", { action: "edit-event" }),
				showModal,
				_,
				autoSave
			);
		} else {
			handleClose();
		}
	}
	function locateConfig(config) {
		const { placeholder, ...rest } = config || {};

		return { ...rest, placeholder: placeholder ? _(placeholder) : "" };
	}

</script>

<svelte:window on:keydown={keyHandlers} />
<div
	class="wx-event-calendar-editor-wrapper"
	out:fade={{ duration: 500 }}
	use:clickOutside={outsideClickHandler}>
	<div class="wx-event-calendar-editor-controls">
		{#if unassignedToggle}
			<i
				class="wxi-arrow-left wx-event-calendar-close"
				on:click={handleClose} />
		{:else}
			<i
				class="wxi-close wx-event-calendar-close"
				on:click={handleClose} />
		{/if}
		{#if autoSave && saving}
			<div class="wx-event-calendar-loading-wrapper">
				Saving...
				<i class="wxi-loading wx-event-calendar-loading" />
			</div>
		{/if}
		<Button type="danger" click={deleteClick}>{_('Delete')}</Button>
		{#if !autoSave}
			<Button type="primary" click={saveClick}>{_('Save')}</Button>
		{/if}
	</div>
	<div class="wx-event-calendar-editor-form">
		{#each editorShape as field}
			{#if field.type === 'text'}
				<Field label={field.label || ''} position="top" let:id>
					<Text
						{id}
						bind:value={$values[field.key]}
						{...field.config} />
				</Field>
			{:else if field.type === 'date'}
				<Field label={field.label || ''} position="top" let:id>
					<div class="wx-event-calendar-date_field">
						<div class="wx-event-calendar-input_wrapper">
							<DatePicker
								{id}
								bind:value={$values[field.key]}
								{...field.config}
								buttons={false} />
						</div>
						{#if field.time}
							<Timepicker
								{id}
								disabled={$formState.disabledTimepicker}
								bind:value={$values[`${field.key}_time`]} />
						{/if}
					</div>
				</Field>
			{:else if field.type === 'checkbox'}
				<Field label={field.label || ''}>
					<Checkbox
						bind:value={$values[field.key]}
						label={_(field.text)} />
				</Field>
			{:else if field.type === 'combo'}
				<Field label={field.label || ''} position="top" let:id>
					<Combo
						{id}
						options={field.options}
						{...field.config}
						bind:value={$values[field.key]}
						let:option>
						<div class="wx-event-calendar-combo-option-wrapper">
							<svelte:component
								this={field.template || templates.comboOption}
								{option} />
						</div>
					</Combo>
				</Field>
			{:else if field.type === 'color'}
				<Field label={field.label || ''} position="top" let:id>
					<ColorPicker
						bind:value={$values[field.key]}
						colors={field.colors || $colors.map(c => c[c.colorpicker || 'border'])}
						placeholder={field.config?.placeholder}
						clear={field.config?.clear || false} />
				</Field>
			{:else if field.type === 'colorSchema'}
				<Field label={field.label || ''} position="top" let:id>
					<ColorPickerSchema
						config={field.config}
						colors={field.colors || $colors}
						bind:color={$values[field.key]} />
				</Field>
			{:else if field.type === 'textarea'}
				<Field label={field.label || ''} position="top" let:id>
					<Area
						{id}
						bind:value={$values[field.key]}
						{...field.config} />
				</Field>
			{:else if field.type === 'files'}
				<Field label={field.label || ''} position="top" let:id>
					<FilesControl {field} {values} />
				</Field>
			{:else if field.type === 'multiselect'}
				<Field label={_(field.label)} position="top" let:id>
					<MultiCombo
						{id}
						bind:value={$values[field.key]}
						options={field.options}
						let:option
						{...field.config}>
						<div>
							<svelte:component
								this={field.template || templates.comboOption}
								{option} />
						</div>
					</MultiCombo>
				</Field>
			{:else if field.type === 'radio'}
				<Field label={_(field.label)} position="top" let:id>
					<RadioButtonGroup
						{id}
						options={field.options}
						bind:value={$values[field.key]} />
				</Field>
			{:else if field.type === 'recurring'}
				<Field label={_(field.label)} position="top" let:id>
					<RecurringEvent
						{id}
						bind:value={$values.RRULE}
						start_date={$values.start_date} />
				</Field>
			{/if}
		{/each}
	</div>
</div>

<style>
	.wx-event-calendar-editor-wrapper {
		padding: 0px 12px;
		height: 100%;
		padding-top: 0px;
	}
	.wx-event-calendar-close,
	.wx-event-calendar-loading-wrapper {
		cursor: pointer;
		font-size: var(--wx-font-size-md);
		flex-grow: 1;
		display: flex;
		gap: 25px;
	}

	.wx-event-calendar-loading {
		cursor: default;
		transform: rotate(0);
		animation: spin 1s linear infinite;
	}
	@keyframes spin {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}

	.wx-event-calendar-editor-controls {
		display: flex;
		width: calc(100% + 24px);
		margin-bottom: 15px;
		align-items: center;
		justify-content: space-between;
		gap: 10px;
		position: sticky;
		top: 0;
		left: 0;
		padding: 12px;
		margin-left: -12px;
		z-index: 5;
		background: var(--wx-background);
		border-bottom: var(--wx-border);
	}
	.wx-event-calendar-date_field {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.wx-event-calendar-date_field .wx-event-calendar-input_wrapper {
		flex-grow: 2;
	}
	.wx-event-calendar-date_field :global(.timepicker) {
		width: unset !important;
	}
	.wx-event-calendar-input_wrapper {
		margin-right: 10px;
	}
	:global(.wx-event-calendar-editor-controls > .btn:not(:last-child)) {
		margin-right: 5px;
	}
	.wx-event-calendar-combo-option-wrapper {
		width: 100%;
		height: 100%;
	}
	.wx-event-calendar-editor-form {
		padding-bottom: 12px;
	}

</style>
