<script>
	import AgendaDateTemplate from "./templates/AgendaDateTemplate.svelte";
	import {
		Material,
		Willow,
		WillowDark,
		Locale,
		Globals,
	} from "@wx/svelte-core";
	import { getContext, setContext, createEventDispatcher } from "svelte";
	import { writable } from "svelte/store";

	import { EventBusRouter } from "@xbs/lib-state";
	import {
		en,
		themeContext,
		DataStore,
		defaultEditorShape,
		defaultCalendars,
		defaultConfig,
		localeContext,
	} from "@xbs/lib-scheduler";
	import Layout from "./Layout.svelte";
	import WeekEvent from "./templates/WeekEvent.svelte";
	import MultiEvent from "./templates/MultiEvent.svelte";
	import MonthEvent from "./templates/MonthEvent.svelte";
	import PopupTemplate from "./templates/PopupTemplate.svelte";
	import HeaderTemplate from "./templates/HeaderTemplate.svelte";
	import { defaultColors } from "@xbs/lib-scheduler/dist/consts";
	import ComboOption from "./templates/ComboOption.svelte";
	import AgendaEventTemplate from "./templates/AgendaEventTemplate.svelte";
	import NavigationControl from "./Navigation/NavigationControl.svelte";
	import SectionTemplate from "./templates/SectionTemplate.svelte";
	import UnassignedEvent from "./templates/UnassignedEvent.svelte";

	export let events = [];
	export let selected = null;
	export let mode = "week";
	export let config = defaultConfig;
	export let editorShape = defaultEditorShape;
	export let calendars = defaultCalendars;
	export let sidebar = { show: true };
	export let templates = {};
	export let colors = defaultColors;
	export let date = new Date();

	const dispatch = createEventDispatcher();

	const dataStore = new DataStore(writable);
	// setup default theme
	const themes = {
		material: Material,
		willow: Willow,
		"willow-dark": WillowDark,
	};
	const theme = getContext(themeContext);
	const defaultTheme = themes[theme] || Material;
	if (!theme) setContext(themeContext, defaultTheme);

	templates = {
		weekEvent: WeekEvent,
		multievent: MultiEvent,
		monthEvent: MonthEvent,
		popup: PopupTemplate,
		header: HeaderTemplate,
		yearEvent: MonthEvent,
		agendaDate: AgendaDateTemplate,
		agendaEvent: AgendaEventTemplate,
		timelineSection: SectionTemplate,
		unassignedEvent: UnassignedEvent,
		...templates,
		comboOption: ComboOption,
	};

	setContext("schedulerTemplates", templates);
	$: weekStart =
		getContext(localeContext)?.getGroup("calendar")("weekStart") ?? 7;
	$: config = {
		...config,
		weekStartsOn:
			weekStart === 7 || typeof weekStart === "string" ? 0 : weekStart,
	};
	$: dateFnsLocale = getContext(localeContext)?.getRaw().dateFnsLocale;
	$: dataStore.init({
		events,
		selected,
		mode,
		date,
		editorShape,
		calendars,
		config,
		sidebar,
		colors,
		dateFnsLocale,
	});

	// define event route
	let lastInRoute = new EventBusRouter(dispatch);
	// public API
	export const api = {
		exec: dataStore.in.exec,
		getState: dataStore.getState.bind(dataStore),
		getReactiveState: dataStore.getReactive.bind(dataStore),
		setNext: ev => {
			lastInRoute.setNext(ev.exec);
			lastInRoute = ev;
		},
		getStores: () => ({ state: dataStore }),
		on: dataStore.in.on.bind(dataStore.in),
		intercept: dataStore.in.on.bind(dataStore.in),
	};

	dataStore.in.setNext(lastInRoute.exec);

	function triggerAction(ev) {
		const { action, data } = ev.detail;
		dataStore.in.exec(action, data);
	}

</script>

<svelte:component this={defaultTheme}>
	<Locale words={en} optional={true}>
		<Globals>
			<Layout store={dataStore} on:action={triggerAction}>
				<slot name="modeControl" slot="modeControl" let:mode {mode}>
					<NavigationControl {mode} on:action={triggerAction} />
				</slot>
			</Layout>
		</Globals>
	</Locale>
</svelte:component>
