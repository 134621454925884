import { useEffect } from "react";
import { useAppDispatch } from "../../../helpers/hooks";
import { setDialog, toggleDialog } from "../../../store/appSlice";
import { Button } from "antd";
import ReactPlayer from 'react-player/youtube'
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

export interface ISubscriptionWelcomeProps {
    header: string
    body: string
    onImportBrand: () => void
}

function SubscriptionWelcome(props: ISubscriptionWelcomeProps) {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    return (<>
        <div className="w-full h-[600px] bg-white rounded-lg flex-col justify-center items-center inline-flex gap-5 p-[-20px]">
            <div className="w-full h-[360px]">
                <ReactPlayer url='https://www.youtube.com/watch?v=aWNfVs1xZ7U' width='100%'
                    height='100%'
                    controls={false}
                    pip={true}
                    config={{ playerVars: { autoplay: 1, loop: 1, controls: 0, color: "white", modestbranding: 0, rel: 0 } }} />
            </div>
            <div className="w-full h-[240px] px-[28px] py-[40px] flex flex-col gap-7 content-between">
                <div className="w-full h-[94px] gap-[10px] flex flex-col">
                    <span className="flex justify-center text-black font-medium text-[30px] leading-10 text-center">{props.header}</span>
                    <span className="flex justify-center text-black font-regular text-[14px] leading-[22px] text-center">{props.body}</span>
                </div>
                <div className="w-full  h-[40px] flex flex-row gap-3 justify-center">
                    <Button className="h-[40px]" type="default" onClick={() => { dispatch(toggleDialog(false)); navigate("/app/homepage") }}>{"Go to Home"}</Button>
                    <Button className="h-[40px]" type="primary" onClick={() => { dispatch(toggleDialog(false)); props.onImportBrand() }}>{"Import Your Brand"}</Button>
                </div>
            </div>
        </div >
    </>)
}



function SubscriptionWelcomeOpener(props: ISubscriptionWelcomeProps) {
    const dispatch = useAppDispatch();
    useEffect(() => {
        showSubscriptionDialog();
    }, []);
    const showSubscriptionDialog = () => {
        dispatch(
            setDialog({
                actions: <></>,
                title: "",
                message: "",
                customTemplate: (
                    <SubscriptionWelcome
                        {...props}
                    >
                    </SubscriptionWelcome>
                ),
                // customZIndex: props.zIndex ?? "z-50",
            }),
        );
        dispatch(toggleDialog(true));
    };

    return <></>;
}
export default SubscriptionWelcomeOpener;