<script>
	import { ColorSelect } from "@wx/svelte-core";
	import { form } from "@xbs/lib-svelte";

	export let colors;
	export let config = { clear: null, placeholder: null };
	export let color;

	$: colorpicker = color.colorpicker || "border";

	const values = form({}, val => {
		color = {
			...colors.find(c => c[c.colorpicker || "border"] === val.color),
		};
	});

	$: values.reset({ color: color[colorpicker] || "" });
	$: mapedColors = colors.map(c => c[c.colorpicker || "border"]);

</script>

<ColorSelect
	bind:value={$values.color}
	colors={mapedColors}
	placeholder={config?.placeholder}
	clear={config?.clear || false} />
